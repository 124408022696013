const Facebook = () => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='34' height='34' rx='8' fill='#496199' />
      <path
        d='M21.441 18.0485L21.9369 14.8155H18.835V12.7184C18.835 11.8338 19.2675 10.9709 20.6568 10.9709H22.068V8.21845C22.068 8.21845 20.7879 8 19.5646 8C17.0088 8 15.3398 9.54877 15.3398 12.3515V14.8155H12.5V18.0485H15.3398V25.8646C15.91 25.9541 16.4932 26 17.0874 26C17.6816 26 18.2648 25.9541 18.835 25.8646V18.0485H21.441Z'
        fill='white'
      />
    </svg>
  );
};

export default Facebook;
