import styled from "styled-components";
import {Field} from "formik";
import React from "react";

const StyledWrapperInput = styled.div`
  display: flex;
  border: 1px solid #94a3b8;
  border-radius: 8px;
  padding: 10px;
`;

const StyledField = styled(Field)`
  outline: none;
  border: none;
  width: 100%;
`;

export const CustomInputField = (props) => {
    const {icon: Icon, ...rest} = props;
    return (
        <StyledWrapperInput>
            <StyledField {...rest} />
        </StyledWrapperInput>
    );
};
