import React, {useEffect, useState} from "react";
import {useStripe, useElements, CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";
import creditCard from "../../pages/adsActions/MyAds/asssets/credit-card.svg";
import {
    errorSweetAlert,
} from '../../sections/sweetAlert/sweetAlert';
import PopPaymentCheckout from "./PopPaymentCheckout"
import { useTranslation } from 'react-i18next';


const PayByCreditCard = (props) => {
    const { t } = useTranslation();
    const {token, id, apiPaymentMethod, closeModal,  setModalActivePurchase, setModalActiveError} = props
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false)
    const [clientSecret, setClientSecret] = useState("");
    const [, setCardData] = useState("")
    const [showPaymentCheckout, setShowPaymentCheckout] = useState({
        show: false,
        data: null,
    })

    const togglePaymentCheck = (data) => {
        setShowPaymentCheckout(prevState => ({
            show: !prevState.show,
            data,
        }))
    };

    useEffect(() => {
        if (token && id) {
            apiPaymentMethod(token, id)
                .then((r) => {
                    const {intent_secret, ...rest} = r.data
                    setClientSecret(intent_secret)
                    setCardData(rest)
                })
        }
    }, [token, id, apiPaymentMethod])

    //
    // const handlePayByCard = async (e) => {
    //     e.preventDefault();
    //     if (!stripe || !elements) {
    //         return;
    //     }
    //     await stripe.confirmCardPayment(clientSecret, {
    //         payment_method: {
    //             card: elements.getElement(CardElement)
    //         }
    //     }).then((result) => {
    //         closeModal()
    //
    //             doneSettingsAlert('Payment Success!');
    //
    //         console.log("PR", result)
    //     }).catch((err) => {
    //
    //             closeModal()
    //             errorSweetAlert('Payment Error')
    //
    //     })
    //
    // };

    const handlePayByCard = async (e) => {
        e.preventDefault();
        try {

            if (!stripe || !elements) {
                return;
            }
            setIsLoading(true);

            const confirmPayment = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement)
                }
            })
            const {paymentIntent} = confirmPayment

            if (paymentIntent.status === "succeeded") {
                closeModal()
                setModalActivePurchase(true)

            } else {
                closeModal()
                errorSweetAlert('Payment Error')
            }

        } catch (err) {
            setModalActiveError(true)
            console.log(err)
        }
        setIsLoading(false);

    };


    return (
        <div>
            <button onClick={() => togglePaymentCheck()}
                    type="submit" className={'payByCard'}>
                {t('pay-by-credit-card')}
                <img src={creditCard} alt={'credit-card'}/>
            </button>
            {showPaymentCheckout.show && (
                <PopPaymentCheckout
                    modal={showPaymentCheckout}
                    setModal={setShowPaymentCheckout}
                    handlePayByCard={handlePayByCard}
                    stripe={stripe}
                    elements={elements}
                    isLoading={isLoading}
                    CardExpiryElement={CardExpiryElement}
                    CardCvcElement={CardCvcElement}
                    CardNumberElement={CardNumberElement}
                />
            )}
        </div>
    )

}

export default PayByCreditCard