import './popupLogout.css'
import {setIsLoginUser, setLogOutUser} from "../../../redux/reducers/userAuth/sliceUserAuth";
import {cleanPersonalAds} from "../../../redux/reducers/ads/sliceAds";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {AuthOperations} from "../../../API/api";
import { useTranslation } from 'react-i18next';

const PopupLogout = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleLogout = () => {
        AuthOperations.userLogout()
            .then(res => {
                // console.log(res)
                if (res.status === 200) {
                    localStorage.clear();
                    sessionStorage.clear();
                    dispatch(setIsLoginUser());
                    dispatch(setLogOutUser());
                    dispatch(cleanPersonalAds());
                    history.push('/auth?type=login');
                }
            })
    };

    return (
        <div style={{background: props.active ? "rgba(0, 0, 0, 0.5)" : "transparent"}}
             className={props.active ? "modalLogout active" : "modalLogout"} onClick={() => props.setActive(false)}>

            <form className={'modal-content-logout'}
                  onClick={e => e.stopPropagation()}>

                <div className={'close-and-feedback'}>
                    <div className={'leave-feedback'}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="32" height="32" rx="8" fill="#F26464"/>
                            <path
                                d="M18 12V10C18 9.46957 17.7893 8.96086 17.4142 8.58579C17.0391 8.21071 16.5304 8 16 8H9C8.46957 8 7.96086 8.21071 7.58579 8.58579C7.21071 8.96086 7 9.46957 7 10V22C7 22.5304 7.21071 23.0391 7.58579 23.4142C7.96086 23.7893 8.46957 24 9 24H16C16.5304 24 17.0391 23.7893 17.4142 23.4142C17.7893 23.0391 18 22.5304 18 22V20"
                                stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11 16H25M25 16L22 13M25 16L22 19" stroke="white" strokeWidth="2"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className={'log'}>{t('logout-me')}</span>
                    </div>
                    <div className={'close-button'}>
                        <svg width="24" className={'close_icon'}
                             onClick={() => props.setActive(false)}
                             height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.6416 12.0002L18.5804 7.07632C19.0069 6.61883 18.9948 5.90538 18.5522 5.46381C18.1097 5.0213 17.3972 5.00819 16.9397 5.43475L12.0001 10.3586L7.07621 5.42731C6.62059 4.96982 5.88089 4.96792 5.4234 5.42356C4.96591 5.87824 4.96402 6.61888 5.41965 7.07636L10.3584 12.0002L5.41965 16.924C4.9931 17.3815 5.00527 18.095 5.44778 18.5366C5.89029 18.9791 6.60278 18.9922 7.06029 18.5656L11.9841 13.6418L16.908 18.5656H16.9089C17.3617 19.0269 18.1033 19.0344 18.5655 18.5806C19.0267 18.1278 19.0342 17.3862 18.5805 16.9241L13.6416 12.0002Z"
                                fill="#003E47" fillOpacity="0.4"/>
                        </svg>
                    </div>
                </div>
                <div className={'sure-delete'}>
                    <span>{t('Are you sure you want to log out of your account?')}</span>
                </div>

                <div className={'cancel-block'} onClick={() => props.setActive(false)}>
                    <div className={'cancel'}>{t('cancel')}</div>
                </div>
                <div className={'delete-block'}>
                    <div className={'delete'} onClick={handleLogout}>{t('logout')}</div>
                </div>
            </form>
        </div>
    );
};


export default PopupLogout;