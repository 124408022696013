import React from 'react';
import GoogleMapReact from 'google-map-react';
import CustomMapMarker from "./CustomMapMarker";
import './google_map.css'

const GoogleMap = (props) => {
    const {lat, lng} = props;
    return (
        <div style={{height: '120px', width: '100%'}}>
            <GoogleMapReact
                bootstrapURLKeys={{key: process.env.REACT_APP_MAPS_API_KEY}}
                defaultCenter={{lat, lng}}
                defaultZoom={10}
                key={{lat, lng}}
                options={{
                    disableDefaultUI: true,
                    // scaleControl: false,
                    // panControl: false,
                    // mapTypeControl: false,
                    // scrollWheel: false,
                    // styles: [{stylers: [{'saturation': -100}, {'gamma': 0.8}, {'lightness': 4}, {'visibility': 'on'}]}]
                }}
            >
                <CustomMapMarker
                    lat={lat}
                    lng={lng}
                />
            </GoogleMapReact>
        </div>
    );
};

export default GoogleMap;
