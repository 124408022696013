import React from 'react';
import {Route} from 'react-router-dom';
import {Navigation} from './navigation';
// import Header from '../pages/header';
// import Footer from '../pages/footer/Footer';

const PublicRouter = (props) => {
			return (
				<div className={'wrapper_app'}>
							{/*<Header/>*/}
							{Navigation.mainNavigation.map(route => (
								<Route path={route.path}
											 exact
											 {...props}
											 component={route.main}
											 key={route.path}/>
							))}
							{/*<Footer/>*/}
				</div>
			);
};

export default PublicRouter;