import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getUserAvatar, getUserName} from '../../redux/selectors/selectors';
import '../menu/menuSettings.css';
import ads from '../menu/assets/ads-list.svg';
import archive from '../menu/assets/archive.svg';
import creditCard from '../menu/assets/credit-card.svg';
import heartMenu from '../menu/assets/heart.svg';
import logout from '../menu/assets/logout.svg';
import message from '../menu/assets/message-circle-2.svg';
import settings from '../menu/assets/settings.svg';
import PopupLogout from "../../Components/listMenu/PopupLogout/PopupLogout";
import OutsideClickHandler from "../../Constants/OutsideClickHandler";

const menuItems = [
    {
        url: '/profile/my-ads?page=1',
        src: ads,
        alt: 'ads',
        text: 'my-ads-list',
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 4H6C4.89543 4 4 4.89543 4 6V7C4 8.10457 4.89543 9 6 9H8C9.10457 9 10 8.10457 10 7V6C10 4.89543 9.10457 4 8 4Z"
                stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M8 13H6C4.89543 13 4 13.8954 4 15V18C4 19.1046 4.89543 20 6 20H8C9.10457 20 10 19.1046 10 18V15C10 13.8954 9.10457 13 8 13Z"
                stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M18 4H16C14.8954 4 14 4.89543 14 6V18C14 19.1046 14.8954 20 16 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4Z"
                stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
    },
    {
        url: '/profile/messages',
        src: message,
        alt: 'messages',
        text: 'messages',
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3 20L4.3 16.1C3.17644 14.4382 2.76999 12.4704 3.15622 10.5623C3.54244 8.65415 4.69506 6.93563 6.39977 5.72623C8.10447 4.51683 10.2453 3.89885 12.4241 3.9872C14.6029 4.07554 16.6715 4.86419 18.2453 6.20652C19.819 7.54884 20.7909 9.3535 20.9801 11.2849C21.1693 13.2164 20.563 15.1432 19.2739 16.7071C17.9848 18.271 16.1007 19.3656 13.9718 19.7873C11.8429 20.2091 9.6142 19.9293 7.7 19L3 20"
                stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
    },
    {
        url: '/profile/my-followed?page=1',
        src: heartMenu,
        alt: 'followed',
        text: 'followed-ads',
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.5001 13.572L12.0001 21M12.0001 21L4.50006 13.572C4.00536 13.0906 3.6157 12.512 3.3556 11.8726C3.09551 11.2333 2.97062 10.5469 2.98879 9.85693C3.00697 9.16691 3.16782 8.48813 3.46121 7.86333C3.75461 7.23853 4.17419 6.68125 4.69354 6.22657C5.21289 5.7719 5.82076 5.42968 6.47887 5.22147C7.13697 5.01327 7.83106 4.94358 8.51743 5.0168C9.20379 5.09001 9.86756 5.30455 10.4669 5.6469C11.0663 5.98925 11.5883 6.45199 12.0001 7.00599C12.4136 6.45602 12.9362 5.99731 13.5352 5.6586C14.1341 5.31988 14.7966 5.10844 15.481 5.03751C16.1654 4.96658 16.8571 5.03769 17.5128 5.24639C18.1685 5.45508 18.7741 5.79687 19.2916 6.25036C19.8091 6.70385 20.2275 7.25928 20.5205 7.88189C20.8135 8.50449 20.9748 9.18088 20.9944 9.8687C21.0139 10.5565 20.8913 11.241 20.6342 11.8792C20.3771 12.5175 19.991 13.0958 19.5001 13.578L12.0001 21Z"
                stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
    },
    {
        url: '/profile/my-archive?page=1',
        src: archive,
        alt: 'archive',
        text: 'archive',
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 4H5C3.89543 4 3 4.89543 3 6C3 7.10457 3.89543 8 5 8H19C20.1046 8 21 7.10457 21 6C21 4.89543 20.1046 4 19 4Z"
                stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M5 8V18C5 18.5304 5.21071 19.0391 5.58579 19.4142C5.96086 19.7893 6.46957 20 7 20H17C17.5304 20 18.0391 19.7893 18.4142 19.4142C18.7893 19.0391 19 18.5304 19 18V8"
                stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 12H14" stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
    },
    {
        url: '/profile/my-invoices',
        src: creditCard,
        alt: 'payment',
        text: 'Payment and transactions',
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 5H6C4.34315 5 3 6.34315 3 8V16C3 17.6569 4.34315 19 6 19H18C19.6569 19 21 17.6569 21 16V8C21 6.34315 19.6569 5 18 5Z"
                stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 10H21" stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 15H7.01" stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 15H13" stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
    },
    {
        url: '/profile/my-settings/main-info',
        src: settings,
        alt: 'settings',
        text: 'settings',
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.325 4.317C10.751 2.561 13.249 2.561 13.675 4.317C13.7389 4.5808 13.8642 4.82578 14.0407 5.032C14.2172 5.23822 14.4399 5.39985 14.6907 5.50375C14.9414 5.60764 15.2132 5.65085 15.4838 5.62987C15.7544 5.60889 16.0162 5.5243 16.248 5.383C17.791 4.443 19.558 6.209 18.618 7.753C18.4769 7.98466 18.3924 8.24634 18.3715 8.51677C18.3506 8.78721 18.3938 9.05877 18.4975 9.30938C18.6013 9.55999 18.7627 9.78258 18.9687 9.95905C19.1747 10.1355 19.4194 10.2609 19.683 10.325C21.439 10.751 21.439 13.249 19.683 13.675C19.4192 13.7389 19.1742 13.8642 18.968 14.0407C18.7618 14.2172 18.6001 14.4399 18.4963 14.6907C18.3924 14.9414 18.3491 15.2132 18.3701 15.4838C18.3911 15.7544 18.4757 16.0162 18.617 16.248C19.557 17.791 17.791 19.558 16.247 18.618C16.0153 18.4769 15.7537 18.3924 15.4832 18.3715C15.2128 18.3506 14.9412 18.3938 14.6906 18.4975C14.44 18.6013 14.2174 18.7627 14.0409 18.9687C13.8645 19.1747 13.7391 19.4194 13.675 19.683C13.249 21.439 10.751 21.439 10.325 19.683C10.2611 19.4192 10.1358 19.1742 9.95929 18.968C9.7828 18.7618 9.56011 18.6001 9.30935 18.4963C9.05859 18.3924 8.78683 18.3491 8.51621 18.3701C8.24559 18.3911 7.98375 18.4757 7.752 18.617C6.209 19.557 4.442 17.791 5.382 16.247C5.5231 16.0153 5.60755 15.7537 5.62848 15.4832C5.64942 15.2128 5.60624 14.9412 5.50247 14.6906C5.3987 14.44 5.23726 14.2174 5.03127 14.0409C4.82529 13.8645 4.58056 13.7391 4.317 13.675C2.561 13.249 2.561 10.751 4.317 10.325C4.5808 10.2611 4.82578 10.1358 5.032 9.95929C5.23822 9.7828 5.39985 9.56011 5.50375 9.30935C5.60764 9.05859 5.65085 8.78683 5.62987 8.51621C5.60889 8.24559 5.5243 7.98375 5.383 7.752C4.443 6.209 6.209 4.442 7.753 5.382C8.753 5.99 10.049 5.452 10.325 4.317Z"
                stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                stroke="#2F49D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
    },
];

export const MenuHeader = () => {
    const {t} = useTranslation();
    const userName = useSelector(getUserName);
    const userAvatar = useSelector(getUserAvatar);
    const [modalPopupLogout, setModalPopupLogout] = useState(false);
    const [menu, setMenu] = useState(false);

    const handleClick = () => {
        setMenu(!menu);
    }

    const togglePopup = () => {
        setModalPopupLogout(!modalPopupLogout);
        setMenu(false)
    }

    return [
        <div key={0} className={'style-menu-wrapper'}>
            <div style={menu ? {pointerEvents: 'none'} : null}
                 className={'person-icon'} onClick={handleClick}>
                {userAvatar
                    ? <img src={userAvatar} alt={'person'}/>
                    : <span>{userName?.split("")[0]}</span>}
            </div>
            <OutsideClickHandler callback={() => {
                if (menu) {
                    setMenu(false);
                }
            }}>
                {menu && (
                    <div className={'theme-menu'}>
                        <div
                            id='simple-menu'>
                            <div className={'menu-header'}>
                                <Link
                                    className={'name-user-block'}
                                    onClick={() => setMenu(false)}
                                    to={`/profile/my-ads?page=1`}>
                                    <div className={'auth_initials'} onClick={handleClick}>
                                        {userAvatar
                                            ? <img src={userAvatar} alt={'user_avatar'} className={'user_default'}/>
                                            : <span>{userName?.split("")[0]}</span>}
                                    </div>
                                    <span className={'user-name-profile'}>{userName}</span>
                                </Link>
                                {menuItems.map((el, i) => (
                                    <Link to={el.url}
                                          onClick={() => setMenu(false)}
                                          key={i} className={'item-menu'}>
                                        {/*<img src={el.src} alt={el.alt}/>*/}
                                        {el.icon}
                                        <span>{t(el.text)}</span>
                                    </Link>
                                ))}
                                <div className={'logout-block'} onClick={togglePopup}>
                                    <img src={logout} alt={'logout'}/>
                                    <span>{t('logout')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </OutsideClickHandler>
        </div>,

        modalPopupLogout && <PopupLogout key={1} active={modalPopupLogout} setActive={setModalPopupLogout}/>,
    ]
};
