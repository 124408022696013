import React, {useEffect, useState} from 'react';
import Seo from "../../../seo";
import {Link, useHistory, useLocation} from "react-router-dom";
import {APIUserRequest} from "../../../API/api";
import {makeStyles, Typography} from "@material-ui/core";
import Bazarbg from "../LoginAcc/assets/background-login.webp";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    resetPasswordInfo: {
        backgroundImage: `url(${Bazarbg})`,
        backgroundPosition: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    displayInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    ResetLogoWithAlias: {
        margin: '0 auto 3rem',
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
    },

    resetLogoName: {
        marginTop: '-2rem',
        fontSize: '4.5rem',
        fontWeight: 'bold',
        letterSpacing: '0.3rem',
        color: '#ffffff',
        textAlign: 'center',
        textTransform: 'uppercase',
        '& a': {
            color: 'inherit'
        }
    },

    ResetAlias: {
        color: '#ffffff',
        marginTop: '-0.5rem',
        fontSize: '2rem',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    resetFormInfo: {
        width: "100%",
        maxWidth: '568px',
        padding: "0rem 1rem",
        [theme.breakpoints.down("xs")]: {
            width: "343px",
            height: "370px"
        },
    },
}));

export const EmailVerification = () => {
    const location = useLocation();
    const history = useHistory();
    const [token, setToken] = useState(null);
    const [success, setSuccess] = useState(null);
    const classes = useStyles();
    const { t } = useTranslation();


    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        if (urlParams && urlParams.get('token')) {
            let urlToken = urlParams.get('token');
            setToken(urlToken)
            APIUserRequest.verifyUserEmailToken(token)
                .then(() => {
                    // console.log(res)
                    setSuccess(true)
                    setTimeout(() => {
                        setSuccess(true)
                        history.push('/auth?type=login')
                    }, 3000)
                })
                .catch(() => {
                    // console.log(err)
                    setSuccess(false)
                    // changeContinuePopup(err.message)
                    // actions.setFieldError('token', err.response.data.message === 'Invalid token' ? 'Your token has expired. Please resend email to get new token' : 'err.response.data.message')
                })
        }
    }, [location.search, history, token])

    return [
        <div key={0} className={classes.resetPasswordInfo}>
            <Seo>
                <meta name="robots" content="noindex, nofollow"/>
            </Seo>
            <div className={classes.displayInfo}>
                <Typography className={classes.resetLogoName} variant='h1'>
                    <Link to={`/auth?type=login`}>{`${process.env.REACT_APP_TITLE}`}</Link>
                </Typography>
                <Typography className={classes.ResetAlias} variant='body1'>
                    {t('page-slogan')}
                </Typography>
                <div className={classes.resetFormInfo}>
                    {success ? (
                        <Typography className={classes.ResetAlias} variant='h2'>
                            {t('email-verification.successfully')}
                        </Typography>
                    ) : (
                        <Typography className={classes.ResetAlias} variant='h2'>
                            {t('email-verification.token-invalid')}
                        </Typography>
                    )
                    }
                </div>
            </div>
        </div>
        // <div key={0} className={classes.resetPasswordInfo}>
        //
        //     <Seo>
        //         <meta name="robots" content="noindex, nofollow"/>
        //     </Seo>
        //
        //     <div className={classes.displayInfo}>
        //         <div className={classes.ResetLogoWithAlias}>
        //             <Typography className={classes.resetLogoName} variant='h1'>
        //                 <Link to={`/auth?type=login`}>Bazar</Link>
        //             </Typography>
        //             <Typography className={classes.ResetAlias} variant='body1'>
        //                 hyperspace ads
        //             </Typography>
        //         </div>
        //         {token ? <EnterNewPassword token={token} changeContinuePopup={changeContinuePopup}/>
        //             : <EnterEmail changeContinuePopup={changeContinuePopup}/>}
        //     </div>
        // </div>
    ]
}
