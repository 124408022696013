import '../archive/archive.css'
import React, {useEffect, useState} from "react";
import mapPin from "../../../../sections/card/assets/map-pin.svg";
import clock from "../../../../sections/card/assets/clock.svg";
import eye from "../../../../sections/card/assets/eye.svg";
import heartCard from "../../../../sections/card/assets/heart.svg";
import messageCard from "../../../adsActions/MyAds/asssets/message-circle-card.svg";
import editAction from '../../../userArchive/assets/images/edit-archive.svg';
import activate from '../../assets/activate.svg'
import PopupActivate from "../../PopupActivate/PopupActivate";
import PopupDeleteAd from "../../../adsActions/MyAds/PopupDeleteAd/PopupDeleteAd";
import {APICardRequest} from "../../../../API/api";
import {useSelector} from "react-redux";
import {getUserId} from "../../../../redux/selectors/selectors";
import useActivePage from "../../../../Constants/useActivePage";
import {CircularProgress, Tooltip} from "@material-ui/core";
import emptyList from "../../assets/images/archive-empty-list.svg";
import {PaginationAd} from "../../../../sections/pagination/PaginationAd";
import useResize from "../../../../Constants/useResize";
import placeholderImage from '../../assets/images/placeholder_ad_image.svg'
import MobMenuArchive from "../../../../Components/mobMenuArchive/MobMenuArchive";
import {Link} from "react-router-dom";
import ChangedConfirmedPopup from "../../../../Components/SuccessPopup/ChangedConfirmedPopup";
import useBottomPopup from "../../../../Constants/useBottomPopup";
import {numberWithDots} from "../../../../Constants/numberWithDots";
import Seo from "../../../../seo";
import { useTranslation } from 'react-i18next';

const helperImgAdding = (user_id, images, title) => {
    let src = (format) => images.length ? `https://d2j7v9zcmivqbu.cloudfront.net/${user_id}/${images[0]}.${format}` : placeholderImage;
    return (
        <picture>
            <source srcSet={src('webp')} type={"image/webp"}/>
            <img src={src('jpeg')} alt={title} className={"product-plugs-img"}/>
        </picture>
    )
};

export const Archive = () => {
    const { t } = useTranslation();
    const userId = useSelector(getUserId);
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const {pageNumber, pageLoaded, setPageNumber} = useActivePage();
    const [modalActiveActivate, setModalActiveActivate] = useState({
        show: false,
        data: null,
    });
    const [modalActive, setModalActive] = useState({
        show: false,
        data: null,
    });
    const [archiveData, setArchiveData] = useState({
        data: [],
        total_pages: null,
        totalCount: null,
        loading: true,
    });
    const {innerWidth} = useResize();

    useEffect(() => {
        if (userId && pageLoaded) {
            APICardRequest.getActiveAds(userId, pageNumber, 10, true)
                .then(res => {
                    // console.log(res)
                    setArchiveData({
                        data: res.data,
                        total_pages: JSON.parse(res.headers['x-pagination']).total_pages,
                        totalCount: JSON.parse(res.headers['x-pagination']).total,
                        loading: false,
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [userId, pageLoaded, pageNumber])

    const togglePopupActivate = (data) => {
        setModalActiveActivate(state => ({
            show: !state.show,
            data,
        }));
    }

    const togglePopup = (data) => {
        setModalActive({
            show: true,
            data,
        })
    };

    const [sortByMobMenuArchive, setSortByMobMenuArchive] = useState({
        show: false,
        data: null,
    })
    // const toggleSortMobMenuArchive = (data) => {
    //     setSortByMobMenuArchive(state => ({
    //         show: !state.show,
    //         data,
    //     }));
    // }

    return [
        <div key={0} className={'wrapper_archive'}>

            <Seo>
                <meta name="robots" content="noindex, nofollow"/>
            </Seo>

            <div className={'archive-plugs'}>
                <div className={'title-and-sortBy'}>
                    <div className={'title-and-count'}>
                        <span className={'title'}>{t('Archived ads')}</span>
                        <span style={{fontSize: '18px', color: '#CBD5E1'}}>( {archiveData.totalCount} )</span>
                    </div>
                    {/*{innerWidth > 700 ? (*/}
                    {/*    <div>*/}
                    {/*        <SortByPopular/>*/}
                    {/*    </div>*/}
                    {/*) : (*/}
                    {/*    <SortByMob/>*/}
                    {/*)}*/}
                </div>

                {!archiveData.loading
                    ? archiveData.data.length
                        ? [
                            archiveData.data.map((el, i) => (
                                <div className={'archive-list-wrap'} key={i}>
                                    {innerWidth >= 700 ? (
                                            <div className={'list-plugs'}>
                                                <div className={'product-plugs'}>
                                                    {helperImgAdding(el.user.id, el.images, el.title)}
                                                    {el.top_date && (
                                                        <div className={"ad_archive_top_date"}>
                                                            <span>TOP</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={'desc'}>
                                                    <span className={'title'}>{el.title}</span>
                                                    <span className={'text'}>{el.text.trim().replace(/\n/g, '')}</span>
                                                    <div className={'inf-product'}>
                                                        <div className={'map'}>
                                                            <img src={mapPin} alt={'pin'}/>
                                                            <span>{el.loc_name ?? t('no-location')}</span>
                                                        </div>
                                                        <div className={'clock'}>
                                                            <img src={clock} alt={'clock'}/>
                                                            <span>{el.time.split("T")[0]}</span>
                                                        </div>
                                                        <div className={'archive-reactions'}>
                                                            <div className={'eye'}>
                                                                <img src={eye} alt={'eye'}/>
                                                                <span>{el.hits}</span>
                                                            </div>
                                                            <div className={'heart'}>
                                                                <img src={heartCard} alt={'heart'} className={'heart-img'}/>
                                                                <span>{el.followed}</span>
                                                            </div>
                                                            <div className={'messageCard'}>
                                                                <img src={messageCard} alt={'messages'}/>
                                                                <span>{el.reactions}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'price-and-actions'}>
                                                    <div className={'price'}>
                                                        <span>{numberWithDots(el.price)}&#8364;</span>
                                                    </div>

                                                    <div className={'actions'}>
                                                        <Tooltip title={t('activate-ad')} placement={'top'} arrow>
                                                            <img src={activate} alt={'activate'} className={'action'}
                                                                 onClick={() => togglePopupActivate(el)}/>
                                                        </Tooltip>
                                                        <Tooltip title={t('Edit Ad')} placement={'top'} arrow>
                                                            <Link to={`/ads/edit-ad/${el.id}?type=archived`}>
                                                                <img src={editAction} alt={'edit-action'}
                                                                     className={'action'}/>
                                                            </Link>
                                                        </Tooltip>
                                                        {/*<Tooltip title={'Delete Ad'} placement={'top'} arrow>*/}
                                                        {/*    <img src={deleteAction} alt={'delete-action'}*/}
                                                        {/*         className={'action'}*/}
                                                        {/*         onClick={() => togglePopup(el)}/>*/}
                                                        {/*</Tooltip>*/}

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className={'list-plugs'}>
                                                <div className={'product-plugs'}>
                                                    {helperImgAdding(el.user.id, el.images, el.title)}
                                                    {el.top_date && (
                                                        <div className={"ad_list_top_date"}>
                                                            <span>TOP</span>
                                                        </div>
                                                    )}
                                                    <div className={'date-loc'}>
                                                        <span className={'date'}>{el.time.split("T")[0]}</span>
                                                        <span className={'title'}>{el.title}</span>
                                                        <div className={'price'}>
                                                            <span>{numberWithDots(el.price)}&#8364;</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'reactions-activate'}>
                                                    <div className={'reactions-archive'}>
                                                        <div className={'eye'}>
                                                            <img src={eye} alt={'eye'}/>
                                                            <span className={'number-reactions'}>{el.hits}</span>
                                                        </div>
                                                        <div className={'heart'}>
                                                            <img src={heartCard} alt={'heart'} className={'heart-img'}/>
                                                            <span className={'number-reactions'}>{el.followed}</span>
                                                        </div>
                                                        <div className={'messageCard'}>
                                                            <img src={messageCard} alt={'messages'}/>
                                                            <span className={'number-reactions'}>{el.reactions}</span>
                                                        </div>
                                                    </div>
                                                    <div className={'actions-mob'}>
                                                        <Tooltip title={'Activate Ad'} placement={'top'} arrow>
                                                            <img src={activate} alt={'activate'} className={'action'}
                                                                 onClick={() => togglePopupActivate(el)}/>
                                                        </Tooltip>
                                                        <Tooltip title={'Edit Ad'} placement={'top'} arrow>
                                                            <Link to={`/ads/edit-ad/${el.id}?type=archived`}>
                                                                <img src={editAction} alt={'edit-action'}
                                                                     className={'action'}/>
                                                            </Link>
                                                        </Tooltip>
                                                        {/*<img src={dots} alt={'more'} className={'dots-more'}*/}
                                                        {/*     onClick={() => toggleSortMobMenuArchive(el)}/>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            )),
                            archiveData.total_pages > 1 &&
                            <PaginationAd
                                key={51}
                                isTotalPages={archiveData.total_pages}
                                pageNumber={pageNumber}
                                setPageNumber={setPageNumber}
                                loading={archiveData.loading}
                            />,
                        ]
                        : <div className={'empty_list_wrap'}>
                            <img src={emptyList} alt="empty list"/>
                            <h5>{t('archive-is-empty')}</h5>
                            <p>{t('Archived ads will be saved here')}</p>
                        </div>
                    : <CircularProgress
                        style={{position: "absolute", top: "40%", left: "50%"}}
                        size={50}
                    />}
            </div>
        </div>,

        <ChangedConfirmedPopup successText={successText}
                               key={1}
                               changingConfirmed={changingConfirmed}/>,

        sortByMobMenuArchive.show ?
            <MobMenuArchive
                key={2}
                togglePopup={() => togglePopup(sortByMobMenuArchive.data)}
                modal={sortByMobMenuArchive}
                setModal={setSortByMobMenuArchive}/> : null,

        modalActiveActivate.show ?
            <PopupActivate
                key={3}
                modal={modalActiveActivate}
                setArchiveData={setArchiveData}
                setModal={setModalActiveActivate}/> : null,

        modalActive ?
            <PopupDeleteAd
                key={4}
                setArchiveData={setArchiveData}
                modal={modalActive}
                setModal={setModalActive}
                pageNumber={pageNumber}
                isTotalPages={archiveData.total_pages}
                totalCount={archiveData.totalCount}
                adsData={archiveData.data}
                userId={userId}
                setPageNumber={setPageNumber}
                changeContinuePopup={changeContinuePopup}
            /> : null,
    ]

};