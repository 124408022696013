import React, {useEffect, useState} from "react";
import './transactions.css'
import {APIUserRequest} from "../../../../../API/api";
import {CircularProgress} from "@material-ui/core";
import emptyList from '../../assets/payment_list_empty.svg'
import useResize from "../../../../../Constants/useResize";
import caret from "../Payment/assets/caret-down.svg";
import {PaginationAd} from "../../../../../sections/pagination/PaginationAd";
import useActivePage from "../../../../../Constants/useActivePage";
import moment from "moment";
import {useTranslation} from 'react-i18next';

const Transactions = () => {
    const {t} = useTranslation();
    const [transactionData, setTransactionData] = useState([]);
    const [loading, setLoading] = useState(true);
    const {innerWidth} = useResize();
    const {pageNumber, setPageNumber, pageLoaded} = useActivePage();
    const [pagination, setPagination] = useState({
        total_pages: null,
        totalCount: null,
    });

    useEffect(() => {
        if (pageLoaded) {
            APIUserRequest.getUserTransactions(pageNumber)
                .then(res => {
                    // console.log(res)
                    setTransactionData(res.data.map(x => {
                        return {
                            ...x,
                            open: false
                        }
                    }))
                    setLoading(false)
                    setPagination({
                        total_pages: JSON.parse(res.headers['x-pagination']).total_pages,
                        totalCount: JSON.parse(res.headers['x-pagination']).total,
                    })
                })
                .catch(alert);
        }
    }, [pageNumber, pageLoaded])

    return (
        <div>
            <div
                className={`wrapper_invoices overflow_scrollbar ${!transactionData.length ? 'wrapper_invoices_empty' : ''}`}>
                {innerWidth >= 700 ? (
                    <div className={'content'}>
                        <div className={'title-invoices'}
                             style={{gridTemplateColumns: '180px 222px minmax(100px, 1fr) 100px'}}>
                            <span>{t('created')}</span>
                            <span>{t('transaction-number')}</span>
                            <span>{t('transaction-type')}</span>
                            <span>{t('top-used')}</span>
                            <div/>
                        </div>

                        <div className={'body'}>
                            {!loading
                                ? transactionData.length
                                    ? transactionData.map((item, index) => (
                                        <div className={`inf-invoices ${index % 2 === 1 ? 'inf-invoices-bg' : ''}`}
                                             key={index}
                                             style={{gridTemplateColumns: '180px 222px minmax(100px, 1fr) 100px'}}>
                                            <span className={'date'}>
                                                {moment.utc(item.created).local().format('MMM. DD, YYYY - HH:mm')}
                                            </span>
                                            <span className={'number'}>{item.id}</span>
                                            <span
                                                className={'period'}>{item.type}</span>
                                            <span className={'number'}>{item.amount}</span>
                                        </div>
                                    ))
                                    : <div className={'empty_list_wrap'}>
                                        <img src={emptyList} alt="empty list"/>
                                        <h5>{t('Transaction list is empty')}</h5>
                                        <p>{t('As soon as you make a TOP for your ad, all transactions will appear here')}</p>
                                    </div>
                                : <CircularProgress
                                    style={{position: "absolute", top: "40%", left: "50%"}}
                                    size={50}
                                />}
                        </div>
                    </div>
                ) : (
                    <div className={'content'}>
                        <div className={'title-invoices'} style={{gridTemplateColumns: '1fr 110px'}}>
                            <span>{t('created')}</span>
                            <span>{t('transaction-type')}</span>
                        </div>
                        <div className={'body'}>
                            {!loading
                                ? transactionData.length
                                    ? transactionData.map((item, period) => (
                                        <div key={period}>
                                            <div className={'inf-invoices'}
                                                 style={{
                                                     backgroundColor: item.style,
                                                     gridTemplateColumns: '1fr 50px 24px'
                                                 }}>
                                                <div className={'paid-and-title'}>
                                        <span
                                            className={'period'}>{moment.utc(item.created).local().format('MMM. DD, YYYY - HH:mm')}</span>
                                                </div>
                                                <div className={'amount-number'}>
                                                <span
                                                    className={'period'}>{item.type}</span>
                                                </div>
                                                <img style={{cursor: 'pointer'}} src={caret} alt={'more'}
                                                     onClick={() => {
                                                         setTransactionData(prevState => ([
                                                             ...prevState.slice(0, period),
                                                             {...item, open: !item.open},
                                                             ...prevState.slice(period + 1),
                                                         ]))
                                                     }}/>
                                            </div>
                                            {
                                                item.open && (
                                                    <div className={'date-number'}>
                                                        <div>
                                                            <div className={'date-mob'} style={{margin: '0 0 .5rem'}}>
                                                                <span className={'title-date'}>{t('top-used')}</span>
                                                                <span className={'number'}>{item.amount}</span>
                                                            </div>
                                                            <div className={'date-mob'}>
                                                                <span className={'title-date'}>{t('transaction-number')}</span>
                                                                <span className={'number'}>{item.id}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            }
                                        </div>
                                    ))
                                    : <div className={'empty_list_wrap'}>
                                        <img src={emptyList} alt="empty list"/>
                                        <h5>{t('Transaction list is empty')}</h5>
                                        <p>{t('As soon as you make a TOP for your ad, all transactions will appear here')}</p>
                                    </div>
                                : <CircularProgress
                                    style={{position: "absolute", top: "40%", left: "50%"}}
                                    size={50}
                                />}
                        </div>
                    </div>
                )}
            </div>

            {pagination.totalCount > 10 ?
                <PaginationAd loading={loading} isTotalPages={pagination.total_pages}
                              pageNumber={pageNumber}
                              setPageNumber={setPageNumber}/> : null}
        </div>
    );
};

export default Transactions;