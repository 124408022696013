import React from 'react';
// import Header from "../pages/header";
import {Route} from "react-router-dom";
import {Navigation} from "./navigation";
// import Footer from "../pages/footer/Footer";

const PrivateRouterNavigation = (props) => {
    return (
        <div className={'wrapper_app'}>
            {/*<Header/>*/}
            {Navigation.protectedNavigation.map(route => (
                <Route path={route.path}
                       exact
                       {...props}
                       component={route.main}
                       key={route.path}/>
            ))}
            {/*<Footer/>*/}
        </div>
    );
};

export default PrivateRouterNavigation;