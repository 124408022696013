import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';
import {Link} from "react-router-dom";

export const ImgWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 30%;
  //height: 100%;
`;

export const StyledImg = styled.img`
  width: 168px;
  height: auto;
  object-fit: cover;
  @media (max-width: 1260px) {
    max-height: 185px;
  }
  @media (max-width: 900px) {
    max-height: 300px;
    width: 100%;
    max-width: 100%;
  }
`;

export const StyledBlockHeart = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
`;

export const StyledAttrs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  font-size: 14px;
`;

export const StyledBlockLoc = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
`;

export const StyledBlockHit = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledBlockAroundHeart = styled.div`
  display: flex;
  background: #EEF0FB;
  border-radius: 50%;
  padding: 5px;

`;

export const StyledTextWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 6px 0 6px 24px;
  flex-direction: column;
  justify-content: space-between;
  //word-break: break-all;
  //white-space: pre-wrap;
  @media (max-width: 900px) {
    padding: 6px 0 6px 0;
  }
`;

export const StyledTimeAgoText = styled.span`
  color: #475569;
`;
export const StyledDescriptionText = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: rgba(71, 85, 105, 0.6);
  max-width: 600px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 1200px) {
    max-width: 320px;
  }
  @media (max-width: 990px) {
    max-width: 250px;
  }
`;

export const StyledPriceText = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: right;
  color: #475569;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;

  @media (max-width: 900px) {
    width: 100%;
    display: flex;
  }
`;



export const StyledCardStatsWrapper = styled.div`
  display: grid;
  grid-template-columns: 110px 200px 105px 75px 75px;
  grid-gap: 15px;
  // height: 100%;
  // flex-direction: row;
  // //justify-content: center;
  // //align-items: center;
  // width: 100%;
  @media (max-width: 1255px) {
    grid-template-columns: unset;
  }

  & > button {
    padding: 2px;
    font-size: 0.5rem;
  }
`;

export const StyledCardStatsContentLink = styled(Link)`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  grid-gap: 8px;

  & > p {
    color: #475569;
  }
`

export const StyledCardStatsContent = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  grid-gap: 5px;

  & > p {
    color: #475569;
  }

`;

export const StyledCardStatsContentLoc = styled.div`
  display: grid;
  grid-template-columns: max-content 180px;
  align-items: center;
  grid-gap: 5px;

  & > p {
    color: #475569;
  }
  @media (max-width: 1255px) {
    grid-template-columns: max-content 300px;
  }

  @media (max-width: 400px) {
    grid-template-columns: max-content 180px;
  }
`;

export const CardLink = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`

export const CardWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: row;
  border-radius: 8px;
  margin-bottom: 24px;
  background: #ffffff;
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;


    & > ${ImgWrapper} {
      width: 100%;
      height: 30%;

      & > ${StyledImg} {
        width: 305px;
        height: 245px;
      }

    }

    & > ${StyledTextWrapper} {
      width: 50%;

      & > ${StyledPriceText} {
        width: 60%;
      }
    }

    & > ${StyledCardStatsWrapper} {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 43%;

      & > ${StyledCardStatsContent} {
        padding-left: 15px;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  @media (max-width: 630px) {
    & > ${ImgWrapper} > ${CardLink} {
      width: 100%;
    }
  }
`;



styled(Badge)`
  & > span:last-child {
    background-color: rgba(39, 255, 41, 0.32);
    color: #3b3b3b;
  }

  @media (max-width: 600px) {
    //top:0;
    //left: 90%;
    //bottom: 50%;
  }
`;


export const SImgTopWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #20C576;;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2px .5rem;
  //padding: 0.2rem 1.3rem 1.3rem;
  & > span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }
`;
