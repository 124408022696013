import React, {createContext, useState} from "react";

const authContext = createContext();
export default function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}


const fakeAuth = {
    isAuthenticated: false,
    signin(cb) {
        let promise = new Promise(res => {
            res(fakeAuth.isAuthenticated = true);
        });
        promise.then(() => cb());
    },
    signout(cb) {
        let promise = new Promise(res => {
            res(fakeAuth.isAuthenticated = false);
        });
        promise.then(() => cb());
    }
};

function useProvideAuth() {
    const [user, setUser] = useState(null);

    const signin = cb => {
        return fakeAuth.signin(() => {
            setUser("user");
            cb();
        });
    };

    const signout = cb => {
        return fakeAuth.signout(() => {
            setUser(null);
            cb();
        });
    };

    return {
        user,
        signin,
        signout
    };
}