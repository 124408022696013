import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';

// export const ImgWrapper = styled.div`
//   display: flex;
//   position: relative;
//   justify-content: center;
//   align-items: center;
//   width: 30%;
//   height: 100%;
// `;
















export const StyledReactionsComponent = styled.div`
  display: grid;
  grid-template-columns: 120px 75px 75px;
  grid-gap: 10px;
  @media (max-width: 1020px) {
    grid-template-columns: 103px 52px 58px;
  }
`;

// export const CardWrapper = styled.div`
//   display: flex;
//   width: 90%;
//   height: 142px;
//   padding: 10px;
//   flex-direction: row;

//   //border-bottom: 2px solid #a9a9a9;
//   margin-bottom: 24px;
//   cursor: pointer;
//   background: #ffffff;
//   @media (max-width: 1300px) {
//     width: 93%;
//     // width: 90%;
//   }
//   @media (max-width: 600px) {
//     flex-direction: row;
//     width: 100%;
//     height: 100%;
//     flex-wrap: wrap;

//     & > ${ImgWrapper} {
//       width: 100%;
//       height: 30%;

//       & > ${StyledImg} {
//         width: 80%;
//       }
//     }

//     & > ${StyledTextWrapper} {
//       width: 50%;

//       & > ${StyledPriceText} {
//         width: 60%;
//       }
//     }

//     & > ${StyledCardStatsWrapper} {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 43%;

//       & > ${StyledCardStatsContent} {
//         padding-left: 15px;
//         justify-content: flex-start;
//         align-items: center;
//       }
//     }
//   }
// `;








styled(Badge)`
  & > span:last-child {
    background-color: rgba(39, 255, 41, 0.32);
    color: #3b3b3b;
  }

  @media (max-width: 600px) {
    //top:0;
    //left: 90%;
    //bottom: 50%;
  }
`;






