import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import '../auth/auth.css';
import { useTranslation } from 'react-i18next';

const AuthWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5px;
  justify-content: center;

  & > button:first-child {
    margin-right: 1rem;
  }

  @media (max-width: 830px) {
    display: none;
  }

`;

export const Auth = () => {
    const [open,] = useState(false);
//setOpen
    const history = useHistory();
    const { t } = useTranslation();

    const handleLoginAuth = (type) => {
        history.push(`/auth?type=${type}`);
    };

    return (
        <AuthWrapper openMenu={open.toString()}>
            <button
                className={'button-sign-up'}
                onClick={() => handleLoginAuth('sign-up')}>
                <span>{t('sign-up')}</span>
            </button>

            <button
                className={'button-log-in'}
                onClick={() => handleLoginAuth('login')}>
                <span>{t('log-in')}</span>
            </button>
        </AuthWrapper>
    );
};
