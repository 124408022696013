import imageCompression from "browser-image-compression";

function compressImage(file) {
    // console.log('originalFile instanceof Blob', file instanceof Blob); // true
    // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
    const originalFile = Promise.resolve(file);

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        maxIteration: 2,
        fileType: 'image/webp',
    }
    const max_skip_size = 0.5 // in MB

    if (file.size / 1024 / 1024 < max_skip_size) {
        // console.log("Skipping compression image to small")
        return originalFile;
    }

   return imageCompression(file, options)
        .then(function (compressedFile) {
            // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            return compressedFile;
        })
        .catch(function (error) {
            console.log(error.message);
            throw error;
        });
}

export default compressImage;