import React, {useEffect, useState, useRef} from "react"
import {useDispatch} from 'react-redux';
import {FormWrapper, ImgCancel, ImgIcon, InputWrapper, SearchItemWrapper} from "./searchStyle";
import {useHistory, useLocation} from "react-router-dom";
import {getAdsData} from "../../../redux/reducers/ads/sliceAds";
import searchIcon from "../assets/search-white.svg";
import cancel from "../assets/cancel.svg";
import {useTranslation} from 'react-i18next';
import OutsideClickHandler from "../../../Constants/OutsideClickHandler";
import {CircularProgress} from "@material-ui/core";
import {APICardRequest} from "../../../API/api";

const initState = {
    opened: false,
    data: [],
    loaded: false,
    text: '',
}

const SearchMobile = (props) => {
    const {openSearchBar, setOpenSearchBar, setNavOpen} = props
    const history = useHistory()
    const dispatch = useDispatch();
    const location = useLocation();
    const {search} = useLocation();
    const formRef = useRef()
    const inputFocus = useRef()
    const [searchItem, setSearchItem] = useState(() => initState)
    const {t} = useTranslation();

    useEffect(() => {
        let searchResult = new URLSearchParams(location.search).get('search')
        if (searchResult) {
            setSearchItem(prevState => ({
                ...prevState,
                text: searchResult,
            }))
            setOpenSearchBar(true)
            // inputFocus.current.focus()
        } else {
            setSearchItem(initState)
            setOpenSearchBar(false)
        }
    }, [location.search, setOpenSearchBar])

    useEffect(() => {
        if (searchItem.text.length > 1) {
            APICardRequest.getFilterAdWords(searchItem.text)
                .then(res => {
                    // console.log(res)
                    setSearchItem(prevState => ({
                        ...prevState,
                        data: res.data,
                        loaded: true,
                    }))
                })
        }
    }, [searchItem.text, dispatch])

    const SubmitSearchVal = (e) => {
        e.preventDefault();
        setNavOpen(false)
        setOpenSearchBar(true)
        // inputFocus.current.blur()
        const params = new URLSearchParams(window.location.search)
        const search = params.get("search")

        if (search) {
            params.delete("search")
        }

        params.set("search", `${searchItem.text}`)

        if (!searchItem.text.length) {
            params.delete("search")
        }

        // if (!searchItem.text.trim().length) return;

        const data = {
            text: searchItem.text
        }
        dispatch(getAdsData(data))
        params.set("page", '1')
        history.push(`/ads?${params.toString()}`);
    }

    useEffect(() => {
        if (!search) {
            setSearchItem(initState)
        }
    }, [search]);


    const handleClearText = () => {
        setSearchItem(initState)
        setTimeout(() => {
                setOpenSearchBar(false)
                inputFocus.current.blur()
            }
            , 4000);


    }

    const handleOpen = (e) => {
        if (e.target.value.length > 1) {
            setSearchItem(prevState => ({
                ...prevState,
                opened: true,
            }))
        } else {
            setSearchItem(prevState => ({
                ...prevState,
                opened: false,
                loaded: false,
            }))
        }
    }

    return (
        <SearchItemWrapper>
            <FormWrapper
                ref={formRef}
                onSubmit={SubmitSearchVal}
                openSearchBar={openSearchBar}
                onClick={() => {
                    setOpenSearchBar(true)
                    inputFocus.current.focus()
                }}
                onFocus={() => {
                    setOpenSearchBar(true)
                    inputFocus.current.focus()
                }}

                onBlur={() => {
                    if (!inputFocus.current.value) {
                        setOpenSearchBar(false)
                        inputFocus.current.blur()
                    }
                }}
            >

                <ImgIcon
                    width={"24"}
                    height={"24"}
                    src={searchIcon}
                    alt={"search"}
                />
                {/*{searchItem.opened*/}
                {/*    ? <OutsideClickHandler callback={() => {*/}
                {/*        setSearchItem(prevState => ({*/}
                {/*            ...prevState,*/}
                {/*            opened: false,*/}
                {/*        }))*/}
                {/*    }}>*/}
                {/*        <div className={'search_result_wrap'}>*/}
                {/*            <div className={"search-result overflow_scrollbar"}>*/}
                {/*                <div className={"product-list"}>*/}
                {/*                    {searchItem.loaded*/}
                {/*                        ? searchItem.data.length*/}
                {/*                            ? searchItem.data.map((product) => (*/}
                {/*                                <div*/}
                {/*                                    onClick={() => {*/}
                {/*                                        setSearchItem(prevState => ({*/}
                {/*                                            ...prevState,*/}
                {/*                                            text: product.name,*/}
                {/*                                            opened: false,*/}
                {/*                                        }))*/}
                {/*                                        history.push(`/ads?page=1&search=${product.name}`)*/}
                {/*                                    }}*/}
                {/*                                    className="product-item" key={product.id}>*/}
                {/*                                    <span>{product.name}</span>*/}
                {/*                                </div>*/}
                {/*                            ))*/}
                {/*                            : <div className="product-item">*/}
                {/*                                <span>{t('main.no-results')}</span>*/}
                {/*                            </div>*/}
                {/*                        : <CircularProgress*/}
                {/*                            style={{position: "absolute", top: "40%", left: "50%"}}*/}
                {/*                            size={50}*/}
                {/*                        />}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </OutsideClickHandler> : null}*/}
                <InputWrapper
                    // autoFocus={true}
                    ref={inputFocus}
                    openSearchBar={openSearchBar}
                    autoComplete={"off"}
                    type="text"
                    name="text"
                    onClick={handleOpen}
                    onChange={(e) => {
                        setSearchItem(prevState => ({
                            ...prevState,
                            text: e.target.value,
                        }))
                        handleOpen(e)

                        if (!inputFocus.current.value) {
                            setTimeout(() => {
                                    if (!inputFocus.current.value) {
                                        setOpenSearchBar(false)
                                        inputFocus.current.blur()
                                    }
                                }
                                , 4000);
                        }

                    }}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.text}
                    value={searchItem.text}

                    placeholder={t('search-in-bazar')}
                />
                {searchItem.text ?
                    <ImgCancel
                        openSearchBar={openSearchBar}
                        onClick={handleClearText}
                        src={cancel} alt={"cancel"}/> : null}


            </FormWrapper>
            {searchItem.opened
                ? <OutsideClickHandler callback={() => {
                    setSearchItem(prevState => ({
                        ...prevState,
                        opened: false,
                    }))
                }}>
                    <div className={'search_result_wrap'}>
                        <div className={"search-result overflow_scrollbar"}>
                            <div className={"product-list"}>
                                {searchItem.loaded
                                    ? searchItem.data.length
                                        ? searchItem.data.map((product) => (
                                            <div
                                                onClick={() => {
                                                    setSearchItem(prevState => ({
                                                        ...prevState,
                                                        text: product.name,
                                                        opened: false,
                                                    }))
                                                    history.push(`/ads?page=1&search=${product.name}`)
                                                }}
                                                className="product-item" key={product.name}>
                                                <span>{product.name}</span>
                                            </div>
                                        ))
                                        : <div className="product-item">
                                            <span>{t('main.no-results')}</span>
                                        </div>
                                    : <CircularProgress
                                        style={{position: "absolute", top: "40%", left: "50%"}}
                                        size={50}
                                    />}
                            </div>
                        </div>
                    </div>
                </OutsideClickHandler> : null}
        </SearchItemWrapper>
    )

}

export default SearchMobile