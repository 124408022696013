import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {isLoginSelector} from "../../redux/selectors/selectors";
import {APICardRequest} from "../../API/api";
import {IconButton} from "@material-ui/core";
import {setFollowedAd, updateFollowedCount} from "../../redux/reducers/ads/sliceAds";

export const HeartAd = (props) => {
    const {
        item, deleteFromFollowed, fill, width, handleFollowedItem,
        height, setUserActive, index, togglePopupDeleteFollow
    } = props;
    const dispatch = useDispatch();
    const isLogin = useSelector(isLoginSelector);

    const handleFollow = (e) => {
        e.preventDefault();
        if (!!deleteFromFollowed) {
            togglePopupDeleteFollow()
        } else {
            APICardRequest.followCard(item.id)
                .then(res => {
                    if (!!handleFollowedItem) {
                        handleFollowedItem(res.data)
                    } else {
                        if (!!setUserActive) {
                            setUserActive(prevState => ({
                                ...prevState,
                                data: [
                                    ...prevState.data.slice(0, index),
                                    {...prevState.data[index], liked: res.data.message === 'following'},
                                    ...prevState.data.slice(index + 1),
                                ]
                            }))
                            return dispatch(updateFollowedCount(res.data.message === 'following'))
                        } else {
                            return dispatch(setFollowedAd({index, status: res.data.message}))
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    };

    return isLogin ? (
        <div className={"heart"} onClick={handleFollow}>
            <IconButton size={"small"}>
                <svg
                    className={item.liked ? "active-love" : "non_active_love"}
                    width={width ? width : "24"}
                    height={height ? height : "24"}
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    color={fill}
                >
                    <path
                        d="M14.625 10.679L8.99998 16.25M8.99998 16.25L3.37498 10.679C3.00396 10.318 2.71171 9.88402 2.51664 9.40449C2.32157 8.92495 2.2279 8.41022 2.24153 7.89271C2.25517 7.3752 2.3758 6.86611 2.59585 6.39751C2.8159 5.92891 3.13058 5.51095 3.5201 5.16995C3.90961 4.82894 4.36551 4.57228 4.85909 4.41612C5.35267 4.25997 5.87324 4.2077 6.38801 4.26261C6.90278 4.31752 7.40061 4.47843 7.85014 4.73519C8.29967 4.99195 8.69117 5.33901 8.99998 5.75451C9.31013 5.34203 9.70208 4.998 10.1513 4.74396C10.6005 4.48993 11.0974 4.33135 11.6107 4.27815C12.124 4.22495 12.6428 4.27828 13.1346 4.4348C13.6263 4.59132 14.0805 4.84767 14.4686 5.18778C14.8568 5.5279 15.1706 5.94447 15.3903 6.41143C15.61 6.87839 15.731 7.38567 15.7457 7.90154C15.7604 8.41741 15.6684 8.93076 15.4756 9.40945C15.2827 9.88814 14.9932 10.3219 14.625 10.6835L8.99998 16.25Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </IconButton>
        </div>
    ) : null;
};
