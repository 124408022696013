import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getTotalCount, getTotalPages, getAccessTokenSelector,
    getUserAdsSelector, getUserId, usersAdsLoadingSelector
} from "../../../redux/selectors/selectors";
import {getUserAds} from "../../../redux/reducers/ads/sliceAds";
import {PaginationAd} from "../../../sections/pagination/PaginationAd";
import {useHistory, Link} from "react-router-dom";
import "../MyAds/myAds.css";
import mapPin from "../../../sections/card/assets/map-pin.svg";
import clock from "../../../sections/card/assets/clock.svg";
import eye from "../../../sections/card/assets/eye.svg";
import heartCard from "../../../sections/card/assets/heart.svg";
import messageCard from "../MyAds/asssets/message-circle-card.svg";
import trendingUp from "../MyAds/asssets/trending-up.svg";
import deleteAction from "../MyAds/asssets/deleteAction.svg";
import editAction from "../MyAds/asssets/editAction.svg";
import characterPlant from "../MyAds/asssets/characterPlant.svg";
import PopupRaise from "./PopupRaise/PopupRaise"
import PopupDeleteAd from "./PopupDeleteAd/PopupDeleteAd";
import PopupArchive from "./PopupArchive/PopupArchive";
import {CircularProgress, Tooltip} from "@material-ui/core";
import placeholderImage from "./asssets/placeholder_ad_image.svg";
import useActivePage from "../../../Constants/useActivePage";
import useResize from "../../../Constants/useResize";
import actionMob from '../MyAds/asssets/actionMob.svg'
import dots from "../../userArchive/assets/images/dots.svg";
import MobMenu from "../../../Components/mobMenu/MobMenu";
import ChangedConfirmedPopup from "../../../Components/SuccessPopup/ChangedConfirmedPopup";
import useBottomPopup from "../../../Constants/useBottomPopup";
import {numberWithDots} from "../../../Constants/numberWithDots";
import PopupPurchase from "../../adsActions/MyAds/PopupPurchase/PopupPurchase";
import PopupError from "../../adsActions/MyAds/PopupError/PopupError";
import Seo from "../../../seo";
import {useTranslation} from 'react-i18next';

const helperImgAdding = (user_id, images, title) => {
    let src = (format) => images.length ? `https://d2j7v9zcmivqbu.cloudfront.net/${user_id}/${images[0]}.${format}` : placeholderImage;
    return (
        <picture>
            <source srcSet={src('webp')} type={"image/webp"}/>
            <img src={src('jpeg')} alt={title} className={"product-plugs-img"}/>
        </picture>
    )
};

export const MyAds = () => {
    const history = useHistory();
    const token = useSelector(getAccessTokenSelector);
    const userAdsData = useSelector(getUserAdsSelector);
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const loading = useSelector(usersAdsLoadingSelector);
    const userId = useSelector(getUserId);
    const totalPages = useSelector(getTotalPages);
    const totalCount = useSelector(getTotalCount);
    const dispatch = useDispatch();
    const {pageNumber, pageLoaded, setPageNumber} = useActivePage();
    const {innerWidth} = useResize();
    const [modalActivePurchase, setModalActivePurchase] = useState(false)
    const [modalActiveError, setModalActiveError] = useState(false)
    const [sortByMobMenu, setSortByMobMenu] = useState({
        show: false,
        data: null,
    })
    const [modalActiveRaise, setModalActiveRaise] = useState({
        show: false,
        data: null,
    });

    const [modalActive, setModalActive] = useState({
        show: false,
        data: null,
    });
    const [modalActiveArchive, setModalActiveArchive] = useState({
        show: false,
        data: null,
    });

    const {t} = useTranslation();

    const toggleSortMobMenu = (data) => {
        setSortByMobMenu(state => ({
            show: !state.show,
            data,
        }));
    }
    const togglePopup = (data) => {
        setModalActive({
            show: true,
            data,
        })
    };

    const togglePopupArchive = (data) => {
        setModalActiveArchive(prevState => ({
            show: !prevState.show,
            data,
        }))
    };

    const togglePopupRaise = (data) => {
        setModalActiveRaise(prevState => ({
            show: !prevState.show,
            data,
        }))
    };

    const getMobileTitleSubstring = (title, innerWidth) => {
        let characters = (innerWidth - 180) / 10
        return title.length > 18
            ? title.substring(0, characters) + '...'
            : title
    }

    useEffect(() => {
        if (pageLoaded && userId) dispatch(getUserAds({id: userId, page: pageNumber}))
    }, [dispatch, pageNumber, pageLoaded, userId]);

    return [
        <div key={0} className={"wrapper_myAds"}>

            <Seo>
                <meta name="robots" content="noindex, nofollow"/>
            </Seo>

            <div className={"list"}>
                <div className={"title-and-sortBy"}>
                    <div className={"title-and-count"}>
                        <span className={"title"}>{t('my-ads-list')}</span>
                        <span style={{fontSize: "18px", color: "#CBD5E1"}}>( {totalCount} )</span>
                    </div>
                    {/*{innerWidth > 700 ? (*/}
                    {/*    <div>*/}
                    {/*        <SortByPopular/>*/}
                    {/*    </div>*/}
                    {/*) : (*/}
                    {/*    <SortByMob/>*/}
                    {/*)}*/}
                </div>
                {!loading ? (
                    userAdsData.length ? (
                        [
                            userAdsData.map((userAds, key) => {
                                return (
                                    <div className={'archive-list-wrap'} key={key}>
                                        {innerWidth > 700 ? (
                                                <div className={"list-plugs"}>
                                                    <Link to={`/ads/detail-ad/${userAds.id}/${userAds.slug ?? ''}`}
                                                          className={'product-plugs'}>
                                                        <Tooltip title={`${t('open')} ${userAds.title} ${t('ad-detail')}`}
                                                                 placement={'top'}
                                                                 arrow>
                                                            {helperImgAdding(userAds.user.id, userAds.images, userAds.title)}
                                                        </Tooltip>
                                                        {userAds.top_date && (
                                                            <div className={"ad_list_top_date"}>
                                                                <span>TOP</span>
                                                            </div>
                                                        )}
                                                    </Link>

                                                    <div className={"desc"}>
                                                        <Link to={`/ads/detail-ad/${userAds.id}/${userAds.slug ?? ''}`}>
                                                            <Tooltip
                                                                title={`${t('open')} ${userAds.title} ${t('ad-detail')}`}
                                                                placement={'top'} arrow>
                                                                <span className={"title"}>{userAds.title}</span>
                                                            </Tooltip>
                                                        </Link>
                                                        <span
                                                            className={"text"}>{userAds.text.trim().replace(/\n/g, '')}</span>
                                                        <div className={"inf-product"}>
                                                            <div className={"map"}>
                                                                <img src={mapPin} alt={"pin"}/>
                                                                <span>{userAds.loc_name ?? t('no-location')}</span>
                                                            </div>
                                                            <div className={"clock"}>
                                                                <img src={clock} alt={"clock"}/>
                                                                <span>{userAds.time.split("T")[0]}</span>
                                                            </div>
                                                            <div className={'ads-reactions'}>
                                                                <div className={"eye"}>
                                                                    <img src={eye} alt={"eye"}/>
                                                                    <span>{userAds.hits}</span>
                                                                </div>
                                                                <div className={"heart"}>
                                                                    <img src={heartCard} alt={"heart"}
                                                                         className={'heart-img'}/>
                                                                    <span>{userAds.followed}</span>
                                                                </div>
                                                                <div className={"messageCard"}>
                                                                    <img src={messageCard} alt={"messages"}/>
                                                                    <span>{userAds.reactions}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"price-and-actions"}>
                                                        <div className={"price"}>
                                                            <span>{numberWithDots(userAds.price)} &#8364;</span>
                                                        </div>
                                                        <div className={"actions"}>
                                                            <Tooltip title={t('Raise Ad to TOP')} placement={'top'}
                                                                     arrow>
                                                                <img
                                                                    src={trendingUp}
                                                                    alt={"trending-up"}
                                                                    className={userAds ? "action" : "non-active-payed"}
                                                                    onClick={() => togglePopupRaise(userAds)}
                                                                />
                                                            </Tooltip>

                                                            <Tooltip title={t('Edit Ad')} placement={'top'} arrow>
                                                                <img
                                                                    onClick={() =>
                                                                        history.push(`/ads/edit-ad/${userAds.id}`)
                                                                    }
                                                                    src={editAction}
                                                                    alt={"edit-action"}
                                                                    className={"action"}
                                                                />
                                                            </Tooltip>

                                                            <Tooltip title={t('remove-ad')} placement={'top'} arrow>
                                                                <img
                                                                    src={deleteAction}
                                                                    alt={"delete-action"}
                                                                    className={"action"}
                                                                    onClick={() => togglePopupArchive(userAds)}
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            : (
                                                <div className={'list-plugs'}>
                                                    <div className={"product-plugs"}>
                                                        <Link
                                                            to={`/ads/detail-ad/${userAds.id}/${userAds.slug ?? ''}`}>
                                                            <Tooltip
                                                                title={`${t('open')} ${userAds.title} ${t('ad-detail')}`}
                                                                placement={'top'}
                                                                arrow>
                                                                {helperImgAdding(userAds.user.id, userAds.images, userAds.title)}
                                                            </Tooltip>

                                                            {userAds.top_date && (
                                                                <div className={"ad_list_top_date"}>
                                                                    <span>TOP</span>
                                                                </div>
                                                            )}
                                                        </Link>
                                                        <div className={'date-loc'}>
                                                                    <span
                                                                        className={'date'}>{userAds.time.split("T")[0]}</span>
                                                            <Link
                                                                to={`/ads/detail-ad/${userAds.id}/${userAds.slug ?? ''}`}>
                                                                <Tooltip
                                                                    title={`${t('open')} ${userAds.title} ${t('ad-detail')}`}
                                                                    placement={'top'}
                                                                    arrow>
                                                                    <span
                                                                        className={"title"}>{getMobileTitleSubstring(userAds.title, innerWidth)}</span>
                                                                </Tooltip>
                                                            </Link>
                                                            <div className={"price"}>
                                                                <span>{numberWithDots(userAds.price)} &#8364;</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'reactions-activate'}>
                                                        <div className={'reactions-archive'}>
                                                            <div className={"eye"}>
                                                                <img src={eye} alt={"eye"}/>
                                                                <span
                                                                    className={'number-reactions'}>{userAds.hits}</span>
                                                            </div>
                                                            <div className={"heart"}>
                                                                <img src={heartCard} alt={"heart"}
                                                                     className={'heart-img'}/>
                                                                <span
                                                                    className={'number-reactions'}>{userAds.followed}</span>
                                                            </div>
                                                            <div className={"messageCard"}>
                                                                <img src={messageCard} alt={"messages"}/>
                                                                <span
                                                                    className={'number-reactions'}>{userAds.reactions}</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Tooltip title={t('Raise Ad to TOP')} placement={'top'}
                                                                     arrow>
                                                                <img
                                                                    src={actionMob}
                                                                    alt={"trending-up"}
                                                                    className={"action"}
                                                                    onClick={() => togglePopupRaise(userAds)}

                                                                />
                                                            </Tooltip>
                                                            <img src={dots} alt={'more'}
                                                                 className={'dots-more'}
                                                                 onClick={() => toggleSortMobMenu(userAds)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                );
                            }),
                            totalPages > 1 &&
                            <PaginationAd
                                key={1}
                                isTotalPages={totalPages}
                                pageNumber={pageNumber}
                                setPageNumber={setPageNumber}
                                loading={loading}
                            />,
                        ]
                    ) : (
                        <div className={"selling-character"}>
                            <img src={characterPlant} alt={"pin"}/>
                            <h4>{t('Start Selling')}</h4>
                            <span>{t('Add your first ad')}</span>
                            <Link to="/createAd">
                                <button type={"submit"} className={"btn-sell"}>
                                    {t("create-ad")}
                                </button>
                            </Link>
                        </div>
                    )
                ) : (
                    <div className={"load_circle_wrapper"}>
                        <CircularProgress/>
                    </div>
                )}
            </div>
        </div>,
        modalActiveRaise.show && (
            <PopupRaise
                key={1}
                modal={modalActiveRaise}
                setModal={setModalActiveRaise}
                token={token}
                setModalActivePurchase={setModalActivePurchase}
                setModalActiveError={setModalActiveError}
                // id={modal.data.id}

            />
        ),

        modalActiveArchive.show && (
            <PopupArchive
                key={2}
                modal={modalActiveArchive}
                dispatch={dispatch}
                setModal={setModalActiveArchive}
            />
        ),

        modalActive.show && (
            <PopupDeleteAd
                key={3}
                modal={modalActive}
                setModal={setModalActive}
                dispatch={dispatch}
                userId={userId}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                changeContinuePopup={changeContinuePopup}
                isTotalPages={totalPages}
                totalCount={totalCount}
                adsData={userAdsData}
            />
        ),
        sortByMobMenu.show
            ? <MobMenu
                key={4}
                togglePopupArchive={() => togglePopupArchive(sortByMobMenu.data)}
                togglePopup={() => togglePopup(sortByMobMenu.data)}
                modal={sortByMobMenu}
                history={history}
                setModal={setSortByMobMenu}/>
            : null,
        <ChangedConfirmedPopup successText={successText}
                               key={5}
                               changingConfirmed={changingConfirmed}/>,

        modalActivePurchase &&
        <PopupPurchase key={6} active={modalActivePurchase} setActive={setModalActivePurchase}/>,

        modalActiveError && <PopupError key={7} active={modalActiveError} setActive={setModalActiveError}/>,
    ]

};
