import "../PopupDeleteAd/popupDelete.css";
import archiveIcon from "../asssets/archive-img.svg";
import {APICardRequest} from "../../../../API/api";
import {deleteLocalCard} from "../../../../redux/reducers/ads/sliceAds";
import { useTranslation } from 'react-i18next';

const PopupArchive = (props) => {

    const {modal, setModal, dispatch} = props;
     const { t } = useTranslation();

    const archiveAd = () => {
        APICardRequest.archiveCard(modal.data.id)
            .then(() => {
                dispatch(deleteLocalCard(modal.data.id))
                // console.log(res)
                closeModal()
            })
    }

    const closeModal = () => {
        setModal({
            show: false,
            data: null,
        })
    }

    return (
        <div
            className={modal.show ? "modalDelete active" : "modalDelete"}
            onClick={closeModal}>
            <div className={"modal-content-delete"}>
                <div className={"close-and-feedback"}>
                    <div className={"leave-feedback"}>
                        <img src={archiveIcon} alt={"archive"}/>
                        <span>{t('remove-ad')}</span>
                    </div>
                    <div className={"close-button"}>
                        <svg
                            width="24"
                            className={"close_icon"}
                            onClick={closeModal}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.6416 12.0002L18.5804 7.07632C19.0069 6.61883 18.9948 5.90538 18.5522 5.46381C18.1097 5.0213 17.3972 5.00819 16.9397 5.43475L12.0001 10.3586L7.07621 5.42731C6.62059 4.96982 5.88089 4.96792 5.4234 5.42356C4.96591 5.87824 4.96402 6.61888 5.41965 7.07636L10.3584 12.0002L5.41965 16.924C4.9931 17.3815 5.00527 18.095 5.44778 18.5366C5.89029 18.9791 6.60278 18.9922 7.06029 18.5656L11.9841 13.6418L16.908 18.5656H16.9089C17.3617 19.0269 18.1033 19.0344 18.5655 18.5806C19.0267 18.1278 19.0342 17.3862 18.5805 16.9241L13.6416 12.0002Z"
                                fill="#003E47"
                                fillOpacity="0.4"
                            />
                        </svg>
                    </div>
                </div>
                <div className={"sure-delete"}>
                    <span>{t('Are you sure you want to delete this ad?')}</span>
                    <span>{t('You can activate it again')}</span>
                </div>

                <div className={"cancel-block"}>
                    <button type="cancel" className={"cancel"} onClick={closeModal}>
                        {t('cancel')}
                    </button>
                </div>
                <div className={"delete-block"}>
                    <button type="delete" className={"delete"} onClick={archiveAd}>
                        {t('delete')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PopupArchive;
