import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import {Link, useHistory, useLocation} from 'react-router-dom';
import '../pagination/pagination.css';
import {PaginationItem} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export const PaginationAd = (props) => {

    const {isTotalPages, loading, handleNewLoading, setPageNumber, pageNumber} = props;

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    // const params = new URLSearchParams(location.search);

    useEffect(() => {
        if (!loading && pageNumber && isTotalPages) {
            if (isTotalPages < pageNumber) {
                history.push('/404')
            }
        }
    }, [pageNumber, isTotalPages, history, loading]);

    return (
        <div className={classes.root}>
            {!loading && (
                <Pagination count={+isTotalPages} page={pageNumber}
                            onChange={(e, value) => {
                                setPageNumber(value)
                                if (handleNewLoading) handleNewLoading()
                            }}
                            renderItem={(item) => (
                                <PaginationItem
                                    component={Link} // Use Link as the component for anchor tags
                                    to={`${location.pathname}${location.search.replace(/page=\d*/, `page=${item.page}`) }`} // Replace with your actual route
                                    {...item}
                                />
                            )}
                />
            )}
        </div>
    );
};
