import {configureStore} from '@reduxjs/toolkit';
import adsSlice from "../reducers/ads/sliceAds";
import userAuth from "../reducers/userAuth/sliceUserAuth";
import chatSlice from "../reducers/chat/sliceChat";
import {APICardRequest} from '../../API/api';

export const store = configureStore({
    reducer: {
        adsSlice,
        userAuth,
        chatSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: APICardRequest,
            },
            serializableCheck: false,
        }),
})
