import React, {useState, useEffect} from "react";
import {APICardRequest, APIPayment, APIUserRequest} from "../../../../API/api";
import {CheckoutForm} from "../../../../Components/checkOutForm/PaymentRequestButton"
import PayByCreditCard from "../../../../Components/payByCard/PayByCreditCard"
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";

import {
    // doneSettingsAlert,
    errorSweetAlert,
} from '../../../../sections/sweetAlert/sweetAlert';
// import GooglePayButton from "@google-pay/button-react";
import '../PopupRaise/popupRaise.css'
import raise from '../asssets/raise-img.svg'
import wallet from '../asssets/wallet.svg'
import PayBySMS from "../../../../Components/payBySMS/PayBySMS";
// import googlePay from '../asssets/googlePay.svg'
// import creditCard from '../asssets/credit-card.svg'
// import PopupPurchase from "../PopupPurchase/PopupPurchase";
// import PopupError from "../PopupError/PopupError";
// import apple from '../asssets/Pay.svg'


const PopupRaise = (props) => {
    const {modal, setModal, token, setModalActivePurchase, setModalActiveError} = props
    const [balance, setBalance] = useState(0)
    const [topPrice, setTopPrice] = useState("")
    const [paymentDescription, setPaymentDescription] = useState("")
    const {t} = useTranslation();
    const history = useHistory();
    const to_pricing = () => {
        let path = `/pricing`;
        history.push(path);
    }
    // const [modalActivePurchase, setModalActivePurchase] = useState(false)
    // const [modalActiveError, setModalActiveError] = useState(false)

    const closeModal = () => {
        setModal({
            show: false,
            data: null,
        })
    }

    useEffect(() => {
        if (token && modal.data.id) {
            APIPayment.topAdGetClientSecret(token, modal.data.id).then((r) => {
                setTopPrice(r.data.amount)
                setPaymentDescription(r.data.description)
            })
        }
    }, [token, modal.data.id])

    useEffect(() => {
        APIUserRequest.getUserInfo().then((r) => {
            setBalance(r.data.balance)
        })

    }, [])

    const makePaymentFromBalance = (e) => {
        e.preventDefault();
        APICardRequest.adToTop(modal.data.id).then((r) => {
            // console.log(r)
            if (r.data.message === "Insufficient balance") {
                closeModal()
                return errorSweetAlert(t('insufficient-balance'))
            }

            if (r.data.success) {
                APIUserRequest.getUserInfo().then((r) => {
                    setBalance(r.data.balance)
                    closeModal()
                    return setModalActivePurchase(true)
                })
                // return doneSettingsAlert("Payment Success")
            }
        }).catch(() => {
            // return errorSweetAlert("Something went wrong")
            return setModalActiveError(true)
        })
    }
    return (
        <div style={{background: modal.show ? "rgba(0, 0, 0, 0.5)" : "transparent"}}
             className={modal.show ? "modalRaise active" : "modalRaise"} onClick={closeModal}>

            <div className={'modal-content-raise'}
                 onClick={e => e.stopPropagation()}>

                <div className={'raiseAd-to-top'}>
                    <div className={'close-and-feedback'}>
                        <div className={'leave-feedback'}>
                            <img src={raise} alt={'raise'}/>
                            <span>{t('raise-ad-payment-modal')}</span>
                        </div>
                        <div className={'close-button'}>
                            <svg width="24" className={'close_icon'}
                                 onClick={closeModal}
                                 height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.6416 12.0002L18.5804 7.07632C19.0069 6.61883 18.9948 5.90538 18.5522 5.46381C18.1097 5.0213 17.3972 5.00819 16.9397 5.43475L12.0001 10.3586L7.07621 5.42731C6.62059 4.96982 5.88089 4.96792 5.4234 5.42356C4.96591 5.87824 4.96402 6.61888 5.41965 7.07636L10.3584 12.0002L5.41965 16.924C4.9931 17.3815 5.00527 18.095 5.44778 18.5366C5.89029 18.9791 6.60278 18.9922 7.06029 18.5656L11.9841 13.6418L16.908 18.5656H16.9089C17.3617 19.0269 18.1033 19.0344 18.5655 18.5806C19.0267 18.1278 19.0342 17.3862 18.5805 16.9241L13.6416 12.0002Z"
                                    fill="#003E47" fillOpacity="0.4"/>
                            </svg>
                        </div>
                    </div>
                </div>

                <div className={'paid-service'}>
                    <div className={'paid'}>
                        {/*<span className={'paid-service-word'}>Paid service</span>*/}
                        {/*<span className={'top-fee'}>Top fee</span>*/}
                        <span className={'paid-service-word'}>{t('paid-service')}</span>
                        <span className={'top-fee'}>{t(paymentDescription)}</span>
                    </div>
                    <div className={'fee'}>
                            <span>
                                {topPrice / 100}&#8364;
                            </span>
                    </div>
                </div>
                <div className={'pay-and-balance'}>
                    <div className={'payment-options'}>
                        <PayBySMS id={modal.data.id}
                                  closeModal={closeModal}
                                  setModalActivePurchase={setModalActivePurchase}
                                  setModalActiveError={setModalActiveError}
                        />
                        <PayByCreditCard id={modal.data.id} token={token}
                                         apiPaymentMethod={APIPayment.topAdGetClientSecret}
                                         closeModal={closeModal}
                                         setModalActivePurchase={setModalActivePurchase}
                                         setModalActiveError={setModalActiveError}

                        />
                        <CheckoutForm id={modal.data.id}
                                      description={paymentDescription && paymentDescription}
                                      price={topPrice && topPrice / 100}
                                      apiPaymentMethod={APIPayment.topAdGetClientSecret}
                                      token={token}
                                      closeModal={closeModal}
                                      setModalActiveError={setModalActiveError}
                                      setModalActivePurchase={setModalActivePurchase}
                        />
                        {/*{modalActiveError && <PopupError active={modalActiveError} setActive={setModalActiveError}/>}*/}
                    </div>
                </div>

                <span className={'or'}>-{t('or')}-</span>
                {/*<div style={{border: '1px solid #E2E8F0'}}/>*/}
                <div className={'raiseAd-to-top'}>
                    {/*<div className={'close-and-feedback'}>*/}
                    {/*    <div className={'leave-feedback'}>*/}
                    {/*        <img src={raise} alt={'raise'}/>*/}
                    {/*        <span>{t('raise-ad-payment-modal')}</span>*/}
                    {/*    </div>*/}
                    {/*    <div className={'close-button'}>*/}
                    {/*        <svg width="24" className={'close_icon'}*/}
                    {/*             onClick={closeModal}*/}
                    {/*             height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*            <path*/}
                    {/*                d="M13.6416 12.0002L18.5804 7.07632C19.0069 6.61883 18.9948 5.90538 18.5522 5.46381C18.1097 5.0213 17.3972 5.00819 16.9397 5.43475L12.0001 10.3586L7.07621 5.42731C6.62059 4.96982 5.88089 4.96792 5.4234 5.42356C4.96591 5.87824 4.96402 6.61888 5.41965 7.07636L10.3584 12.0002L5.41965 16.924C4.9931 17.3815 5.00527 18.095 5.44778 18.5366C5.89029 18.9791 6.60278 18.9922 7.06029 18.5656L11.9841 13.6418L16.908 18.5656H16.9089C17.3617 19.0269 18.1033 19.0344 18.5655 18.5806C19.0267 18.1278 19.0342 17.3862 18.5805 16.9241L13.6416 12.0002Z"*/}
                    {/*                fill="#003E47" fillOpacity="0.4"/>*/}
                    {/*        </svg>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={'pay-and-balance'}>
                        <div className={'balance-and-tops'}>
                            <div className={'balance'}>
                                <img src={wallet} alt={'wallet'}/>
                                <span>{t('your-balance')}:</span>
                            </div>
                            <span className={'tops'}>{balance} {balance > 1 ? t('tops') : t('top')}</span>
                        </div>
                        <div style={{textAlign: 'center'}}>
                            {balance === 0 ?
                                <button className={'pay'} onClick={() => to_pricing()}>{t('prepay')}</button> :
                                <button className={'pay'} onClick={makePaymentFromBalance}>{t('pay-from-balance')}
                                </button>}
                        </div>
                    </div>

                </div>


            </div>
        </div>
    );
};

export default PopupRaise;