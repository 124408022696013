import {
    Card,
    CardContent,
    makeStyles,
    Typography,
    Button,
} from '@material-ui/core';
import {Form, Formik, Field} from 'formik';
import * as Yup from "yup";
import {StyledInputErr} from "../authStyle";
import React from "react";
import {AuthOperations} from "../../../API/api";
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    resetEmailTitle: {
        color: '#475569',
        fontSize: '0.9rem',
        display: 'inline-block',
        marginBottom: '0.3rem',

    },

    emailInput: {
        width: '100%',
        background: 'rgba(255, 255, 255, 0.1)',
        border: '1px solid #94A3B8',
        borderRadius: '8px',
        padding: '10px 1rem',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000',
        transition: '.2s',
        '&::placeholder': {
            color: '#CBD5E1',
        },
        '&:focus': {
            border: '1px solid #2F49D1'
        },
    },

    textField: {
        [`& fieldset`]: {
            borderRadius: '0.5rem',
        },
        marginBottom: theme.spacing(3),
    },

    resetFormWrapper: {
        width: '100%',
    },

    resetFormInfo: {
        width: '100%',
        maxWidth: '568px',
        margin: '0 auto',
        padding: '0rem 1rem',
        [theme.breakpoints.down('xs')]: {
            width: "343px",
            height: "324px",
        },
    },

    resetPas: {
        textAlign: 'center',
        color: '#2F49D1',
        textTransform: 'capitalize',
        fontSize: '1.1rem',
        fontWeight: '600',
        marginBottom: theme.spacing(1),
    },

    resetEmail: {
        textAlign: 'center',
        color: '#475569',
        fontSize: '1rem',
        fontWeight: '500',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(7),
        },
    },

    restBtn: {
        color: '#ffffff',
        borderRadius: '0.5rem',
        boxShadow: 'none',
        backgroundColor: '#2F49D1!important',
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: '600',
        padding: '0.6rem 0',
        textAlign: 'center',
        display: 'block',
        width: '100%',
        marginBottom: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'none',
            boxShadow: 'none',
            opacity: "0.8"
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(4),
        },
    },

    input: {
        '&::placeholder': {
            color: '#CBD5E1',
            fontWeight: '500',
            fontSize: '1rem',
        },
    },
}));


const initState = {
    email: '',
};



const EnterEmail = ({changeContinuePopup}) => {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();

    const SignupSchema = Yup.object().shape({
        email: Yup.string(t('enter-your-email'))
            .email(t('enter-a-valid-email'))
            .min(2, t('too-short'))
            .max(50, t('too-long'))
            .required(t('required')),
    });

    const formikSubmit = (values, actions) => {
        AuthOperations.sendRecoverEmail(values)
            .then(() => {
                // console.log(res)
                changeContinuePopup(t('recovery-link-sent-to-your-email'))
                actions.resetForm({
                    values: initState
                })
                setTimeout(() => {
                    changeContinuePopup(t('recovery-link-sent-to-your-email'))
                    history.push('/auth?type=login')
                }, 3000)
            })
            .catch(err => {
                console.log(err)
                actions.setFieldError('email', err.response.data.message)
            })
    }

    return (
        <Formik
            initialValues={initState}
            validationSchema={SignupSchema}
            onSubmit={formikSubmit}>
            {({errors, touched}) => {
                return (
                    <Form className={classes.resetFormWrapper}>
                        <Card className={classes.resetFormInfo}>
                            <CardContent>
                                <Typography className={classes.resetPas} variant='body2'>
                                    {t('reset-password')}
                                </Typography>
                                <Typography className={classes.resetEmail} variant='body2'>
                                   {t('enter-your-email')}
                                </Typography>

                                <div className={classes.textField}>
                                    <span className={classes.resetEmailTitle}>Email</span>
                                    <Field name={'email'} className={classes.emailInput} placeholder={'email@mail'}/>
                                    {/*<TextField*/}
                                    {/*    fullWidth*/}
                                    {/*    name={'email'}*/}
                                    {/*    variant='outlined'*/}
                                    {/*    id='standard-basic'*/}
                                    {/*    size='small'*/}
                                    {/*    placeholder='email@mail'*/}
                                    {/*    InputProps={{*/}
                                    {/*        classes: {input: classes.input},*/}
                                    {/*    }}*/}
                                    {/*/>*/}

                                    {errors.email && touched.email ? (
                                        <StyledInputErr>{errors.email}</StyledInputErr>) : null}
                                </div>
                                <Button
                                    variant='contained'
                                    type={'submit'}
                                    className={classes.restBtn}
                                >
                                    {t('continue')}
                                </Button>
                            </CardContent>
                        </Card>
                    </Form>
                )
            }}
        </Formik>
    );
};

export default EnterEmail;
