import React from 'react';
import {useRef} from 'react';
import {Formik} from "formik";
import {StyledForm, StyledInputErr, StyledRegistrationIcon, StyledRegistrationIconWrapper} from "../authStyle";
import {CustomInputField} from "../../../sections/inputAuthField/CustomInputField";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import Eye from "./assets/Eye";
import EyeOff from "./assets/EyeOff";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import OAuth from "./OAuth";



const Registration = (props) => {

    const {handleCreateUser, handleChangePassword, password, error, loading} = props;

    const inputRef = useRef([]);

    const GDPRcheckboxValueChanged = () => {
    if (inputRef.current[1] == null)
    {
        return;
    }
    console.log(inputRef.current[2].current);
    inputRef.current[2].disabled = !inputRef.current[1].checked;
    };

    const buttonIsDisabled = () => {
    if (inputRef.current[1] == null)
    {
        return true;
    }

    return !inputRef.current[1].checked;
  };

    const { t } = useTranslation();
    const SignupRegSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, t('too-short'))
        .max(50, t('too-long'))
        .required(t('required')),

    password: Yup.string()
        .min(2, t('too-short'))
        .max(50, t('too-long'))
        .required(t('required')),

    email: Yup.string()
        .min(2, t('too-short'))
        .max(50, t('too-long'))
        .required(t('required')),
});

    return (
        <div style={{width: "100%"}} className={"big-form-registration"}>
            <OAuth/>
            <Formik
                initialValues={{
                    name: "", password: "", email: ""
                }}
                validationSchema={SignupRegSchema}
                onSubmit={handleCreateUser}>
                {({errors, touched}) => (
                    <StyledForm>
                        <div>
                            <span className={"email"}>{t('username')}</span>
                            <CustomInputField
                                icon={AccountCircleIcon}
                                name="name"
                                type="text"
                                placeholder={t('username')}
                            />
                            {errors.name && touched.name ? (
                                <StyledInputErr>{errors.name}</StyledInputErr>) : null}
                        </div>
                        <div>
                            <span className={"email"}>Email</span>
                            <CustomInputField
                                icon={EmailIcon}
                                name="email"
                                placeholder={"email@mail"}
                                type={"email"}
                            />

                            {errors.email && touched.email ? (
                                <StyledInputErr>{errors.email}</StyledInputErr>) : null}
                        </div>
                        <div className={"password-icon"}>
                            <span className={"email"}>{t('password')}</span>

                            <StyledRegistrationIconWrapper>
                                <CustomInputField
                                    name="password"
                                    placeholder={t('password')}
                                    autoComplete={"on"}
                                    type={password ? "text" : "password"}
                                />
                                <StyledRegistrationIcon onClick={handleChangePassword}>
                                    {!password ? <Eye/> : <EyeOff/>}
                                </StyledRegistrationIcon>
                            </StyledRegistrationIconWrapper>

                            {errors.password && touched.password ? (
                                <StyledInputErr>{errors.password}</StyledInputErr>) : null}
                            {/*<StyledInputErr>{err.length ? err.text : null}</StyledInputErr>*/}
                            {error.text &&
                                <StyledInputErr>{error.text}</StyledInputErr>
                            }

                        </div>
                         <div>
                             <input
                                    ref={el => inputRef.current[1] = el}
                                    name='GDPRCheckbox'
                                    type='checkbox'
                                    defaultChecked={false}
                                    onChange={GDPRcheckboxValueChanged}
                                />

                                <span className={"email"} > {t('I agree to the')} </span>
                                <span className={"email"}
                                    onClick={() => {
                                        window.open('/privacy-policy', '_blank');
                                    }}
                                    style={{color: '#2F49D1', cursor: 'pointer'}}
                                >
                                    {t('privacy-policy')}
                                </span>
                        </div>

                        <button ref={el => inputRef.current[2] = el} type={"submit"} disabled={loading || buttonIsDisabled()} className={"log-in-button"}>{t('create-account')}</button>
                    </StyledForm>)}
            </Formik>
        </div>
    );
};

export default Registration;