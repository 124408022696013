import React, {useEffect, useState} from "react";
import './payment.css';
import {APIUserRequest} from "../../../../../API/api";
import emptyList from "../../assets/payment_list_empty.svg";
import {CircularProgress} from "@material-ui/core";
import useResize from "../../../../../Constants/useResize";
import caret from './assets/caret-down.svg'
import {PaginationAd} from "../../../../../sections/pagination/PaginationAd";
import useActivePage from "../../../../../Constants/useActivePage";
import moment from 'moment';
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";
import {getUserId} from "../../../../../redux/selectors/selectors";

const Payment = () => {
    const [paymentData, setPaymentData] = useState([]);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const {innerWidth} = useResize();
    const [pagination, setPagination] = useState({
        total_pages: null,
        totalCount: null,
    });
    const {pageNumber, setPageNumber} = useActivePage();
    const currentUser = useSelector(getUserId);

    useEffect(() => {
        APIUserRequest.getUserPayments(pageNumber)
            .then(res => {
                // console.log(res)
                setPaymentData(res.data.map(x => {
                    return {
                        ...x,
                        open: false,
                    }
                }))
                setLoading(false)
                setPagination({
                    total_pages: JSON.parse(res.headers['x-pagination']).total_pages,
                    totalCount: JSON.parse(res.headers['x-pagination']).total,
                })
            })
            .catch(alert);
    }, [pageNumber])

    const HelperReceiptUrl = (user_id, payment_id) => {
        return `https://d2j7v9zcmivqbu.cloudfront.net/${user_id}/${payment_id}.pdf`
    };

    return (
        <div>
            <div className={`wrapper_payment overflow_scrollbar ${!paymentData.length ? 'wrapper_payment_empty' : ''}`}>
                {innerWidth >= 700 ? (
                        <div className={'content'}>
                            <div className={'title-payment'}
                                 style={{gridTemplateColumns: '162px 210px minmax(340px, 1fr) 100px 24px'}}>
                                <span>{t('date')}</span>
                                <span>{t('operation-number')}</span>
                                <span>{t('title')}</span>
                                <span>{t('amount')}</span>
                                <div/>
                            </div>
                            <div className={'body'}>
                                {!loading
                                    ? paymentData.length
                                        ? paymentData.map((item, index) => (
                                            <div className={`inf-payment ${index % 2 === 1 ? 'inf-payment-bg' : ''}`}
                                                 key={index}
                                                 style={{
                                                     backgroundColor: item.style,
                                                     gridTemplateColumns: '162px 210px minmax(340px, 1fr) 100px 24px'
                                                     // gridTemplateColumns: '162px 210px minmax(340px, 1fr) 100px'
                                                 }}>
                                            <span className={'date'}>
                                                {moment.utc(item.created).local().format('MMM. DD, YYYY - HH:mm')}
                                            </span>
                                                <span className={'number'}>{item.id}</span>
                                                <div className={'paid-and-title'}>
                                                <span
                                                    className={'paid'}>{item.type === 'top' ? t('paid-service') + ` “Top”` : t('set-of-paid-services')}</span>
                                                    {item.ad_id
                                                        ? <Link to={`/ads/detail-ad/${item.ad_id}`}><span
                                                            className={'title'}>{item.ad_id}</span></Link>
                                                        : <span style={{color: '#94A3B8'}} className={'title'}>{t('balance-replenishment')}</span>}
                                                </div>
                                                <div className={'amount-number'}>
                                                    <span className={'amount'}>{item.amount} &#8364;</span>
                                                </div>
                                                <div onClick={() => {
                                                    window.open(HelperReceiptUrl(currentUser, item.id), '_blank')
                                                }} className={'file-download'}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
                                                            fill="#94A3B8"/>
                                                        <path
                                                            d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
                                                            stroke="#94A3B8" strokeWidth="2" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                        <path
                                                            d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
                                                            stroke="#94A3B8" strokeWidth="2" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                        <path d="M12 11V17" stroke="#94A3B8" strokeWidth="2"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                        <path d="M9 14L12 17L15 14" stroke="#94A3B8" strokeWidth="2"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        ))
                                        : <div className={'empty_list_wrap'}>
                                            <img src={emptyList} alt="empty list"/>
                                            <h5>{t('Payment list is empty')}</h5>
                                            <p>{t('As soon as you make a purchase in the ad tops, all payments will be here')}</p>
                                        </div>
                                    : <CircularProgress
                                        style={{position: "absolute", top: "40%", left: "50%"}}
                                        size={50}
                                    />}
                            </div>
                        </div>
                    )
                    : (
                        <div className={'content'}>
                            <div className={'title-payment'}
                                 style={{gridTemplateColumns: '1fr 110px'}}>
                                <span>{t('title')}</span>
                                <span>{t('amount')}</span>
                            </div>
                            <div className={'body'}>
                                {!loading
                                    ? paymentData.length
                                        ? paymentData.map((item, index) => (
                                            <div key={index}>
                                                <div className={'inf-payment'}
                                                     style={{
                                                         backgroundColor: item.style,
                                                         gridTemplateColumns: '1fr 50px 24px'
                                                     }}>
                                                    <div className={'paid-and-title'}>
                                                    <span
                                                        className={'paid'}>{item.type === 'top' ? t('paid-service') + ` “Top”` : t('set-of-paid-services')}</span>
                                                        {item.ad_id
                                                            ? <Link to={`/ads/detail-ad/${item.ad_id}`}><span
                                                                className={'title'}>{item.ad_id}</span></Link>
                                                            : <span style={{color: '#94A3B8'}} className={'title'}>{t('balance-replenishment')}</span>}
                                                    </div>
                                                    <div className={'amount-number'}>
                                                        <span className={'amount'}>{item.amount} &#8364;</span>
                                                    </div>
                                                    <img src={caret} alt={'more'}
                                                         onClick={() => {
                                                             setPaymentData(prevState => ([
                                                                 ...prevState.slice(0, index),
                                                                 {...item, open: !item.open},
                                                                 ...prevState.slice(index + 1),
                                                             ]))
                                                         }}/>
                                                </div>
                                                {item.open && (
                                                    <div className={'date-number'}>
                                                        <div className={'date-mob'}>
                                                            <span className={'title-date'}>{t('date')}</span>
                                                            <span className={'date'}>
                                                {moment.utc(item.created).local().format('MMM. DD, YYYY - HH:mm')}</span>
                                                        </div>
                                                        <div className={'date-mob'}>
                                                            <span className={'title-date'}>{t('number')}</span>
                                                            <span className={'number'}>{item.id}</span>
                                                        </div>
                                                        <div onClick={() => {
                                                            window.open(HelperReceiptUrl(currentUser, item.id), '_blank')
                                                        }} className={'file-download'}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
                                                                    fill="#94A3B8"/>
                                                                <path
                                                                    d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
                                                                    stroke="#94A3B8" strokeWidth="2" strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                                <path
                                                                    d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
                                                                    stroke="#94A3B8" strokeWidth="2" strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                                <path d="M12 11V17" stroke="#94A3B8" strokeWidth="2"
                                                                      strokeLinecap="round"
                                                                      strokeLinejoin="round"/>
                                                                <path d="M9 14L12 17L15 14" stroke="#94A3B8" strokeWidth="2"
                                                                      strokeLinecap="round"
                                                                      strokeLinejoin="round"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                        : <div className={'empty_list_wrap'}>
                                            <img src={emptyList} alt="empty list"/>
                                            <h5>{t('Payment list is empty')}</h5>
                                            <p>{t('As soon as you make a purchase in the ad tops, all payments will be here')}</p>
                                        </div>
                                    : <CircularProgress
                                        style={{position: "absolute", top: "40%", left: "50%"}}
                                        size={50}
                                    />}
                            </div>
                        </div>
                    )}
            </div>

            {pagination.totalCount > 10 ?
                <PaginationAd loading={loading} isTotalPages={pagination.total_pages}
                              pageNumber={pageNumber}
                              setPageNumber={setPageNumber}/> : null}
        </div>
    );
};

export default Payment;