import React, {useCallback, useEffect, useState} from 'react';
import './styles/profile.css';
import {Link, Route, useLocation, useParams} from 'react-router-dom';
import {Navigation} from '../../routers/navigation';
import {Rating} from '../../sections/rating/Rating';
import {useDispatch, useSelector} from 'react-redux';
import {
    getTotalCount, getUserAvatar,
    getUserId,
    getUserName,
} from '../../redux/selectors/selectors';
import PopupLogout from '../../Components/listMenu/PopupLogout/PopupLogout';
import {APIUserConversations} from '../../API/api';
import {getUserAds} from '../../redux/reducers/ads/sliceAds';
import useActivePage from "../../Constants/useActivePage";
import Seo from "../../seo";
import Header from "../header";
// import Footer from "../footer/Footer";
import { useTranslation } from 'react-i18next';


const ProfileNavigation = () => {
    const { t } = useTranslation();
    const {slug} = useParams();
    const location = useLocation();
    const userName = useSelector(getUserName);
    const userAvatar = useSelector(getUserAvatar);
    const dispatch = useDispatch();
    const userId = useSelector(getUserId);
    const totalCount = useSelector(getTotalCount);
    const [activePage, setActivePage] = useState(slug);
    const [modalPopupLog, setModalPopupLog] = useState(false);
    const [userPrivateInfo, setUserPrivateInfo] = useState({});
    const [activeChat, setActiveChat] = useState(!!location.pathname.split('/')[3] && slug === 'messages');
    const {pageNumber} = useActivePage();

    const togglePopup = () => setModalPopupLog(!modalPopupLog);

    useEffect(() => {
        setActiveChat(!!location.pathname.split('/')[3] && slug === 'messages')
        return () => setActiveChat(false)
    }, [location.pathname, slug])

    useEffect(() => {
        setActivePage(slug);
        return () => setActivePage('')
    }, [slug]);

    useEffect(() => {
        if (userId) {
            APIUserConversations.getUserPublicInfo(userId)
                .then(res => {
                    setUserPrivateInfo(res.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [userId]);

    useEffect(() => {
        if (userId) {
            if (slug !== 'my-ads') {
                dispatch(getUserAds({id: userId, page: 1}))
            }
        }
    }, [userId, dispatch, pageNumber, slug])

    const OldSchoolMenuLink = ({link, label, pathname, path, icon, pagination}) => {
        if (label) {
            return (
                <div
                    key={pathname}
                    className={`profile_link ${
                        activePage === pathname || activePage.includes(pathname)
                            ? 'profile_link_active'
                            : ''
                    }`}>
                    <Link
                        to={link ?? `${path}${pagination ? `?page=1` : ''}${pathname === 'messages' ? '&filter=recent' : ''}`}
                        onClick={() => setActivePage(pathname)}>
                        {icon}
                        <span>{t(label)}</span>
                    </Link>
                </div>
            );
        } else return null;
    };

    const SwitcherNavBar = (props) => {
        const {routes} = props;
        return routes.map((route, index) => (
            <Route
                path={route.path}
                exact={true}
                component={route.main}
                key={index}
            />
        ));
    };

    const renderSwitchRoute = useCallback(() => {
        return <SwitcherNavBar routes={Navigation.profileNavigation}/>;
    }, []);

    return (
        <div className={'wrapper_app'}>

            <Header/>

            <div className={'profile_wrapper'}>

                <Seo>
                    <meta name="robots" content="noindex, nofollow"/>
                </Seo>

                <div className={'profile_info'}>
                    <div className={'user_info'}>
                        <div className={'profile_initials'}>
                            {userAvatar ? <img src={userAvatar} alt={'user'} className={'profile_photo'}/>
                                : <span>{userName.split('')[0]}</span>}
                        </div>
                        <span className={'title'}>{userPrivateInfo.name}</span>
                        <p className={'ads_count'}>{totalCount} {t('ads')} </p>
                        {!!userPrivateInfo.rating ? (
                            <Link to={'/profile/ratings'} className={'rating_star'}>
                                <Rating size={20} value={userPrivateInfo.rating}/>
                            </Link>
                        ) : (
                            <div className={'rating_star'}>
                                <Rating size={20} value={userPrivateInfo.rating}/>
                            </div>
                        )}
                        {!!userPrivateInfo.rating ? (
                            <span className={'rating_count'}>
              {userPrivateInfo.rating?.toFixed(1)} / 5.0
            </span>
                        ) : (
                            <span style={{color: '#94A3B8'}}>{t('not-rated-yet')}</span>
                        )}
                    </div>
                    <div className={'profile_navigation'}>
                        {Navigation.profileNavigation.map((route, key) => (
                            <OldSchoolMenuLink {...route} key={key}/>
                        ))}
                    </div>
                    <div className={'log_out'}>
                        <button onClick={togglePopup}>
                            <svg
                                width='25'
                                height='24'
                                viewBox='0 0 25 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M14.5 8V6C14.5 5.46957 14.2893 4.96086 13.9142 4.58579C13.5391 4.21071 13.0304 4 12.5 4H5.5C4.96957 4 4.46086 4.21071 4.08579 4.58579C3.71071 4.96086 3.5 5.46957 3.5 6V18C3.5 18.5304 3.71071 19.0391 4.08579 19.4142C4.46086 19.7893 4.96957 20 5.5 20H12.5C13.0304 20 13.5391 19.7893 13.9142 19.4142C14.2893 19.0391 14.5 18.5304 14.5 18V16'
                                    stroke='#94A3B8'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M7.5 12H21.5M21.5 12L18.5 9M21.5 12L18.5 15'
                                    stroke='#94A3B8'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                            <span className={'word-log-out'}>{t('logout')}</span>
                        </button>
                    </div>
                </div>

                <div className={'profile_page_content'}>{renderSwitchRoute()}</div>
                {modalPopupLog ? (
                    <PopupLogout active={modalPopupLog} setActive={setModalPopupLog}/>
                ) : null}
            </div>

            {/*{!activeChat && <Footer/>}*/}

        </div>
    );
};

export default ProfileNavigation;
