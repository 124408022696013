import React, {useState} from "react";
import {Formik, Form} from "formik";
import {
    Card,
    CardContent,
    makeStyles,
    Typography,
    TextField,
    Button,
} from "@material-ui/core";
import Eye from "./icons/Eye";
import EyeOff from "./icons/EyeOff";
import * as Yup from "yup";
import {AuthOperations} from "../../../API/api";
import {useHistory} from "react-router-dom";
import {StyledInputErr} from "../authStyle";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    resetEmailTitle: {
        color: "#475569",
        fontSize: "0.9rem",
        display: "inline-block",
        marginBottom: "0.3rem",
    },

    textField: {
        [`& fieldset`]: {
            borderRadius: "0.5rem",
        },
        margin: '0 0 1.25rem',
        position: "relative",
    },

    resetFormInfo: {
        width: "100%",
        maxWidth: '568px',
        padding: "0rem 1rem",
        [theme.breakpoints.down("xs")]: {
            width: "343px",
            height: "370px"
        },
    },

    resetPas: {
        textAlign: "center",
        textTransform: "capitalize",
        margin: '0 0 1rem',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '27px',
        color: '#2F49D1',
    },

    resetEmail: {
        textAlign: "center",
        color: "#475569",
        fontSize: "1rem",
        fontWeight: "500",
        marginBottom: '2.5rem',
        fontStyle: 'normal',
        lineHeight: '24px',
    },

    restBtn: {
        color: "#ffffff",
        borderRadius: "0.5rem",
        boxShadow: "none",
        backgroundColor: "#2F49D1!important",
        textTransform: "none",
        fontSize: "1rem",
        fontWeight: "600",
        padding: "0.6rem 0",
        textAlign: "center",
        display: "block",
        width: "100%",
        marginBottom: theme.spacing(1),
        "&:hover": {
            backgroundColor: "none",
            boxShadow: "none",
            opacity: "0.8"

        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(4)
        },
    },

    input: {
        "&::placeholder": {
            color: "#CBD5E1",
            fontWeight: "500",
            fontSize: "1rem",
        },
    },

    passwordIconWrapper: {
        position: 'relative',
    },

    eyeOn: {
        position: "absolute",
        right: ".75rem",
        top: "50%",
        transform: 'translateY(-50%)',
        display: 'grid',
        alignItems: 'center',
    },

    resetPasswordValidation: {
        display: "block",
        color: "#F26464",
        fontSize: "0.8rem",
        fontWeight: "500",
        marginTop: "-0.2rem",
        marginBottom: "0.4rem"
    },
}));



const initialValues = {
    password: "",
    confirmPassword: "",
    token: '',
};

const EnterNewPassword = (props) => {
    const {t} = useTranslation();

    const SignupSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, t('password-should-contain-8-symbols'))
            .required(t('required')),
        confirmPassword: Yup.string().when("password", {
            is: (val) => (val && val.length),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                t('both-passwords-needs-to-match')
            ),
        })
            .required(t('required'))
    });

    const {token, changeContinuePopup} = props;

    const history = useHistory();
    const [showPass, setShowPass] = useState({
        password: false,
        confirmPassword: false,
    })
    const classes = useStyles();

    const formikSubmit = (values, actions) => {
        AuthOperations.setRecoverPassword({token, password: values.password})
            .then(() => {
                // console.log(res)
                changeContinuePopup(t('password-successfully updated'))
                actions.resetForm({
                    values: initialValues
                })
                setTimeout(() => {
                    changeContinuePopup(t('password-successfully updated'))
                    history.push('/auth?type=login')
                }, 3000)
            })
            .catch(err => {
                console.log(err)
                actions.setFieldError('token', err.response.data.message === 'Invalid token' ? t('reset-password-invalid-token-msg') : 'err.response.data.message')
            })
    }

    const handleChangePassword = (name) => {
        setShowPass(prevState => ({
            ...prevState,
            [name]: !prevState[name],
        }))
    };

    return (
        <Card className={classes.resetFormInfo}>
            <CardContent>
                <Typography className={classes.resetPas} variant="body2">
                    {t('reset-password')}
                </Typography>
                <Typography className={classes.resetEmail} variant="body2">
                    {t('enter-new-password')}
                </Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={SignupSchema}
                    onSubmit={formikSubmit}
                >
                    {({errors, touched, handleChange, handleBlur}) => {
                        return (
                            <Form>
                                <div className={classes.textField}>
                                    <span className={classes.resetEmailTitle}>{t('password')}</span>

                                    <div className={classes.passwordIconWrapper}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            error={errors.password && touched.password}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type={showPass.password ? "text" : "password"}
                                            placeholder={t('password')}
                                            InputProps={{
                                                classes: {input: classes.input},
                                            }}
                                            name="password"
                                        />
                                        <div
                                            onClick={() => handleChangePassword('password')}
                                            className={classes.eyeOn}>
                                            {!showPass.password ? <Eye/> : <EyeOff/>}
                                        </div>
                                    </div>
                                </div>
                                {errors.password && touched.password ? (
                                    <span className={classes.resetPasswordValidation}>
                                           {errors.password}
                                         </span>
                                ) : null}

                                <div className={classes.textField}>
                               <span className={classes.resetEmailTitle}>
                                   {t('confirm-password')}
                               </span>

                                    <div className={classes.passwordIconWrapper}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            error={errors.confirmPassword && touched.confirmPassword}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type={showPass.confirmPassword ? "text" : "password"}
                                            placeholder={t('confirm-password')}
                                            InputProps={{
                                                classes: {input: classes.input},
                                            }}
                                            name="confirmPassword"
                                        />
                                        <div
                                            onClick={() => handleChangePassword('confirmPassword')}
                                            className={classes.eyeOn}>
                                            {!showPass.confirmPassword ? <Eye/> : <EyeOff/>}
                                        </div>
                                    </div>
                                </div>
                                {errors.confirmPassword && touched.confirmPassword ? (
                                    <span className={classes.resetPasswordValidation}>
                                           {errors.confirmPassword}
                                        </span>
                                ) : null}
                                {errors.token && touched.token ? (
                                    <StyledInputErr>{errors.token}</StyledInputErr>) : null}
                                <Button variant="contained" className={classes.restBtn}
                                        onClick={() => {
                                            if (errors.token && touched.token) {
                                                history.push('/reset-password')
                                            }
                                        }}
                                        type={errors.token && touched.token ? 'button' : 'submit'}>
                                    {errors.token && touched.token ? t('go-back') : t('confirm-password')}
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            </CardContent>
        </Card>
    );
};

export default EnterNewPassword;
