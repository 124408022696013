import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {store} from './redux/store/store';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

// PAYMENT STRIPE
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {GoogleOAuthProvider} from "@react-oauth/google";
import {StyleSheetManager} from "styled-components";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

Sentry.init({
    dsn: "https://4bfbf98d3d38491db90e9c085ac7fd9e@o462132.ingest.sentry.io/4504594116706304",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
});

const queryClient = new QueryClient()

ReactDOM.render(
    <Provider store={store}>
        <Elements stripe={stripePromise}>
            <GoogleOAuthProvider clientId="75299941821-h6cg5qbaecfuche7mjg90fr5e8ogct62.apps.googleusercontent.com">
                <QueryClientProvider client={queryClient}>
                    <StyleSheetManager disableCSSOMInjection={/HeadlessChrome/.test(window.navigator.userAgent)}>
                        <App/>
                    </StyleSheetManager>
                </QueryClientProvider>
            </GoogleOAuthProvider>
        </Elements>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
