import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getFollowedAdsCount,
    getFollowedAdsSelector, getTotalFollowedPages,
    loadingSelector
} from "../../../redux/selectors/selectors";
import Card from "../../../sections/card";
import {PaginationAd} from "../../../sections/pagination/PaginationAd";
import "./followed.css";
import character from "./assets/character.svg";
import useHeart from "./assets/useHeart.svg";
import {CircularProgress} from "@material-ui/core";
import useActivePage from "../../../Constants/useActivePage";
import {deleteFromFollowed, getFollowedAds} from "../../../redux/reducers/ads/sliceAds";
import {Link} from "react-router-dom";
import ChangedConfirmedPopup from "../../../Components/SuccessPopup/ChangedConfirmedPopup";
import useBottomPopup from "../../../Constants/useBottomPopup";
import Seo from "../../../seo";
import { useTranslation } from 'react-i18next';

export const Followed = () => {
    const { t } = useTranslation();
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const {pageNumber, pageLoaded, setPageNumber} = useActivePage();
    const loading = useSelector(loadingSelector);
    const followedAdsData = useSelector(getFollowedAdsSelector);
    const dispatch = useDispatch();
    const followedAdsCount = useSelector(getFollowedAdsCount);
    const totalPages = useSelector(getTotalFollowedPages);
    const [modalActiveDeleteFollow, setModalActiveDeleteFollow] = useState({
        show: false,
        data: null,
    });

    useEffect(() => {
        if (pageLoaded) {
            dispatch(getFollowedAds({page: pageNumber, page_size: 10}))
        }
    }, [pageLoaded, pageNumber, dispatch]);

    return [
        <ChangedConfirmedPopup successText={successText}
                               key={0}
                               changingConfirmed={changingConfirmed}/>,
        <div key={1} className={"wrapper_followed_ads"}>

            <Seo>
                <meta name="robots" content="noindex, nofollow"/>
            </Seo>

            <div className={"block-followed"}>
                <div className={"title-and-sortBy"}>
                    <div className={"title-and-count"}>
                        <span className={"title"}>{t('Followed Ads')}</span>
                        <span style={{fontSize: "18px", color: "#CBD5E1"}}>( {followedAdsCount} )</span>
                    </div>
                    {/*{innerWidth > 700 ? <SortByPopular/> : <SortByMob/>}*/}
                </div>
                {!loading
                    ? followedAdsData.length
                        ? [
                            followedAdsData.map((props, key) => (
                                <Card key={props.id} {...props} index={key}
                                      modalActiveDeleteFollow={modalActiveDeleteFollow}
                                      changeContinuePopup={changeContinuePopup}
                                      isTotalPages={totalPages}
                                      pageNumber={pageNumber}
                                      setPageNumber={setPageNumber}
                                      followedAdsData={followedAdsData}
                                      followedAdsCount={followedAdsCount}
                                      setModalActiveDeleteFollow={setModalActiveDeleteFollow}
                                      deleteFromFollowed={deleteFromFollowed}/>
                            )),
                            totalPages > 1 &&
                            <PaginationAd key={1} loading={loading} isTotalPages={totalPages}
                                          pageNumber={pageNumber} setPageNumber={setPageNumber}/>,
                        ]
                        : <div className={"empty-page-followed"}>
                            <img src={character} alt={"character"}/>
                            <span className={"dont-follow"}>{t('You don\'t follow ads')}</span>
                            <span className={"use-heart"}>{t('Use the heart icon to start following ads')}</span>
                            <Link to={`/ads?page=1`}
                                  className={"use-heart-img"}>
                                <img
                                    src={useHeart}
                                    alt={"use-heart"}/>
                            </Link>
                        </div>
                    : <CircularProgress
                        style={{position: "absolute", top: "40%", left: "50%"}}
                        size={50}
                    />}
            </div>
        </div>
    ]
};
