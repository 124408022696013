// import {Button} from '@material-ui/core';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import ExtensionIcon from '@material-ui/icons/Extension';
import '../alternativeLogIn/alternativelogin.css'
import {
    StyledLink, StyledSpan,
    TextWrapper,
} from './alternativeLoginStyle';
import { useTranslation } from 'react-i18next';

export const AlternativeLogIn = () => {
    const { t } = useTranslation();

    return (
        <TextWrapper>
            <div className={'signup-or-reset'}>
                <StyledSpan>
                    <span>{t('new-to-us')} </span>
                    <StyledLink to={'/auth?type=sign-up'}>
                        {t('sign-up')}
                    </StyledLink>
                </StyledSpan>
                <div className={'forgot-reset'}>
                    <span>{t('forgot-password')}</span>
                    <StyledLink
                        to={'/reset-password'}>{t('reset-password')}</StyledLink>
                </div>
            </div>
        </TextWrapper>
    );
};