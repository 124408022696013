import React from "react";
// import { useTranslation } from 'react-i18next';
import './mobMenu.css'
import edit from './assets/edit.svg'
import trash from './assets/trash (1).svg'
import { useTranslation } from 'react-i18next';

export const MobMenu = (props) => {
    const {togglePopupArchive, modal, setModal, history} = props;
    // const {t} = useTranslation();

    const sortArr = [
        {
            text: 'Edit Ad',
            value: 'edit',
            img: edit,
            onClick: () => history.push(`/ads/edit-ad/${modal.data.id}`),
        },
        {
            text: 'Delete Ad',
            value: 'archive',
            img: trash,
            onClick: togglePopupArchive,
        },
        // {
        //     text: 'Delete Ad',
        //     value: 'delete',
        //     img: deleteImg,
        //     onClick: togglePopup,
        // },
    ];
    const { t } = useTranslation();


    return (
        <div style={{background: modal.show ? "rgba(0, 0, 0, 0.05)" : "transparent"}}
             className={modal.show ? "modalMobMenu active" : "modalMobMenu"} onClick={() => {
            setModal({
                show: false,
                data: null,
            })
        }}>
            <div className={'sort_mobile_popular'}>
                {sortArr.map((item, index) => (
                    <div onClick={item.onClick} className={'mob-menu-icon'} key={index}>
                        <img src={item.img} alt={item.value}/>
                        <span className={'sortPopularMob'}>{t(item.text)}</span>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default MobMenu;