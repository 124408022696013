import styled from 'styled-components';
import {Link} from 'react-router-dom';




export const StyledSpan = styled.span
	`
    font-size: 0.7rem;
	`;
export const StyledLink = styled(Link)
	`
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #2F49D1;
		text-decoration: none;
		cursor: pointer;
	`;

export const TextWrapper = styled.div
	`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
		text-align: center;
    width: 100%;
		
    padding-top: 20px;

    @media (max-width: 800px) {
      width: 90%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
	`;
