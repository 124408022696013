import React, {forwardRef, useEffect, useState} from "react";
import cancel from "../assets/cancel.svg";
import {useDispatch} from 'react-redux';
import {getAdsData} from '../../../redux/reducers/ads/sliceAds';
import {APICardRequest} from "../../../API/api";
import {CircularProgress} from "@material-ui/core";
import OutsideClickHandler from "../../../Constants/OutsideClickHandler";
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';


const initState = {
    opened: false,
    data: [],
    loaded: false,
    text: '',
};

export const Search = forwardRef((props, ref) => {
    const {className, landing} = props;

    const [searchData, setSearchData] = useState(() => initState);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {search} = useLocation();
    const {t} = useTranslation();

    useEffect(() => {
        if (!search) {
            setSearchData(initState)
        }
    }, [search]);

    useEffect(() => {
        let searchVal = new URLSearchParams(location.search).get('search')
        if (searchVal) {
            setSearchData(prevState => ({
                ...prevState,
                text: searchVal,
            }))
        } else {
            setSearchData(prevState => ({
                ...prevState,
                text: '',
            }))
        }
    }, [location.search])

    useEffect(() => {
        if (searchData.text.length > 1) {
            APICardRequest.getFilterAdWords(searchData.text)
                .then(res => {
                    // console.log(res)
                    setSearchData(prevState => ({
                        ...prevState,
                        data: res.data,
                        loaded: true,
                    }))
                })
        }
    }, [searchData.text, dispatch])

    const onSubmit = (e) => {
        e.preventDefault();
        const params = new URLSearchParams(window.location.search)
        const tags = params.get('tags');
        const search = params.get("search")

        if (search) {
            params.delete("search")
        }

        params.set("search", `${searchData.text}`)


        if (!searchData.text.length) {
            params.delete("search")
        }

        // if (!searchData.text.trim().length) return;

        const data = {
            text: searchData.text,
            tags: tags
        }
        dispatch(getAdsData(data));

        params.set("page", '1')
        history.push(`/ads?${params.toString()}`);
        // setSearchData(initState)
        setSearchData(prevState => ({
            ...prevState,
            opened: false,
        }))
    }

    const handleClear = () => {
        setSearchData(initState)
    }

    const handleOpen = (e) => {
        if (e.target.value.length > 1) {
            setSearchData(prevState => ({
                ...prevState,
                opened: true,
            }))
        } else {
            setSearchData(prevState => ({
                ...prevState,
                opened: false,
                loaded: false,
            }))
        }
    }

    return (
        <div className={`searchName ${className ?? ''}`}>
            <form onSubmit={onSubmit} ref={ref}>
                <input
                    autoComplete="off"
                    className={`color-text-input ${searchData.text.length ? 'color-text-input-active' : ''}`}
                    id={landing ? 'header_search_landing' : 'header_search'}
                    type="text"
                    name="text"
                    value={searchData.text}
                    placeholder={t('search-in-bazar')}
                    onChange={(e) => {
                        setSearchData(prevState => ({
                            ...prevState,
                            text: e.target.value,
                        }))
                        handleOpen(e)
                    }}
                    // onBlur={formik.handleBlur}
                    onClick={handleOpen}
                />
                {!landing && <div
                    className={`search ${searchData.text.length || searchData.opened ? 'search_active' : ''}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M21 21L15 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </div>}

                {searchData.text
                    ? <div onClick={handleClear}>
                        <img className={"cancel-text"} src={cancel} alt={"cancel"}/>
                    </div> : null}
                {searchData.opened
                    ? <OutsideClickHandler callback={() => {
                        setSearchData(prevState => ({
                            ...prevState,
                            opened: false,
                        }))
                    }}>
                        <div className={'search_result_wrap'}>
                            <div className={"search-result overflow_scrollbar"}>
                                <div className={"product-list"}>
                                    {searchData.loaded
                                        ? searchData.data.length
                                            ? searchData.data.map((product) => (
                                                <div
                                                    onClick={() => {
                                                        setSearchData(prevState => ({
                                                            ...prevState,
                                                            text: product.name,
                                                            opened: false,
                                                        }))
                                                        history.push(`/ads?page=1&search=${product.name}`)
                                                    }}
                                                    className="product-item" key={product.id}>
                                                    <span>{product.name}</span>
                                                </div>
                                            ))
                                            : <div className="product-item">
                                                <span>{t('main.no-results')}</span>
                                            </div>
                                        : <CircularProgress
                                            style={{position: "absolute", top: "40%", left: "50%"}}
                                            size={50}
                                        />}
                                </div>
                            </div>
                        </div>
                    </OutsideClickHandler> : null}
            </form>
        </div>
    );
});