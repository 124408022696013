import React from "react";
import './headerMobileClose.css'
import x from '../headerMobileClose/assets/x.svg';
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const HeaderMobileClose = (props) => {
    const {closeNotifications, clearNotifications, clearFilters} = props;
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <div className={'header_mod_close'}>
            <img onClick={() => closeNotifications()} src={x} alt={'close'}/>
            <span onClick={() => {
                if (!!clearNotifications) {
                    clearNotifications()
                } else if (clearFilters) {
                    closeNotifications()
                    history.push('/ads?page=1')
                }
            }}>{t('clear')}</span>
        </div>
    );
};

export default HeaderMobileClose;