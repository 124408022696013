import './popupPurchase.css'
import confetti from './assets/confetti.svg'
import {useTranslation} from "react-i18next";


const PopupPurchase = (props) => {
    const { t } = useTranslation();

    return (
        <div style={{background: props.active ? "rgba(0, 0, 0, 0.5)" : "transparent"}}
             className={props.active ? "modalPurchase active" : "modalPurchase"} onClick={() => props.setActive(false)}>
            <div className={'modal-content-purchase'}
                 onClick={e => e.stopPropagation()}>
                <span className={'thank-you'}>{t('purchase-popup.thank-you')}</span>
                <span className={'purchase-successful'}>{t('purchase-popup.successful')}</span>
                <img src={confetti} alt={'confetti'} className={'confetti'}/>
                <button className={'ok-button'} onClick={() => props.setActive(false)}>OK</button>
            </div>
        </div>
    );
};


export default PopupPurchase;