import React, {useState, useEffect} from "react";
import wallet from "../assets/wallet.svg";
import Payment from "./Payment/Payment";
import useActivePage from "../../../../Constants/useActivePage";
import {APIUserRequest} from '../../../../API/api';
import {CircularProgress} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Transactions from "./Transactions/Transactions";

const PayAndInv = ({setPrepayValue}) => {
    const [balance, setBalance] = useState(null);
    const [activeTab, setActiveTab] = useState('payment');
    const {setPageNumber} = useActivePage();
    const { t } = useTranslation();

    useEffect(() => {
        APIUserRequest.getUserInfo()
            .then((r) => setBalance(r.data.balance))
    }, [])

    return (
        <div className={'block-invoices-and-payment'}>
            <div className={'title-and-prepay'}>
                <span className={'title'}>{t('Payment and transactions')}</span>
                <div className={'balance-prepay'}>
                    <div className={'your-balance'}>
                        <img src={wallet} alt={'wallet'} className={'wallet'}/>
                        <span className={'balance-word'}>{t('your-balance')}:</span>
                        <span className={'tops'}>{balance === null ?
                            <CircularProgress size={20}/> : balance} {balance > 1 ? t('tops') : t('top')}</span>
                    </div>
                    <button className={'prepay-button'} onClick={() => setPrepayValue(true)}>{t('prepay')}</button>
                </div>
            </div>
            <div className={'tabs-payment-invoices'}>
                <div className={`${activeTab === 'payment' ? 'active_tab' : ''} tab`}
                     onClick={() => {
                         if (activeTab !== 'payment') {
                             setActiveTab('payment')
                             setPageNumber(1)
                         }
                     }}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.2 8.5C17.001 7.93524 16.6374 7.44297 16.1563 7.08654C15.6751 6.73011 15.0983 6.52583 14.5 6.5H10.5C9.70435 6.5 8.94129 6.81607 8.37868 7.37868C7.81607 7.94129 7.5 8.70435 7.5 9.5C7.5 10.2956 7.81607 11.0587 8.37868 11.6213C8.94129 12.1839 9.70435 12.5 10.5 12.5H14.5C15.2956 12.5 16.0587 12.8161 16.6213 13.3787C17.1839 13.9413 17.5 14.7044 17.5 15.5C17.5 16.2956 17.1839 17.0587 16.6213 17.6213C16.0587 18.1839 15.2956 18.5 14.5 18.5H10.5C9.90175 18.4742 9.32491 18.2699 8.84373 17.9135C8.36255 17.557 7.99905 17.0648 7.8 16.5"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path d="M12.5 18.5V21.5M12.5 3.5V6.5V3.5Z" stroke="currentColor" strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className={'word-pay'}>{t('payment')}</span>
                </div>


                <div className={`${
                    activeTab === 'invoices' ? 'active_tab' : ''
                } tab`}
                     onClick={() => {
                         if (activeTab !== 'invoices') {
                             setActiveTab('invoices')
                             setPageNumber(1)
                         }
                     }}>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14 3.5V7.5C14 7.76522 14.1054 8.01957 14.2929 8.20711C14.4804 8.39464 14.7348 8.5 15 8.5H19"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path
                            d="M17 21.5H7C6.46957 21.5 5.96086 21.2893 5.58579 20.9142C5.21071 20.5391 5 20.0304 5 19.5V5.5C5 4.96957 5.21071 4.46086 5.58579 4.08579C5.96086 3.71071 6.46957 3.5 7 3.5H14L19 8.5V19.5C19 20.0304 18.7893 20.5391 18.4142 20.9142C18.0391 21.2893 17.5304 21.5 17 21.5Z"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path d="M9 7.5H10" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M9 13.5H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M13 17.5H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                    <span className={'word-inv'}>{t('transactions')}</span></div>

            </div>

            {activeTab === 'payment' ? <Payment/> : <Transactions/>}
        </div>
    );
};

export default PayAndInv;