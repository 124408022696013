import React from "react";
import './notifications.css'
import HeaderMobileClose from "../../Components/headerMobileClose/HeaderMobileClose";
import {setNewNotifications, updateNotificationData} from "../../redux/reducers/chat/sliceChat";
import {useDispatch} from "react-redux";
import { useTranslation } from 'react-i18next';

const Notifications = (props) => {
    const {notificationsLayout, closeMobileNotifications} = props;

    const dispatch = useDispatch();

    const clearNotifications = () => {
        dispatch(setNewNotifications([]))
        dispatch(updateNotificationData([]))
    }

    const { t } = useTranslation();

    return (
        <div className={'notifications-page'}>
            <HeaderMobileClose closeNotifications={closeMobileNotifications} clearNotifications={clearNotifications}/>
            <div className={'wrapper_notifications'}>
                <span className={'title'}>{t('notifications')}</span>
                <div className={'new-notifications'}>
                    <p className={'new'}>{t('new')}</p>
                    {notificationsLayout(true)}
                </div>
            </div>
        </div>
    );
};

export default Notifications;