import React from "react";
import Header from "../../pages/header/Header";
import Footer from "../../pages/footer/Footer";
import './pageNotFound.css'
import pageError from './assets/404-page-placeholder.svg';
import {useHistory} from "react-router-dom";
import Seo from "../../seo";

const PageNotFound = () => {
    const history = useHistory();
    const handleMainPage = () => {
        history.push("/");
    };

    return (
        <div className={'wrapper_app'}>

            <Seo>
                <meta name="robots" content="noindex, nofollow"/>
            </Seo>

            <Header/>
            <div className={'wrapper-page-not-found'}>
                <img src={pageError} alt={'page-404'}/>
                <div className={'right-block'}>
                    <div className={'page-not-found'}>Page not found</div>
                    <span className={'oh-no'}>Oh No! Error 404</span>
                    <span className={'try-to-find'}>Try to find the page again or go to the home page</span>
                    <button className={'back-homepage'} onClick={handleMainPage}>Back to Homepage</button>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default PageNotFound;