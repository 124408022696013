import React, {useEffect, useState} from "react";
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';
import {CircularProgress} from '@material-ui/core';
// import {useTranslation} from "react-i18next";


export const CheckoutForm = (props) => {
    const {token, id, apiPaymentMethod, closeModal, setModalActiveError, setModalActivePurchase} = props;
    const stripe = useStripe()
    const [paymentRequest, setPaymentRequest] = useState(null)
    const [loading, setLoading] = useState(true)
    const [clientSecret, setClientSecret] = useState("")
    const [cardData, setCardData] = useState("")
    // const { t } = useTranslation();


    useEffect(() => {
        if (token && id) {
            apiPaymentMethod(token, id)
                .then((r) => {
                    const {intent_secret, ...rest} = r.data
                    setClientSecret(intent_secret)
                    // console.log('REST', rest);
                    setCardData(rest)
                })
        }
    }, [token, id, apiPaymentMethod])

    useEffect(() => {
        if (stripe && clientSecret && cardData) {
            // console.log('PAYMENT', cardData);
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: cardData.currency,
                total: {
                    label: cardData.description,
                    amount: +cardData.amount,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            pr.on('paymentmethod', async (ev) => {
                // Confirm the PaymentIntent without handling potential next actions (yet).
                const {paymentIntent, error: confirmError} =
                    await stripe.confirmCardPayment(
                        clientSecret,
                        {payment_method: ev.paymentMethod.id},
                        {handleActions: false}
                    );

                if (confirmError) {
                    // Report to the browser that the payment failed, prompting it to
                    // re-show the payment interface, or show an error message and close
                    // the payment interface.
                    ev.complete('fail');
                } else {
                    // Report to the browser that the confirmation was successful, prompting
                    // it to close the browser payment method collection interface.
                    ev.complete('success');
                    // Check if the PaymentIntent requires any actions and if so let Stripe.js
                    // handle the flow. If using an API version older than "2019-02-11"
                    // instead check for: `paymentIntent.status === "requires_source_action"`.
                    if (paymentIntent.status === 'requires_action') {
                        // Let Stripe.js handle the rest of the payment flow.
                        const {error} = await stripe.confirmCardPayment(clientSecret);
                        if (error) {
                            closeModal()
                            setModalActiveError(true)
                            // errorSweetAlert('Payment Error');
                        } else {
                            closeModal()
                            setModalActivePurchase(true)
                            console.log('qweqwe')
                            // doneSettingsAlert('Payment Success!');
                        }
                    } else {
                        closeModal()
                        setModalActivePurchase(true)
                        console.log('qwe')
                        // doneSettingsAlert('Payment Success!');
                    }
                }
            });
            // Check the availability of the Payment Request API.
            pr.canMakePayment().then((result) => {
                setLoading(false);
                // console.log('PR', result);
                if (result) {
                    setPaymentRequest(pr);
                }
            });
        }
    }, [stripe, clientSecret, cardData, closeModal, setModalActiveError, setModalActivePurchase]);

    const options = {
        paymentRequest,
        style: {
            paymentRequestButton: {
                type: 'buy',
                theme: 'dark',
                height: '50px',
            },
        }
    } 

    if (paymentRequest) {
        return (
            <>
                {
                    loading ? (
                        <CircularProgress size={30}/>
                    ) : (
                        <div>
                            <PaymentRequestButtonElement options={options}/>

                        </div>
                    )
                }
            </>
        )
    }
    return null;
    // (
        // <div>
        //     {
        //         loading ? (
        //             <CircularProgress size={30}/>
        //         ) : (
        //             <span className={'wallet-unavailable'}>{t('purchase-popup.no-wallet-payment')}</span>
        //         )
        //     }
        // </div>
    // )
}

// export default PaymentRequestStripeButton