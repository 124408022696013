import React from "react";
import {Redirect, Route} from "react-router-dom";
import Keys from '../Constants/helper';

const PrivateRoute = ({children, ...rest}) => {
    let getToken = localStorage.getItem(Keys.JWT_TOKEN);

    return (
        <Route
            {...rest}
            render={({location}) => (
                getToken ?
                    children :
                    <Redirect
                        to={{
                            pathname: "/",
                            state: {from: location}
                        }}
                    />
            )}
        />
    );
}

export default PrivateRoute;