import React from 'react';
import {BsStarFill} from "react-icons/bs"
import {BsStarHalf} from "react-icons/bs"
import {BsStar} from "react-icons/bs"

export const Rating = ({ size, value, color }) => {
  return (
    <div className={'rating-star'}>
      <span style={{ color: color, paddingRight:'0.3rem' }}>
        {value >= 1 ? (
          <BsStarFill size={size} />
        ) : value >= 0.5 ? (
          <BsStarHalf size={size} />
        ) : (
          <BsStar size={size} />
        )}
      </span>

      <span style={{ color: color, paddingRight:'0.3rem'}}>
        {value >= 2 ? (
          <BsStarFill size={size} />
        ) : value >= 1.5 ? (
          <BsStarHalf size={size} />
        ) : (
          <BsStar size={size} />
        )}
      </span>

      <span style={{ color: color, paddingRight:'0.3rem' }}>
        {value >= 3 ? (
          <BsStarFill size={size} />
        ) : value >= 2.5 ? (
          <BsStarHalf  size={size} />
        ) : (
          <BsStar size={size} />
        )}
      </span>

      <span style={{ color: color, paddingRight:'0.3rem' }}>
        {value >= 4 ? (
          <BsStarFill size={size} />
        ) : value >= 3.5 ? (
          <BsStarHalf size={size}/>
        ) : (
          <BsStar size={size} />
        )}
      </span>

      <span style={{ color: color, paddingRight:'0.4rem' }}>
        {value >= 5 ? (
          <BsStarFill  size={size} />
        ) : value >= 4.5 ? (
          <BsStarHalf size={size} />
        ) : (
          <BsStar size={size} />
        )}
      </span>
    </div>
  );
};

Rating.defaultProps = {
  color: '#FFB648',
};
