import {useState, useEffect} from 'react';


export default function useResize() {
    const [innerWidth, setInnerWidth] = useState(0);

    const handleResize = () => {
        const isPrerender = /HeadlessChrome/.test(window.navigator.userAgent)
        if (!isPrerender) {
            setInnerWidth(window.innerWidth);
        }
    };

    useEffect(() => {
        handleResize()
        // Add event listener on mount
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { innerWidth };
}