import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import i18next from 'i18next';

export const MySwalDelete = (history) => {
    const MySwalDelete = withReactContent(Swal);
    MySwalDelete.fire({
        position: 'center',
        icon: 'success',
        title: i18next.t('Deleted'),
        showConfirmButton: false,
        timer: 1500,
    })
    setTimeout(() => {
        history.push('/')
    }, 2000)
}

export const createDoneAlert = (history, id, adv, edit = false) => {
    const createAdDone = withReactContent(Swal);
    //const { t } = useTranslation(); //FIXME: translation here
    createAdDone.fire({
        position: 'center',
        icon: 'success',
        title: edit ? i18next.t('Ad updated!') : i18next.t('Created!'),
        showConfirmButton: false,
        timer: 1500
    })
    setTimeout(() => {
        history.push(`/ads/detail-ad/${id}/${adv}`)
    }, 2000)
};


export const errorSweetAlert = (mess = 'Error') => {
    const err = withReactContent(Swal);
    err.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${mess}`,
        confirmButtonColor: '#151515',
        // footer: '<a href="">Why do I have this issue?</a>'
    })
};

export const SMSCouponAlert = (mess = 'Error') => {
    const err = withReactContent(Swal);
    err.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${mess}`,
        timer: 2000,
        showConfirmButton: false,
    })
};

export const facebookLoginErrorSweetAlert = (title, mess, url_text) => {
    const err = withReactContent(Swal);
    err.fire({
        icon: 'error',
        title: title,
        text: mess,
        confirmButtonColor: '#151515',
        footer: `<a href="https://www.facebook.com/settings?tab=account">${url_text}</a>`
    })
};


export const doneSettingsAlert = (title, mess) => {
    const settingsChanged = withReactContent(Swal);
    settingsChanged.fire({
        position: 'center',
        icon: 'success',
        timer: 2000,
        title: title,
        text: mess,
        showConfirmButton: true,
        confirmButtonColor: '#151515',
    })

}
