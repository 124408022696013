import React from "react";
import placeholderImage from "./assets/placeholder_ad_image.svg";
import {
    CardWrapper,
    ImgWrapper,
    StyledCardStatsContent,
    StyledCardStatsContentLink,
    CardLink,
    StyledCardStatsWrapper,
    StyledDescriptionText,
    StyledImg,
    StyledPriceText,
    StyledTextWrapper,
    StyledTimeAgoText,
    SImgTopWrapper,
    StyledBlockHeart, StyledBlockAroundHeart, StyledBlockLoc, StyledBlockHit, StyledCardStatsContentLoc, StyledAttrs
} from "./cardStyle";
import {IconButton, Button, Tooltip} from "@material-ui/core";
import {HeartAd} from "../heart/HeartAd";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {APICardRequest} from "../../API/api";
import {getUserAds} from "../../redux/reducers/ads/sliceAds";
import "../card/card.css";
import clock from "../card/assets/clock.svg";
import map from "../card/assets/map-pin.svg";
import eye from "../card/assets/eye.svg";
import heart from "../card/assets/heart.svg";
import PopupDeleteFollow from "./PopupDeleteFollow/PopupDeleteFollow";
import useResize from "../../Constants/useResize";
import {StyledReactionsComponent} from "../cardblock/cardBlockStyle";
import {numberWithDots} from "../../Constants/numberWithDots";
import { useTranslation } from 'react-i18next';
import {HelperAttrsAdding} from '../detailCard/DetailCard';

const helperImgAdding = (user_id, images, title, lazy = true) => {
    let src = (format, suffix='') => images.length ? `https://d2j7v9zcmivqbu.cloudfront.net/${user_id}/${images[0]}${suffix}.${format}` : placeholderImage;
    return (
        <picture>
            <source
                srcSet={`${src('webp', '-m')} 512w, ${src('webp')} 1024w`}
                sizes="(max-width: 700px) 100vw, (max-width: 1024px) 33.3vw, 33.3vw"
                type="image/webp"
            />
            <StyledImg width="350" height="350" src={src('jpeg')} alt={title} {...(!lazy && {fetchpriority: "high"})} {...(!lazy && {fetchpriority: "high"})}{...(lazy && { loading: "lazy" })} onError={(e) => {
                e.target.onerror = null;
                e.currentTarget.parentNode.children[0].srcset = e.target.src;
                e.currentTarget.parentNode.children[1].srcset = e.target.src;
            }}/>
        </picture>
    )
};

export const Card = (props) => {
    const {
        id, images, title, text, price, slug, index, changeContinuePopup,
        deleteFromFollowed, setUserActive, isTotalPages,
        setPageNumber, setModalActiveDeleteFollow, modalActiveDeleteFollow,
        pageNumber, followedAdsData, followedAdsCount, loc_name, top_date,
        user, time, followed, hits, attrs, usersAdCab = false,
    } = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const {innerWidth} = useResize();
    const { t } = useTranslation();

    const togglePopupDeleteFollow = () => {
        if (setModalActiveDeleteFollow) {
            setModalActiveDeleteFollow(prevState => ({
                ...prevState,
                show: !prevState.show,
                data: {id, title}
            }));
        }
    };

    const handleSendIdToEditURL = (e) => {
        e.stopPropagation();
        history.push(`/ads/edit-ad/${id}`);
    };

    const handleDeleteCard = (e) => {
        e.stopPropagation();
        APICardRequest.deleteCard(id)
            .then((r) => {
                if (r.status === 204) {
                    dispatch(getUserAds());
                }
                // history.go(0);
            })
            .catch((e) => {
                alert(e);
            });
    };

    const heartLayout = () => (
        <HeartAd
            item={props}
            togglePopupDeleteFollow={togglePopupDeleteFollow}
            index={index}
            deleteFromFollowed={deleteFromFollowed}
            setUserActive={setUserActive}
        />
    )

    return (
        <div>
            {innerWidth && innerWidth >= 700 ? (
                    <CardWrapper>
                        <ImgWrapper>
                            <Tooltip title={`${t('open')} ${title} ${t('ad-detail')}`} placement={'top'}
                                     arrow>
                                <CardLink to={`/ads/detail-ad/${id}/${slug}`}>
                                    {helperImgAdding(user.id, images, title, !(index === 0))}
                                    {top_date && (
                                        <SImgTopWrapper>
                                            <span>TOP</span>
                                        </SImgTopWrapper>
                                    )}
                                </CardLink>
                            </Tooltip>
                        </ImgWrapper>

                        <StyledTextWrapper>
                            <div style={{display: 'flex'}} className={"title-card"}>
                                <CardLink to={`/ads/detail-ad/${id}/${slug}`}>
                                    <Tooltip title={`${t('open')} ${title} ${t('ad-detail')}`} placement={'top'}
                                             arrow>
                                        <span>{title}</span>
                                    </Tooltip>
                                </CardLink>

                                {usersAdCab && (
                                    <>
                                        <Button
                                            onClick={handleSendIdToEditURL}
                                            variant={"outlined"}
                                            size={"small"}
                                        >
                                            Edit
                                        </Button>
                                        <Button onClick={handleDeleteCard}>Delete</Button>
                                    </>
                                )}
                            </div>
                            <StyledDescriptionText className={"text-desc"}>
                                {/*{text.trim().replace(/\n/g, '')}*/}
                                {text.length > 51 ? text.substring(0, 51) + "..." : text}
                            </StyledDescriptionText>
                            <StyledCardStatsWrapper className={"stats-wrapper"}>
                            <StyledCardStatsContentLink to={`/user-profile/${user.id}?page=1`}
                                                            className={"user-name-and-photo"}>
                                    <div className={"initial_display"}>
                                        {user.avatar
                                            ? <img src={user.avatar} alt={"avatar"} className={"card_img"}/>
                                            : <span>{user.name.split("")[0]}</span>}
                                    </div>
                                    <span className={"desc-icon"}>{user.name}</span>
                                </StyledCardStatsContentLink>

                                <StyledCardStatsContentLoc className={"user-location"}>
                                    <div>
                                        <img src={map} alt={"map"} className={"map"}/>
                                    </div>
                                    <span className={"desc-icon"}>{loc_name ?? t('no-location')}</span>
                                </StyledCardStatsContentLoc>

                                <StyledReactionsComponent>
                                    <StyledCardStatsContent className={"user-time"}>
                                        <img src={clock} alt={clock} className={"clock"}/>
                                        <StyledTimeAgoText className={"desc-icon"}>
                                            {time.split("T")[0]}
                                        </StyledTimeAgoText>
                                    </StyledCardStatsContent>

                                    <StyledCardStatsContent className={"user-eye"}>
                                        <IconButton size={"small"} component={"span"}>
                                            <img src={eye} alt={"eye"} className={"eye"}/>
                                        </IconButton>
                                        <span className={"desc-icon"}>{hits}</span>
                                    </StyledCardStatsContent>

                                    <StyledCardStatsContent className={"user-likes"}>
                                        <IconButton size={"small"}>
                                            <img src={heart} alt={"heart"} className={"heart"}/>
                                        </IconButton>
                                        <span className={"desc-icon"}>{followed}</span>
                                    </StyledCardStatsContent>
                                </StyledReactionsComponent>
                            </StyledCardStatsWrapper>
                            <StyledAttrs>
                                <HelperAttrsAdding attrs={attrs}/>
                            </StyledAttrs>
                        </StyledTextWrapper>
                        <div className={"price-and-heart"}>
                            <StyledPriceText>
                                <span>{numberWithDots(price)} &#8364;</span>
                            </StyledPriceText>
                            {heartLayout()}
                        </div>
                    </CardWrapper>
                )
                :
                (
                    <CardWrapper>
                    <ImgWrapper className={"img-columns"}>
                            <Tooltip title={`${t('open')} ${title} ${t('ad-detail')}`} placement={'top'}
                                     arrow>
                                <CardLink to={`/ads/detail-ad/${id}/${slug}`}>
                                    {helperImgAdding(user.id, images, title, !(index === 0))}
                                    {top_date && (
                                        <SImgTopWrapper>
                                            <span>TOP</span>
                                        </SImgTopWrapper>
                                    )}
                                </CardLink>
                            </Tooltip>
                        </ImgWrapper>
                        <div>
                            <HelperAttrsAdding attrs={attrs}/>
                        </div>
                        <StyledBlockHeart>
                            <StyledCardStatsContent className={"user-time"}>
                                <img height={20} width={20} src={clock} alt={clock} className={"clock"}/>
                                <StyledTimeAgoText className={"desc-icon"}>
                                    {time.split("T")[0]}
                                </StyledTimeAgoText>
                            </StyledCardStatsContent>
                            {usersAdCab && (
                                <>
                                    <Button
                                        onClick={handleSendIdToEditURL}
                                        variant={"outlined"}
                                        size={"small"}
                                    >
                                        Edit
                                    </Button>
                                    <Button onClick={handleDeleteCard}>Delete</Button>
                                </>
                            )}
                            <StyledBlockAroundHeart>
                                {heartLayout()}
                            </StyledBlockAroundHeart>
                        </StyledBlockHeart>
                        <div className={"title-card"}>
                            <CardLink to={`/ads/detail-ad/${id}/${slug}`}>
                                <Tooltip title={`${t('open')} ${title} ${t('ad-detail')}`} placement={'top'}
                                         arrow>
                                    <span>{title}</span>
                                </Tooltip>
                            </CardLink>
                        </div>
                        <StyledPriceText>
                            <span className={'price-card-block'}>{numberWithDots(price)} &#8364;</span>
                        </StyledPriceText>

                        <StyledBlockLoc>
                            <StyledCardStatsContentLoc className={"user-location"}>
                                <div>
                                    <img height={20} width={20} src={map} alt={"map"} className={"map"}/>
                                </div>
                                <span className={"desc-icon"}>{loc_name ?? t('no-location')}</span>
                            </StyledCardStatsContentLoc>

                            <StyledBlockHit>
                                <StyledCardStatsContent className={"user-eye"}>
                                    <IconButton size={"small"} component={"span"}>
                                        <img src={eye} alt={"eye"} className={"eye"}/>
                                    </IconButton>
                                    <span className={"desc-icon"}>{hits}</span>
                                </StyledCardStatsContent>

                                <StyledCardStatsContent className={"user-likes"}>
                                    <IconButton size={"small"} component={"span"}>
                                        <img src={heart} alt={"heart"} className={"heart"}/>
                                    </IconButton>
                                    <span className={"desc-icon"}>{followed}</span>
                                </StyledCardStatsContent>
                            </StyledBlockHit>
                        </StyledBlockLoc>
                    </CardWrapper>
                )}

            {modalActiveDeleteFollow?.show ? (
                <PopupDeleteFollow
                    modal={modalActiveDeleteFollow}
                    setModal={setModalActiveDeleteFollow}
                    isTotalPages={isTotalPages}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    followedAdsCount={followedAdsCount}
                    changeContinuePopup={changeContinuePopup}
                    followedAdsData={followedAdsData}
                />
            ) : null}
        </div>
    );
};

export default Card;
