import './popupError.css'
import petting from './assets/petting.svg'
import { useTranslation } from 'react-i18next';


const PopupError = (props) => {

    const { t } = useTranslation();

    return (
        <div
             className={props.active ? "modalError active" : "modalError"} onClick={() => props.setActive(false)}>

            <div  className={'modal-content-error'}
                  onClick={e => e.stopPropagation()}>
                <span className={'went-wrong'}>{t('Something went wrong')}</span>
                <span className={'purchase-error'}>{t('Check the data and try again')}</span>
                    <img src={petting} alt={'petting'} className={'petting'}/>
                <button className={'ok-button'} onClick={() => props.setActive(false)}>OK</button>
            </div>
        </div>


    );

};


export default PopupError;