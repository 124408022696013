import React, {useEffect, useState} from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import Bazarbg from '../LoginAcc/assets/background-login.webp';
import EnterNewPassword from "./EnterNewPassword";
import EnterEmail from "./EnterEmail";
import {Link, useHistory, useLocation} from "react-router-dom";
import useBottomPopup from "../../../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../../../Components/SuccessPopup/ChangedConfirmedPopup";
import Seo from "../../../seo";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    resetPasswordInfo: {
        backgroundImage: `url(${Bazarbg})`,
        backgroundPosition: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },

    displayInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    ResetLogoWithAlias: {
        margin: '0 auto 3rem',
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
    },

    resetLogoName: {
        marginTop: '-2rem',
        fontSize: '4.5rem',
        fontWeight: 'bold',
        letterSpacing: '0.3rem',
        color: '#ffffff',
        textAlign: 'center',
        textTransform: 'uppercase',
        '& a': {
            color: 'inherit'
        }
    },

    ResetAlias: {
        color: '#ffffff',
        marginTop: '-0.5rem',
        fontSize: '2rem',
        fontWeight: 'bold',
        textAlign: 'center',
    },
}));

export const ResetPassword = () => {
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const location = useLocation();
    const history = useHistory();
    const [token, setToken] = useState(null);
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        if (urlParams && urlParams.get('token')) {
            let urlToken = urlParams.get('token');
            setToken(urlToken)
        }
    }, [location.search, history])

    return [
        <ChangedConfirmedPopup successText={successText}
                               key={1}
                               changingConfirmed={changingConfirmed}/>,
        <div key={0} className={classes.resetPasswordInfo}>

            <Seo>
                <meta name="robots" content="noindex, nofollow"/>
            </Seo>

            <div className={classes.displayInfo}>
                <div className={classes.ResetLogoWithAlias}>
                    <Typography className={classes.resetLogoName} variant='h1'>
                        <Link to={`/auth?type=login`}>{`${process.env.REACT_APP_TITLE}`}</Link>
                    </Typography>
                    <Typography className={classes.ResetAlias} variant='body1'>
                        {t('page-slogan')}
                    </Typography>
                </div>
                {token ? <EnterNewPassword token={token} changeContinuePopup={changeContinuePopup}/>
                    : <EnterEmail changeContinuePopup={changeContinuePopup}/>}
            </div>
        </div>
    ]
};
