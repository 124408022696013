import {Helmet} from 'react-helmet-async';
import meta_image from '../assets/metaimage.png';
import {useEffect, useState} from "react";

export const Seo = (props) => {
    const {
        title, description, image, fb_title, fb_description, children, price
        // brand, availability, price, item_id, group_id
    } = props;
    const [url, setUrl] = useState(window.location.href);


    useEffect(() => {
        setUrl(window.location.href)
        // console.log(image)
        // eslint-disable-next-line
    }, [window.location.href])

    return (<Helmet prioritizeSeoTags>
            <title>{title}</title>
            {image ? (
                <link key='link-image' rel="preload" fetchpriority="high" as="image"
                      href={Array.isArray(image) ? image[0] : image}/>,
                    <meta name="og:image" property="og:image"
                          content={Array.isArray(image) ? image[0] || meta_image : image || meta_image}/>,
                Array.isArray(image) && image.slice(1).map((img, i) => (
                    <meta key={i + 1} name="og:image" property="og:image" content={img ? img : meta_image}/>
                ))
            ) : (
                <meta name="og:image" property="og:image"
                      content={Array.isArray(image) ? image[0] || meta_image : image || meta_image}/>
            )}
            <meta name="og:title" property="og:title" content={fb_title ?? title}/>
            <meta name="twitter:title" property="twitter:title" content={title}/>
            <meta name="description" property="description" content={description}/>
            <meta name="og:description" property="og:description" content={fb_description ?? description}/>
            <meta name="twitter:description" property="twitter:description" content={description}/>

            <meta name="twitter:image" property="twitter:image" content={image ? image : meta_image}/>
            <meta name="twitter:card" property="twitter:card" content="summary_large_image"/>
            <meta name="robots" content="index,follow"/>
            {!price && <meta name="og:type" property="og:type" content="website"/>}
            {price && <meta name="og:type" property="og:type" content="product"/>}
            {price && <meta property="product:price.amount" content={price}/>}
            {price && <meta property="product:price.currency" content="EUR"/>}
            {url && <meta name="og:url" property="og:url" content={url}/>}
            {url && <link rel="canonical" href={url}/>}


            {/*info for marketplaces*/}

            {/*{item_id && [*/}
            {/*    <meta property="product:brand" key='1' content={brand}/>,*/}
            {/*    <meta property="product:availability" key='2' content={availability ? "in stock" : "sold"}/>,*/}
            {/*    <meta property="product:condition" key='3' content="new"/>,*/}
            {/*    <meta property="product:price:amount" key='4' content={price}/>,*/}
            {/*    <meta property="product:price:currency" key='5' content="EUR"/>,*/}
            {/*    <meta property="product:retailer_item_id" key='6' content={item_id}/>,*/}
            {/*    <meta property="product:item_group_id" key='7' content={group_id}/>*/}
            {/*]}*/}
            {price && <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": "${title}",
                        "image": "${Array.isArray(image) ? image[0] || meta_image : image || meta_image}",
                        "description": "${fb_description}",
                        "offers": {
                            "@type": "Offer",
                            "priceCurrency": "EUR",
                            "price": "${price}",
                            "itemCondition": "https://schema.org/UsedCondition",
                            "availability": "https://schema.org/InStock"
                        }
                    }
            `}</script>}
            {children}
        </Helmet>
    );
};