import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {APICardRequest} from '../../../API/api';

export const getAdsData = createAsyncThunk(
    'ADS/fetchAllAds',
    async (props) => {
        return await APICardRequest.getAllAds(props);
    },
);

export const getUserAds = createAsyncThunk(
    'ADS/getUserAds',
    async (props) => {
        const {id, page} = props;
        return await APICardRequest.getActiveAds(id, page);
    },
);

export const getFollowedAds = createAsyncThunk(
    'ADS/getFollowedAds',
    async (payload) => {
        const {page, page_size} = payload;
        return await APICardRequest.getFollowedCards(page, page_size);
    },
);

const initialState = {
    ads: [],
    usersAds: [],
    followedAds: [],
    followedAdsCount: 0,
    loading: true,
    usersAdsLoading: true,
    allAdsLoading: true,
    err: '',
    userAdsReactions: [],
    pages: '',
    followedPages: '',
    totalCount: 0,
    mainPageTotal: null,
};

const adsSlice = createSlice({
    name: 'ADS',
    initialState: initialState,
    reducers: {
        deleteLocalCard: (state, {payload}) => {
            state.usersAds = state.usersAds.filter(({id}) => id !== payload);
            state.totalCount = state.totalCount - 1;
        },
        cleanPersonalAds: (state) => {
            state.usersAds = [];
        },
        getAdReactions: (state, {payload}) => {
            state.userAdsReactions = payload;
        },
        setFollowedAd: (state, props) => {
            let {payload: {index, status}} = props;
            state.ads = [
                ...state.ads.slice(0, index),
                {...state.ads[index], liked: status === 'following'},
                ...state.ads.slice(index + 1),
            ]
            state.followedAdsCount = status === 'following' ? state.followedAdsCount + 1 : state.followedAdsCount - 1
        },
        deleteFromFollowed: (state, {payload}) => {
            state.followedAds = state.followedAds.filter(x => x.id !== payload)
            state.followedAdsCount = state.followedAdsCount - 1
        },
        updateFollowedCount: (state, {payload}) => {
            state.followedAdsCount = payload ? state.followedAdsCount + 1 : state.followedAdsCount - 1
        },

    },
    extraReducers: (builder => {
        builder.addCase(getAdsData.pending, (state) => {
            state.allAdsLoading = true;
        });
        builder.addCase(getAdsData.fulfilled, (state, action) => {
            state.ads = action.payload.data;
            state.pages = JSON.parse(action.payload?.headers['x-pagination'])?.total_pages;
            state.totalCount = JSON.parse(action.payload?.headers['x-pagination'])?.total;
            state.mainPageTotal = JSON.parse(action.payload?.headers['x-pagination'])?.total_pages;
            state.allAdsLoading = false;
        });
        builder.addCase(getAdsData.rejected, (state, action) => {
            state.err = `ERROR : ${action.error.message}`;
            state.allAdsLoading = false;
        });

        builder.addCase(getUserAds.pending, (state) => {
            state.usersAdsLoading = true;
        });
        builder.addCase(getUserAds.fulfilled, (state, action) => {
            state.usersAds = action.payload.data;
            state.pages = JSON.parse(action.payload.headers['x-pagination'])?.total_pages;
            state.totalCount = JSON.parse(action.payload?.headers['x-pagination'])?.total;
            state.usersAdsLoading = false;
        });
        builder.addCase(getUserAds.rejected, (state, action) => {
            state.err = action.payload;
            state.usersAdsLoading = false;
        });

        builder.addCase(getFollowedAds.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getFollowedAds.fulfilled, (state, action) => {
            state.followedAds = action.payload.data.map(x => {
                return {
                    ...x,
                    liked: true,
                }
            });
            state.followedAdsCount = JSON.parse(action.payload.headers['x-pagination']).total;
            state.followedPages = JSON.parse(action.payload.headers['x-pagination'])?.total_pages;
            state.loading = false;
        });
        builder.addCase(getFollowedAds.rejected, (state, action) => {
            state.err = action.payload;
            state.loading = false;
        });
    }),
});

export const {
    // setLoading,
    deleteLocalCard,
    cleanPersonalAds,
    deleteFromFollowed,
    getAdReactions,
    setFollowedAd,
    updateFollowedCount,
} = adsSlice.actions;

export default adsSlice.reducer;