import React from 'react';
import loadable from '@loadable/component'
import {UserArchive} from '../pages/userArchive/userArchive';
import {Followed} from '../pages/adsActions/Followed/Followed';
import {UserInvoices} from '../pages/userInvoices/UserInvoices/UserInvoices';
// import CreateAd from '../pages/adsActions/CreateAd';
import MyAds from "../pages/adsActions/MyAds";

const AdsList = loadable(() => import('../pages/AdsList/AdsList'))
const AdDetail = loadable(() => import('../pages/adsActions/AdDetail/AdDetail'))
const LandingPage = loadable(() => import('../pages/LandingPage/landingPage'))
const UserProfile = loadable(() => import('../pages/userProfile/userProfile'))
const Policy = loadable(() => import('../pages/footer/policy/Policy'))
const Pricing = loadable(() => import('../pages/userPricing/Pricing'))
const TermsOfUse = loadable(() => import('../pages/footer/policy/TermsOfUse'))
const UserRatings = loadable(() => import('../pages/userRatings/UserRatings'))
const UserMessages = loadable(() => import('../pages/userMessages/UserMessages'))
const Chat = loadable(() => import('../pages/userMessages/Components/Chat/Chat'))
// const MyAds = loadable(() => import('../pages/adsActions/MyAds'))
const UserSettings = loadable(() => import('../pages/userSettings/userSettings'))
const CreateAd = loadable(() => import('../pages/adsActions/CreateAd'))

export const Navigation = {
    mainNavigation: [
        {
            exact: true,
            path: '/',
            main: () => <LandingPage/>,
        },
        {
            exact: true,
            path: '/ads',
            main: () => <AdsList/>,
        },

        {
            exact: true,
            path: '/ads/detail-ad/:id/:advertising?',
            main: () => <AdDetail/>,
        },
        {
            exact: true,
            path: '/user-profile/:id',
            main: () => <UserProfile/>,
        },
        {
            exact: true,
            path: '/privacy-policy',
            main: () => <Policy/>,
        },
        {
            exact: true,
            path: '/terms-of-use',
            main: () => <TermsOfUse/>,
        },
        {
            exact: true,
            path: '/pricing',
            main: () => <Pricing/>,
        },
    ],
    profileNavigation: [
        {
            exact: true,
            path: '/profile/my-ads',
            pathname: 'my-ads',
            label: 'My Ads List',
            pagination: true,
            icon: (
                <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M8.5 4H6.5C5.39543 4 4.5 4.89543 4.5 6V7C4.5 8.10457 5.39543 9 6.5 9H8.5C9.60457 9 10.5 8.10457 10.5 7V6C10.5 4.89543 9.60457 4 8.5 4Z'
                        stroke='#475569' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                    <path
                        d='M8.5 13H6.5C5.39543 13 4.5 13.8954 4.5 15V18C4.5 19.1046 5.39543 20 6.5 20H8.5C9.60457 20 10.5 19.1046 10.5 18V15C10.5 13.8954 9.60457 13 8.5 13Z'
                        stroke='#475569' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                    <path
                        d='M18.5 4H16.5C15.3954 4 14.5 4.89543 14.5 6V18C14.5 19.1046 15.3954 20 16.5 20H18.5C19.6046 20 20.5 19.1046 20.5 18V6C20.5 4.89543 19.6046 4 18.5 4Z'
                        stroke='#475569' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                </svg>
            ),
            main: () => <MyAds/>,
        },
        {
            exact: true,
            path: '/profile/messages',
            pathname: 'messages',
            label: 'Messages',
            pagination: true,
            icon: (
                <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M3.5 20.0015L4.8 16.1015C3.67644 14.4398 3.26999 12.472 3.65622 10.5638C4.04244 8.65573 5.19506 6.93722 6.89977 5.72782C8.60447 4.51842 10.7453 3.90044 12.9241 3.98878C15.1029 4.07713 17.1715 4.86578 18.7453 6.2081C20.319 7.55043 21.2909 9.35509 21.4801 11.2865C21.6693 13.218 21.063 15.1448 19.7739 16.7087C18.4848 18.2726 16.6007 19.3672 14.4718 19.7889C12.3429 20.2106 10.1142 19.9308 8.2 19.0015L3.5 20.0015'
                        stroke='#475569' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                </svg>
            ),
            main: () => <UserMessages/>,
        },
        {
            exact: true,
            path: '/profile/my-followed',
            pathname: 'my-followed',
            pagination: true,
            label: 'Followed Ads',
            icon: (
                <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M20.0001 13.5717L12.5001 20.9997M12.5001 20.9997L5.00006 13.5717C4.50536 13.0903 4.1157 12.5117 3.8556 11.8723C3.59551 11.2329 3.47062 10.5466 3.48879 9.85662C3.50697 9.1666 3.66782 8.48782 3.96121 7.86303C4.25461 7.23823 4.67419 6.68094 5.19354 6.22627C5.71289 5.77159 6.32076 5.42938 6.97887 5.22117C7.63697 5.01296 8.33106 4.94327 9.01743 5.01649C9.70379 5.08971 10.3676 5.30425 10.9669 5.64659C11.5663 5.98894 12.0883 6.45169 12.5001 7.00569C12.9136 6.45571 13.4362 5.99701 14.0352 5.65829C14.6341 5.31958 15.2966 5.10814 15.981 5.03721C16.6654 4.96628 17.3571 5.03739 18.0128 5.24608C18.6685 5.45477 19.2741 5.79656 19.7916 6.25005C20.3091 6.70354 20.7275 7.25897 21.0205 7.88158C21.3135 8.50419 21.4748 9.18057 21.4944 9.8684C21.5139 10.5562 21.3913 11.2407 21.1342 11.8789C20.8771 12.5172 20.491 13.0955 20.0001 13.5777L12.5001 20.9997Z'
                        stroke='#475569' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                </svg>
            ),
            main: () => <Followed/>,
        },
        {
            exact: true,
            path: '/profile/my-archive',
            pathname: 'my-archive',
            label: 'Archive',
            pagination: true,
            icon: (
                <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M19.5 4H5.5C4.39543 4 3.5 4.89543 3.5 6C3.5 7.10457 4.39543 8 5.5 8H19.5C20.6046 8 21.5 7.10457 21.5 6C21.5 4.89543 20.6046 4 19.5 4Z'
                        stroke='#475569' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                    <path
                        d='M5.5 8V18C5.5 18.5304 5.71071 19.0391 6.08579 19.4142C6.46086 19.7893 6.96957 20 7.5 20H17.5C18.0304 20 18.5391 19.7893 18.9142 19.4142C19.2893 19.0391 19.5 18.5304 19.5 18V8'
                        stroke='#475569' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                    <path d='M10.5 12H14.5' stroke='#475569' strokeWidth='2' strokeLinecap='round'
                          strokeLinejoin='round'/>
                </svg>
            ),
            main: () => <UserArchive/>,
        },
        {
            exact: true,
            path: '/profile/my-invoices',
            pathname: 'my-invoices',
            pagination: true,
            label: 'Payment and transactions',
            icon: (
                <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M18.5 5H6.5C4.84315 5 3.5 6.34315 3.5 8V16C3.5 17.6569 4.84315 19 6.5 19H18.5C20.1569 19 21.5 17.6569 21.5 16V8C21.5 6.34315 20.1569 5 18.5 5Z'
                        stroke='#475569' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                    <path d='M3.5 10H21.5' stroke='#475569' strokeWidth='2' strokeLinecap='round'
                          strokeLinejoin='round'/>
                    <path d='M7.5 15H7.51' stroke='#475569' strokeWidth='2' strokeLinecap='round'
                          strokeLinejoin='round'/>
                    <path d='M11.5 15H13.5' stroke='#475569' strokeWidth='2' strokeLinecap='round'
                          strokeLinejoin='round'/>
                </svg>
            ),
            main: () => <UserInvoices/>,
        },
        {
            exact: true,
            path: '/profile/messages/:id',
            pathname: 'messages',
            main: () => <Chat/>,
        },
        {
            exact: true,
            path: '/profile/my-settings/:slug',
            pathname: 'my-settings',
            link: '/profile/my-settings/main-info',
            label: 'Settings',
            icon: (
                <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M10.825 4.317C11.251 2.561 13.749 2.561 14.175 4.317C14.2389 4.5808 14.3642 4.82578 14.5407 5.032C14.7172 5.23822 14.9399 5.39985 15.1907 5.50375C15.4414 5.60764 15.7132 5.65085 15.9838 5.62987C16.2544 5.60889 16.5162 5.5243 16.748 5.383C18.291 4.443 20.058 6.209 19.118 7.753C18.9769 7.98466 18.8924 8.24634 18.8715 8.51677C18.8506 8.78721 18.8938 9.05877 18.9975 9.30938C19.1013 9.55999 19.2627 9.78258 19.4687 9.95905C19.6747 10.1355 19.9194 10.2609 20.183 10.325C21.939 10.751 21.939 13.249 20.183 13.675C19.9192 13.7389 19.6742 13.8642 19.468 14.0407C19.2618 14.2172 19.1001 14.4399 18.9963 14.6907C18.8924 14.9414 18.8491 15.2132 18.8701 15.4838C18.8911 15.7544 18.9757 16.0162 19.117 16.248C20.057 17.791 18.291 19.558 16.747 18.618C16.5153 18.4769 16.2537 18.3924 15.9832 18.3715C15.7128 18.3506 15.4412 18.3938 15.1906 18.4975C14.94 18.6013 14.7174 18.7627 14.5409 18.9687C14.3645 19.1747 14.2391 19.4194 14.175 19.683C13.749 21.439 11.251 21.439 10.825 19.683C10.7611 19.4192 10.6358 19.1742 10.4593 18.968C10.2828 18.7618 10.0601 18.6001 9.80935 18.4963C9.55859 18.3924 9.28683 18.3491 9.01621 18.3701C8.74559 18.3911 8.48375 18.4757 8.252 18.617C6.709 19.557 4.942 17.791 5.882 16.247C6.0231 16.0153 6.10755 15.7537 6.12848 15.4832C6.14942 15.2128 6.10624 14.9412 6.00247 14.6906C5.8987 14.44 5.73726 14.2174 5.53127 14.0409C5.32529 13.8645 5.08056 13.7391 4.817 13.675C3.061 13.249 3.061 10.751 4.817 10.325C5.0808 10.2611 5.32578 10.1358 5.532 9.95929C5.73822 9.7828 5.89985 9.56011 6.00375 9.30935C6.10764 9.05859 6.15085 8.78683 6.12987 8.51621C6.10889 8.24559 6.0243 7.98375 5.883 7.752C4.943 6.209 6.709 4.442 8.253 5.382C9.253 5.99 10.549 5.452 10.825 4.317Z'
                        stroke='#475569' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                    <path
                        d='M12.5 15C14.1569 15 15.5 13.6569 15.5 12C15.5 10.3431 14.1569 9 12.5 9C10.8431 9 9.5 10.3431 9.5 12C9.5 13.6569 10.8431 15 12.5 15Z'
                        stroke='#475569' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                </svg>
            ),
            main: () => <UserSettings/>,
        },
        {
            exact: true,
            path: '/profile/ratings',
            pathname: 'ratings',
            main: () => <UserRatings/>,
        },
    ],
    protectedNavigation: [
        {
            exact: true,
            path: '/createAd',
            main: () => <CreateAd/>,
        },
        {
            exact: true,
            path: '/ads/edit-ad/:id',
            main: () => <CreateAd editAd/>,
        },
    ],
};
