import React from "react";
import '../mobMenu/mobMenu.css'
import edit from '../mobMenu/assets/edit.svg'
import {useHistory} from "react-router-dom";

export const MobMenu = (props) => {
    const {modal, setModal} = props;
    const history = useHistory();

    const sortArr = [
        {

            text: 'Edit Ad',
            value: 'edit',
            img: edit,
            onClick: () => {
                history.push(`/ads/edit-ad/${modal.data.id}?type=archived`)
            }
        },
        // {
        //     text: 'Delete Ad',
        //     value: 'delete',
        //     img: deleteImg,
        //     onClick: togglePopup,
        // },
    ];

    return (
        <div style={{background: modal.show ? "rgba(0, 0, 0, 0.05)" : "transparent"}}
             className={modal.show ? "modalMobMenu active" : "modalMobMenu"}
             onClick={() => {
                 setModal({
                     show: false,
                     data: null,
                 })
             }}>
            <div className={'sort_mobile_popular'}>
                {sortArr.map((item, index) => (
                    <div onClick={item.onClick} className={'mob-menu-icon'} key={index}>
                        <img src={item.img} alt={item.value}/>
                        <span className={'sortPopularMob'}>{item.text}</span>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default MobMenu;