import Keys from "../../../Constants/helper";
import {setIsLoginUser, setUserData} from "../../../redux/reducers/userAuth/sliceUserAuth";
import {AuthOperations} from "../../../API/api";
import {errorSweetAlert, facebookLoginErrorSweetAlert} from "../../../sections/sweetAlert/sweetAlert";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import FacebookLogin from "@greatsumini/react-facebook-login";
import Facebook from "../../userSettings/contactInfo/icons/Facebook";
import facebook from "./assets/facebook.svg";
import {useGoogleLogin} from "@react-oauth/google";
import google from "./assets/google.svg";
import React from 'react';
import {useTranslation} from 'react-i18next';

const OAuth = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();

    const successLogin = (res) => {
        // console.log(res)
        const {refresh_token, access_token} = res.data;
        localStorage.setItem(Keys.JWT_TOKEN, access_token);
        localStorage.setItem(Keys.REFRESH_TOKEN, refresh_token);
        dispatch(setIsLoginUser());
        dispatch(setUserData({access_token: access_token}));
        history.push("/");
    }


    const onGoogleSuccess = (response) => {
        console.log(response)
        AuthOperations.googleLogin({access_token: response.access_token})
            .then(res => successLogin(res))
            .catch(err => {
                console.log(err)
                return errorSweetAlert(t('auth.error-occurred'))
            })
    }

    const onFacebookSuccess = (response) => {
        console.log('Login Success!', response);
        AuthOperations.facebookLogin({access_token: response.accessToken})
            .then(res => successLogin(res))
            .catch(err => {
                console.log(err)
                return facebookLoginErrorSweetAlert(t('auth.facebook-error.title'), t('auth.facebook-error.text'),
                    t('auth.facebook-error.url_text'))
            })
    }

    const login = useGoogleLogin({
        onSuccess: onGoogleSuccess,
    });

    return (
        <div className={"log-in-google"}>
            <span>{t('log-in-conveniently-through')}</span>
            <div className={"facebook-google"}>
                <FacebookLogin
                    appId='350990372794737'
                    initParams={{
                        version: 'v21.0',
                    }}
                    autoLoad={false}
                    textButton=''
                    fields='name,email,picture'
                    callback={(response) => {
                        console.log(response);
                    }}
                    onSuccess={onFacebookSuccess}
                    onFail={(error) => {
                        console.log('Login Failed!', error);
                        if (error.status === "loginCancelled") {
                            return
                        }
                        return errorSweetAlert(t('auth.error-occurred'))
                    }}
                    onProfileSuccess={(response) => {
                        console.log('Get Profile Success!', response);
                    }}
                    cssClass='my-facebook-button-class'
                    icon={<Facebook/>}
                    render={({onClick}) => (
                        <div onClick={onClick} className={"facebook"}>
                            <img src={facebook} alt={"facebook"}/>
                            <span>Facebook</span>
                        </div>
                    )}
                />


                <div className={"google"}
                     onClick={() => login()}>
                    <img src={google} alt={"google"}/>
                    <span>Google</span>
                </div>
            </div>
            <span className={"or"}>- {t('or')} -</span>
        </div>
    )
};

export default OAuth;