import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {APIChat} from '../../../API/api';

export const checkNewMessages = createAsyncThunk(
    'ADS/checkNewMessages',
    async () => {
        return await APIChat.getUserConversations('unread', 1, 100)
    },
);

const initialState = {
    messagesCount: 0,
    newNotification: [],
    notificationData: sessionStorage.getItem('notifications') && sessionStorage.getItem('notifications') !== 'undefined'
        ? JSON.parse(sessionStorage.getItem('notifications')) : [],
    loading: true,
    err: null,
};

const chatSlice = createSlice({
    name: 'Chat',
    initialState: initialState,
    reducers: {
        updateMessageCount: (state, {payload}) => {
            state.messagesCount = payload ? payload : state.messagesCount > 0 ? state.messagesCount - 1 : 0;
        },
        setNewNotifications: (state, {payload}) => {
            state.newNotification = payload;
        },
        updateNotificationData: (state, {payload}) => {
            state.notificationData = payload;
            sessionStorage.setItem('notifications', JSON.stringify(payload))
        },
    },
    extraReducers: (builder => {
        builder.addCase(checkNewMessages.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(checkNewMessages.fulfilled, (state, action) => {
            state.messagesCount = JSON.parse(action.payload.headers['x-pagination']).total;
            state.loading = false;
        });
        builder.addCase(checkNewMessages.rejected, (state, action) => {
            state.err = action.payload;
            state.loading = false;
        });
    }),
});

export const {
    updateMessageCount,
    updateNotificationData,
    setNewNotifications,
} = chatSlice.actions;

export default chatSlice.reducer;