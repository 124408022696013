import React, {useState} from "react";
import {FaSms} from "react-icons/fa";
import {
    errorSweetAlert, SMSCouponAlert
} from '../../sections/sweetAlert/sweetAlert';
import {APICardRequest} from "../../API/api";
import PopSMSPaymentCheckout from "./PopSMSPaymentCheckout"
import {useTranslation} from 'react-i18next';


const PayBySMS = (props) => {
    const {t} = useTranslation();
    const {id, closeModal, setModalActivePurchase, setModalActiveError} = props
    const [isLoading, setIsLoading] = useState(false)
    const [showPaymentCheckout, setShowPaymentCheckout] = useState({
        show: false,
        data: null,
    })
    const [couponInput, setCouponInput] = useState('');

    const togglePaymentCheck = (data) => {
        setShowPaymentCheckout(prevState => ({
            show: !prevState.show,
            data,
        }))
    };


    const handlePayBySMS = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            APICardRequest.adTopSms(id, {coupon: couponInput}).then(
                (r) => {
                    if (r.data.success === true) {
                        closeModal()
                        setModalActivePurchase(true)
                    } else {
                        setModalActivePurchase(false)
                        SMSCouponAlert(t('sms-top.invalid-coupon'))
                    }
                }
            ).catch((e) => {
                console.log(e)
                errorSweetAlert('Payment Error')
            })

        } catch (err) {
            setModalActiveError(true)
            console.log(err)
        }
        setIsLoading(false);

    };


    return (
        <div>
            <button onClick={() => togglePaymentCheck()}
                    type="submit" className={'payByCard'}>
                {t('pay-by-sms')}
                <FaSms size={24}/>
            </button>
            {showPaymentCheckout.show && (
                <PopSMSPaymentCheckout
                    modal={showPaymentCheckout}
                    setModal={setShowPaymentCheckout}
                    handlePayBySMS={handlePayBySMS}
                    isLoading={isLoading}
                    setCouponInput={setCouponInput}
                />
            )}
        </div>
    )

}

export default PayBySMS