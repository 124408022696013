import io from "socket.io-client";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAccessTokenSelector} from "../redux/selectors/selectors";
import {APIUserRequest} from "../API/api";
import Keys from "./helper";

const useNotifications = () => {

    const dispatch = useDispatch();
    const access_token = useSelector(getAccessTokenSelector);

    const [notifications, setNotifications] = useState(null);

    useEffect(() => {
        if (access_token) {
            setNotifications(io(`${process.env.REACT_APP_SOCKET_URL}/notifications`, {
                query: {token: access_token},
                withCredentials: true,
                transports: ["websocket"] // or [ "websocket", "polling" ] (the order matters)
            }))
        }
    }, [access_token])

    useEffect(() => {
        if (!!notifications) {
            notifications.connect();
            // notifications.on("connect", (con) => {
            //     console.log(con)
            //     console.log(notifications.id); // x8WIv7-mJelg7on_ALbx
            //     console.log(notifications.connected); // true
            //     //console.log(socket.rooms);
            // });

            notifications.on("connect_error", (err) => {
                if (err.message === 'unauthorized') {
                    console.log(`connect_error due to ${err.message}`);
                    APIUserRequest.userRefreshToken(localStorage.getItem(Keys.REFRESH_TOKEN), dispatch).then(r => r);
                }
            });
            notifications.on("unauthorized", (error) => {
                console.log(error)
                APIUserRequest.userRefreshToken(localStorage.getItem(Keys.REFRESH_TOKEN), dispatch).then(r => r);
            });
        }
        return () => notifications ? notifications.disconnect() : null;
    }, [notifications, dispatch])

    return {notifications, setNotifications};
};

export default useNotifications;