import styled from 'styled-components';
export  const SearchItemWrapper = styled.div `
    align-items: center;

`
export const FormWrapper = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color:  transparent;
  height: 2.4rem;
  position: relative;
  width: ${props => (props.openSearchBar? "799px " : "1rem")};
  // padding-right: ${props => (props.openSearchBar? "25px" : "")};
  cursor: ${props => (props.openSearchBar? "auto" : "pointer")};
  transition: width 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  padding-right: 1rem;

  @media (max-width: 820px) {
    width: ${props => (props.openSearchBar? "425px" : "1.5rem")};

  }

  // @media (max-width: 768px) {
  //   width: ${props => (props.openSearchBar? "425px" : "1.5rem")};
  // }

  @media (max-width: 540px) {
    width: ${props => (props.openSearchBar? "310px" : "1.5rem")};

  }
  @media (max-width: 425px) {
    width: ${props => (props.openSearchBar? "295px" : "1.5rem")};
  }
  
  // @media (max-width: 414px) {
  //   width: ${props => (props.openSearchBar? "387px" : "1.5rem")};
  //  
  // }
  @media (max-width: 412px) {
    width: ${props => (props.openSearchBar? "270px" : "1.5rem")};
  }

  @media (max-width: 390px) {
    width: ${props => (props.openSearchBar? "270px" : "1.5rem")};
  }

  @media (max-width: 375px) {
    width: ${props => (props.openSearchBar? "250px" : "1.5rem")};
  }

  @media (max-width: 360px) {
    width: ${props => (props.openSearchBar? "250px" : "1.5rem")};

  }

`;

export const InputWrapper = styled.input`
  background-color: transparent;
  display: ${props => (props.openSearchBar? "block" : "hidden")};
  flex: 1;
  //width: 335px;
  border: none;
  color: ${props => (props.openSearchBar? "white" : "transparent")};
  transition: margin 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  //margin-left: 0.8rem;

  &:focus,
  &:active {
    outline: none;
  }

  &::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${props => (props.openSearchBar? "rgba(255, 255, 255, 0.3)" : "transparent")};
   }
  //@media (max-width: 820px) {
  //  width: 743px
  //}
  //
  //@media (max-width: 768px) {
  //  width: 690px
  //}
  //
  //@media (max-width: 540px) {
  //  width: 458px
  //}
  //@media (max-width: 425px) {
  //  width: 347px;
  //}
  //@media (max-width: 414px) {
  //  width: 336px;
  //}
  //
  //@media (max-width: 393px) {
  //  width: 315px;
  //}
  //
  //@media (max-width: 375px) {
  //  width: 295px;
  //}
  //
  //@media (max-width: 360px) {
  //  width: 285px;
  //}
`;

export const ImgIcon = styled.img`
  line-height: 1;
  color: white;
  
`;

export const ImgCancel = styled.img`
  display: ${props => (props.openSearchBar? "block" : "none")};
  //padding-right: 5px;
`;
