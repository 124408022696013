import {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

const useActivePage = () => {
    const history = useHistory();
    let activeUrlPage = new URLSearchParams(window.location.search).get("page");
    const [pageNumber, setPageNumber] = useState(activeUrlPage ? Number(activeUrlPage) : 1);
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        if (activeUrlPage && (+activeUrlPage < 1 || isNaN(+activeUrlPage))) {
            return history.push('/404')
        }
    }, [history, activeUrlPage])

    useEffect(() => {
        setPageLoaded(false);
        let newParams = new URLSearchParams(window.location.search);
        newParams.set('page', pageNumber.toString());
        window.history.replaceState(null, '', window.location.pathname + '?' + newParams.toString())
        window.scrollTo(0, 0);
        setPageLoaded(true);
        return () => setPageLoaded(false);
    }, [pageNumber])

    return {pageNumber, pageLoaded, setPageNumber};
};

export default useActivePage;