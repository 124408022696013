import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useHistory, useLocation} from "react-router-dom";
import {
    setIsLoginUser, setUserData,
} from "../../../redux/reducers/userAuth/sliceUserAuth";
import {useDispatch} from "react-redux";
import "../LoginAcc/login.css";
import Keys from "../../../Constants/helper";
import Registration from "./Registration";
import Login from "./Login";
import Seo from "../../../seo";
import { useTranslation } from 'react-i18next';

export const LoginAcc = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [password, setPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState("")
    const [error, setError] = useState({
        show: false,
        text: ""
    })
    const [activeTab, setActiveTab] = useState(() => new URLSearchParams(location.search).get("type") ?? "login");

    useEffect(() => {
        if (localStorage.getItem(Keys.JWT_TOKEN)) {
            history.push("/profile/my-ads?page=1");
        }
    }, [history]);

    useEffect(() => {
        let activePage = new URLSearchParams(location.search).get("type");
        if (activePage !== "login" && activePage !== "sign-up") {
            history.push("/auth?type=login");
        } else if (activePage) {
            setActiveTab(activePage);
        } else {
            setActiveTab("login");
        }
    }, [location.search, history]);

    const handleLogin = (dataUser) => {
        setLoading(true);
        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + "/auth/login",
            headers: {"Content-Type": "application/json"},
            data: JSON.stringify(dataUser),
        })
            .then((r) => {
                if (r.status === 200) {
                    // console.log(r);
                    const {refresh_token, access_token} = r.data;
                    localStorage.setItem(Keys.JWT_TOKEN, access_token);
                    localStorage.setItem(Keys.REFRESH_TOKEN, refresh_token);
                    dispatch(setIsLoginUser());
                    dispatch(setUserData({access_token: access_token}));
                    setLoading(false);
                    history.push("/");
                }
            })
            .catch((e) => {
                console.log("LOGIN", e);
                setLoading(false);
                setErr(t('auth.bad-credentials-info'));
            });
    };

    const handleCreateUser = (dataUser) => {
        let usersInfo = dataUser;
        setLoading(true);
        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + "/auth/register",
            headers: {"Content-Type": "application/json"},
            data: JSON.stringify(dataUser),
        })
            .then((r) => {
                if (r.status === 200) {
                    // console.log(r.data)
                    const {access_token, refresh_token} = r.data;
                    usersInfo = {...usersInfo, access_token};
                    setLoading(false);
                    localStorage.setItem(Keys.JWT_TOKEN, access_token);
                    localStorage.setItem(Keys.REFRESH_TOKEN, refresh_token);
                    dispatch(setIsLoginUser());
                    history.push("/");
                    return dispatch(setUserData(usersInfo));
                }
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e.response.data.status);
                    setError({
                        show: true,
                        // text: e.response.data.status,
                        text: "Email already exist!"
                    });
                    setLoading(false);
                    setTimeout(() => {
                        setError({
                            show: false,
                        })
                    }, 5000)
                }
            });
    };

    const handleChangePassword = () => {
        setPassword(!password);
    };

    return (
        <div className={"log-in-form"}>

            <Seo>
                <meta name="robots" content="noindex, nofollow"/>
            </Seo>

            <div className={"main-form-login"}>
                <Link to={'/'}>
                    <span className={"main-word"}>{`${process.env.REACT_APP_TITLE}`}</span>
                </Link>
                <span className={"hyperspace"}>{t('page-slogan')}</span>
                <div className={"email-password-form"}>
                    <div className={"wrapper_tabs"}>
                    <span className={`${activeTab === "login" ? "active_tab" : ""} tab`}
                          onClick={() => history.push("/auth?type=login")}>{t('log-in')}</span>
                        <span className={`${activeTab === "sign-up" ? "active_tab" : ""} tab`}
                              onClick={() => history.push("/auth?type=sign-up")}>{t('registration')}</span>
                    </div>
                    {activeTab === "login"
                        ? <Login handleLogin={handleLogin} password={password} err={err}
                                 loading={loading}
                                 handleChangePassword={handleChangePassword}/>
                        : <Registration handleCreateUser={handleCreateUser}
                                        loading={loading}
                                        handleChangePassword={handleChangePassword}
                                        password={password} error={error}/>}
                </div>
            </div>
        </div>
    );
};
