import {useState} from 'react';

export default function useBottomPopup() {
    const [changingConfirmed, setChangingConfirmed] = useState(false);
    const [successText, setSuccessText] = useState(null);

    let changeContinuePopup = (text = "Changes saved successfully") => {
        setChangingConfirmed(state => !state)
        setSuccessText(text)
    }

    return {successText, changingConfirmed, changeContinuePopup}
}