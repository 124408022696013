import React, {useState} from 'react';
import './userInvoices.css'
import PayAndInv from "./PaymentInvoices/PayAndInv";
import Prepay from "./PaymentInvoices/Prepay/Prepay";
import Seo from "../../../seo";

export const UserInvoices = () => {
    const [prepayActive, setPrepayActive] = useState(false);

    const setPrepayValue = (val) => setPrepayActive(val);

    return (
        <div className={'wrapper_user_invoices'}>

            <Seo>
                <meta name="robots" content="noindex, nofollow"/>
            </Seo>

            {prepayActive ? <Prepay setPrepayValue={setPrepayValue}/> : <PayAndInv setPrepayValue={setPrepayValue}/>}
        </div>
    );
};