import React, {useRef, useEffect} from "react";

export default function OutsideClickHandler(props) {

    const {callback, className, children} = props;

    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) callback(event)
        }

        const keyEscape = key => {
            if (key.code === 'Escape') callback()
        }

        document.addEventListener("keydown", keyEscape);
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", keyEscape);
        };
    }, [ref, callback]);

    return <div className={`outside_click_wrapper overflow_scrollbar ${className ?? ''}`}
                ref={ref}>{children}</div>;
}
