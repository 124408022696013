import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {APIUserRequest} from '../../../API/api';
import Keys from "../../../Constants/helper";

export const getUserDataThunk = createAsyncThunk(
    'userAuth/getUserDataThunk',
    async () => {
        return await APIUserRequest.getUserInfo();
    },
);
export const setUserDataThunk = createAsyncThunk(
    'userAuth/setUserDataThunk',
    async (data) => {
        const {accToken, ...rest} = data;
        return await APIUserRequest.setUserInfo(rest);
    },
);

export const getUserAddress = createAsyncThunk(
    'userAuth/getUserAddress',
    async () => {
        return await APIUserRequest.getUserAddress();
    },
);

const initialState = {
    accessToken: localStorage.getItem(Keys.JWT_TOKEN) ? localStorage.getItem(Keys.JWT_TOKEN) : '',
    userName: '',
    userAvatar: '',
    userEmail: '',
    isLogin: !!localStorage.getItem(Keys.JWT_TOKEN),
    userId: '',
    balance: '',
    billingInfo: {
        street: '',
        city: '',
        zip: '',
        country: '',
        company_name: '',
        vat_id: '',
        reg_id: '',
        tax_id: '',
    },
};

const userAuth = createSlice({
    name: 'userAuth',
    initialState: initialState,
    reducers: {
        authErr: () => {

        },
        setUserData: (state, {payload}) => {
            // console.log('SET DATA ', payload);
            const {name, email, avatar, access_token} = payload;
            state.userName = name;
            state.userAvatar = avatar ?? '';
            state.userEmail = email;
            state.accessToken = access_token;
        },
        setIsLoginUser: (state) => {
            state.isLogin = !!localStorage.getItem(Keys.JWT_TOKEN);
        },
        setToken: (state, {payload}) => {
            state.accessToken = payload;
        },
        setLogOutUser: (state) => {
            state.accessToken = '';
        },

    },
    extraReducers: (builder) => {
        builder.addCase(getUserDataThunk.fulfilled, (state, action) => {
            // console.log('action' , action.payload.data);
            const {name, email, id, balance, avatar} = action.payload.data;
            state.balance = balance;
            state.userName = name;
            state.userAvatar = avatar;
            state.userEmail = email;
            state.userId = id;
        });
        builder.addCase(setUserDataThunk.fulfilled, (state, action) => {
            const {name, email, avatar} = action.payload.data;
            console.log(action.payload.data)
            console.log(avatar)
            state.userName = name;
            state.userEmail = email;
            state.userAvatar = avatar ?? '';
        });
        builder.addCase(getUserAddress.fulfilled, (state, action) => {
            state.billingInfo = {...action.payload.data};
        });
    },

});

export const {
    setUserData,
    setIsLoginUser,
    setToken,
    setLogOutUser,
} = userAuth.actions;

export default userAuth.reducer;