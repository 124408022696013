import React, {useEffect, useState} from "react";
import wallet from "../../assets/wallet.svg";
import box from '../../assets/box.svg'
import atom from '../../assets/atom.svg'
import diamond from '../../assets/diamond.svg'
import PopupPayment from "./PopupPayment/PopupPayment";
import {useSelector} from 'react-redux';
import {isLoginSelector} from '../../../../../redux/selectors/selectors';
import {APIPayment, APIUserRequest} from '../../../../../API/api';
import {CircularProgress, Tooltip} from "@material-ui/core";
import arrowLeft from "../../../../userMessages/Components/Chat/assets/arrow-left.svg";
import PopupPurchase from "../../../../adsActions/MyAds/PopupPurchase/PopupPurchase";
import PopupError from "../../../../adsActions/MyAds/PopupError/PopupError";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {useQuery} from "@tanstack/react-query";

const mapIcon = new Map([
    [0, box],
    [1, box],
    [2, atom],
    [3, diamond],
]);

const Prepay = ({setPrepayValue, pricingPage}) => {
    const isLogin = useSelector(isLoginSelector);
    // const [packages, setPackages] = useState([]);
    const [balance, setBalance] = useState(null);
    const [modalActivePurchase, setModalActivePurchase] = useState(false);
    const [modalActiveError, setModalActiveError] = useState(false);
    const { t } = useTranslation();

    const {isLoading: p_loaded, data: packages} = useQuery({queryKey: ['packages'], queryFn: APIPayment.getPaymentsPackages, staleTime: 3_600_000})

    // useEffect(() => {
    //     APIPayment.getPaymentsPackages()
    //         .then((r) => {
    //             // console.log(r)
    //             setPackages(r.data)
    //         })
    // }, [])

    useEffect(() => {
        if (isLogin) {
            APIUserRequest.getUserInfo()
                .then((r) => setBalance(r.data.balance))
        }
    }, [isLogin])

    const [modalActivePrepay, setModalActivePrepay] = useState({
        show: false,
        data: null,
    });

    const togglePopupPrepay = (data) => {
        setModalActivePrepay(prevState => ({
            show: !prevState.show,
            data,
        }))
    }

    return (
        <div className={'block-invoices-and-payment'}>
            <div className={'title-and-prepay'}>
                <div className={'prepay-back'}>
                    {!pricingPage
                        ? <div
                            onClick={() => setPrepayValue(false)}
                            className={'back-to-invoices'}>
                            <img src={arrowLeft} alt={'back to messages'}/>
                            <span>{t('Payment and transactions')}</span>
                        </div> : null}
                    <span className={'title'}>{t('prepay')}</span>
                </div>
                {isLogin
                    ? <div className={'balance-prepay'}>
                        <div className={'your-balance'}>
                            <img src={wallet} alt={'wallet'} className={'wallet'}/>
                            <span className={'balance-word'}>{t('your-balance')}:</span>
                            <span className={'tops'}>{balance === null ?
                                <CircularProgress size={20}/> : balance} {balance > 1 ? "top" : "top"}</span>
                        </div>
                    </div> : null}
            </div>
            <div className={'packages'}>
                {!p_loaded ? packages.data.map((i, type) => (
                    <div className={'package-type'} key={type}>
                        <div className={'img-type'}>
                            <img src={mapIcon.get(type)} alt={'type'}/>
                            <span className={'type'}>{t(i.name)}</span>
                        </div>
                        <div className={i.discount ? 'discount-package' : 'no-discount-package'}>
                            {i.discount}% {t('off')}
                        </div>
                        <span className={'tops'}>{i.ad_count} {t('tops', {count: i.ad_count})}</span>
                        <span className={'topup'}> {t('it-will-topup-for-balance-about')} {i.ad_count} {t('ads',
                            {count: i.ad_count})}</span>
                        <span className={'text'}>{t(i.description)}</span>
                        <div className={'block-pay'}>
                            {isLogin ?
                                <button onClick={() => togglePopupPrepay(i)}
                                        className={'pay'}><span>{t('pay')} {i.price} €</span>
                                </button>
                                : <Tooltip title={`You should login first`} placement={'top'} arrow>
                                    <Link to={`/auth?type=login`} className={`pay`}>
                                        <span>{t('pay')} {i.price} €</span>
                                    </Link>
                                </Tooltip>}
                        </div>
                    </div>
                )):
                    <CircularProgress
                        style={{position: "absolute", top: "40%", left: "50%"}}
                        size={50}/>
                    }
            </div>

            {modalActivePrepay.show && (
                <PopupPayment
                    modal={modalActivePrepay}
                    setModalActivePurchase={setModalActivePurchase}
                    setModalActiveError={setModalActiveError}
                    setModal={setModalActivePrepay}
                />
            )}

            {modalActivePurchase &&
                <PopupPurchase active={modalActivePurchase} setActive={setModalActivePurchase}/>}
            {modalActiveError && <PopupError active={modalActiveError} setActive={setModalActiveError}/>}

        </div>
    );

};

export default Prepay;