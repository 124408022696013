import React, {useState} from "react";
import CancelIcon from '@material-ui/icons/Cancel';
import "../PopupComplain/popupComplain.css";
import triangle from "../PopupComplain/assets/alert-triangle.svg";
import {APICardRequest} from "../../../API/api";
import {Formik} from 'formik';
import { useTranslation } from 'react-i18next';

const initialValues = {
    text: "",
    type: "Spam"
}

const PopupComplain = (props) => {
    const {adId, setActive, active, changeContinuePopup} = props;
    const [error, setError] = useState({
        show: false,
        mess: "",
    });

    const { t } = useTranslation();

    const sendComplaints = (data) => {
        setError({
            show: false,
            mess: ""
        });
        APICardRequest.sendUserCompliant(adId, data)
            .then((r) => {
                // console.log(r);
                if (r.status === 201) {
                    changeContinuePopup(t('Complaint sent'))
                    setTimeout(() => {
                        changeContinuePopup(t('Complaint sent'))
                    }, 3000)
                    setActive(false);
                }
            })
            .catch((e) => {
                console.log(e.response.status)
                if (e.response) {
                    setError({
                        show: true,
                        mess: e.response.data.message,
                    });
                    setTimeout(() => {
                        setError(prevState => ({
                            ...prevState,
                            show: false,
                        }))
                    }, 3000)
                }
            });
    };


    return (
        <div
            style={{
                background: active ? "rgba(0, 0, 0, 0.3)" : "transparent",
            }}
            className={active ? "modal  active" : "modal"}
            onClick={() => setActive(false)}>
            <div
                className={"modal-content-complaint"}
                onClick={(e) => e.stopPropagation()}>
                <div className={"close-and-complaint"}>
                    <div className={"complaint"}>
                        <img src={triangle} alt={"triangle"}/>
                        <span>{t('complain')}</span>
                    </div>
                    <div className={"close-button"}>
                        <svg
                            width="24"
                            className={"close_icon"}
                            onClick={() => setActive(false)}
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.6416 12.0002L18.5804 7.07632C19.0069 6.61883 18.9948 5.90538 18.5522 5.46381C18.1097 5.0213 17.3972 5.00819 16.9397 5.43475L12.0001 10.3586L7.07621 5.42731C6.62059 4.96982 5.88089 4.96792 5.4234 5.42356C4.96591 5.87824 4.96402 6.61888 5.41965 7.07636L10.3584 12.0002L5.41965 16.924C4.9931 17.3815 5.00527 18.095 5.44778 18.5366C5.89029 18.9791 6.60278 18.9922 7.06029 18.5656L11.9841 13.6418L16.908 18.5656H16.9089C17.3617 19.0269 18.1033 19.0344 18.5655 18.5806C19.0267 18.1278 19.0342 17.3862 18.5805 16.9241L13.6416 12.0002Z"
                                fill="#003E47"
                                fillOpacity="0.4"
                            />
                        </svg>
                    </div>
                </div>
                <div className={`compliant-error ${error.show ? "compliant-error-active" : ""}`}>
                    <CancelIcon style={{color: "red", paddingRight: "3px"}}/>
                    <span>{error.mess}</span>
                </div>

                <span className={"whats-wrong"}>{t('whats-wrong-with-the-ad')}</span>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        sendComplaints(values)
                    }}
                >
                    {({values, setFieldValue, handleSubmit}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className={"checks"}>
                                    <div className={"radio-check"}>
                                        <input
                                            type="radio"
                                            value="Spam"
                                            name="type"
                                            className={"input-checkbox"}
                                            checked={values.type === "Spam"}
                                            onChange={(e) => setFieldValue("type", e.currentTarget.value)}
                                        />
                                        <label htmlFor="Spam">{t('Spam')}</label>
                                    </div>
                                    <div className={"radio-check"}>
                                        <input
                                            type="radio"
                                            value="Incorrect advertising"
                                            name="type"
                                            className={"input-checkbox"}
                                            checked={values.type === "Incorrect advertising"}
                                            onChange={(e) => setFieldValue("type", e.currentTarget.value)}
                                        />
                                        <label htmlFor="Incorrect advertising">
                                            {t('Incorrect advertising')}
                                        </label>
                                    </div>

                                    <div className={"radio-check"}>
                                        <input
                                            type="radio"
                                            value="Prohibited"
                                            name="type"
                                            className={"input-checkbox"}
                                            checked={values.type === "Prohibited"}
                                            onChange={(e) => setFieldValue("type", e.currentTarget.value)}
                                        />
                                        <label htmlFor="Prohibited product/service">
                                             {t('Prohibited product/service')}
                                        </label>
                                    </div>

                                    <div className={"radio-check"}>
                                        <input
                                            type="radio"
                                            value="Irrelevant"
                                            name="type"
                                            className={"input-checkbox"}
                                            checked={values.type === "Irrelevant"}
                                            onChange={(e) => setFieldValue("type", e.currentTarget.value)}
                                        />
                                        <label htmlFor="Irrelevant ad">{t('Irrelevant ad')}</label>
                                    </div>
                                    <div className={"radio-check"}>
                                        <input
                                            type="radio"
                                            value="Fraud"
                                            name="type"
                                            className={"input-checkbox"}
                                            checked={values.type === "Fraud"}
                                            onChange={(e) => setFieldValue("type", e.currentTarget.value)}
                                        />
                                        <label htmlFor="mce-group[21241]-21241-4">{t('Fraud')}</label>
                                    </div>
                                    <div className={"radio-check"}>
                                        <input
                                            type="radio"
                                            value="Other"
                                            name="type"
                                            className={"input-checkbox"}
                                            checked={values.type === "Other"}
                                            onChange={(e) => setFieldValue("type", e.currentTarget.value)}
                                        />
                                        <label htmlFor="Other">{t('Other')}</label>
                                    </div>
                                </div>

                                <button type="submit" className={"send"}>
                                    {t('send')}
                                </button>
                                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                            </form>
                        )
                    }}
                </Formik>
            </div>

        </div>
    );
};

export default PopupComplain;
