import {Link, useHistory} from 'react-router-dom';
import logo from '../header/assets/logo-bazar.svg';
import './footer.css'
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';


const Footer = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const handleMainPage = () => {
        history.push('/');
    };
    return (
        <footer className={'StyledFooterList'}>
            <div className={"StyledFooterYear"}>
                <span>© {new Date().getFullYear()} BAZAR </span>
                <span>{t('all-rights-reserved')}</span>
            </div>

            <div className={'policy-landing'}>
                <div className={'StyledFooterTerms'}>
                    <Link to={'/privacy-policy'}>{t('privacy-policy')}</Link>
                    <Link to={'/terms-of-use'}>{t('terms-of-use')}</Link>
                    <Link to={{pathname: "https://blog.chytrybazar.sk"}} target="_blank">Blog</Link>
                </div>
                <div className={'email-and-logo'}>
                    <p className={'StyledFooterEmail'}><a
                        href={`mailto:info@${process.env.REACT_APP_DOMAIN}`}>info@{`${process.env.REACT_APP_DOMAIN}`}</a></p>
                    <div className={'StyledFooterLogo'} onClick={handleMainPage}>
                        <img height={"16"} width={"89"} src={logo} alt={'logo'}/>
                    </div>
                </div>
            </div>
            <div>
                <CookieConsent
                    location="bottom"
                    buttonText={ t('cookie.button_text')}
                    style={{background: "#2B373B"}}
                    buttonStyle={{color: "#4e503b", fontSize: "14px", 'border-radius': "8px", fontWeight: "bold"}}
                >
                    {t('cookie.consent') + " "}
                    <span style={{fontSize: "10px"}}>
                        <Link style={{color: "#31a8f0"}} to={'/privacy-policy'}>{t('privacy-policy')}</Link>
                    </span>
                </CookieConsent>
            </div>
        </footer>
    );
};

export default Footer;
