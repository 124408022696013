import React from 'react';
import {Formik} from "formik";
import {StyledForm, StyledInputErr, StyledRegistrationIcon, StyledRegistrationIconWrapper} from "../authStyle";
import {CustomInputField} from "../../../sections/inputAuthField/CustomInputField";
import EmailIcon from "@material-ui/icons/Email";
import Eye from "./assets/Eye";
import EyeOff from "./assets/EyeOff";
import {AlternativeLogIn} from "./alternativeLogIn/AlternativeLogIn";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import OAuth from "./OAuth";

const Login = (props) => {
    const {handleLogin, handleChangePassword, password, err, loading} = props;
    const { t } = useTranslation();

     const SignupSchema = Yup.object().shape({
    password: Yup.string()
        .min(2, t('too-short'))
        .max(50, t('too-long'))
        .required(t('required')),

    email: Yup.string()
        .min(2, t('too-short'))
        .max(50, t('too-long'))
        .required(t('required')),
});

    return (
        <div className={"big-form"}>
            <OAuth/>
            <Formik
                initialValues={{
                    password: "", email: "",
                }}
                validationSchema={SignupSchema}
                onSubmit={(values) => {
                    handleLogin(values);
                }}>
                {({errors, touched}) => (
                    <StyledForm className={"big-form-login"}>
                        <div>
                            <span className={"email"}>Email</span>
                            <CustomInputField
                                icon={EmailIcon}
                                name="email"
                                type="email"
                                placeholder={"email@mail"}
                            />
                            {errors.email && touched.email ? (
                                <StyledInputErr>{errors.email}</StyledInputErr>) : null}
                        </div>
                        <div className={"password-icon"}>
                            <span className={"email"}>{t('password')}</span>
                            <StyledRegistrationIconWrapper>
                                <CustomInputField
                                    autoComplete={"on"}
                                    name="password"
                                    placeholder={t('password')}
                                    type={password ? "text" : "password"}
                                />
                                <StyledRegistrationIcon onClick={handleChangePassword}>
                                    {!password ? <Eye/> : <EyeOff/>}
                                </StyledRegistrationIcon>
                            </StyledRegistrationIconWrapper>
                            {errors.password && touched.password ? (
                                <StyledInputErr>{errors.password}</StyledInputErr>) : null}
                            <StyledInputErr>{err.length ? err : null}</StyledInputErr>
                        </div>
                        <button disabled={loading} type={"submit"} className={"log-in-button"}>{t('log-in')}</button>
                    </StyledForm>
                )}
            </Formik>
            <div style={{display: "flex", justifyContent: "center"}}>
                <AlternativeLogIn/>
            </div>
        </div>
    );
};

export default Login;