export const isLoginSelector = (state) => state.userAuth.isLogin;

export const getAccessTokenSelector = (state) => state.userAuth.accessToken;

export const getAllAdsSelector = (state) => state.adsSlice.ads;

export const getUserAdsSelector = (state) => state.adsSlice.usersAds;

export const getFollowedAdsSelector = (state) => state.adsSlice.followedAds;

export const getFollowedAdsCount = (state) => state.adsSlice.followedAdsCount;

export const getUserId = (state) => state.userAuth.userId;

export const loadingSelector = state => state.adsSlice.loading;

export const usersAdsLoadingSelector = state => state.adsSlice.usersAdsLoading;

export const allAdsLoadingSelector = state => state.adsSlice.allAdsLoading;

export const getAdReactionsSelector = state => state.adsSlice.userAdsReactions;

export const getUserName = state => state.userAuth.userName;

export const getUserAvatar = state => state.userAuth.userAvatar;

export const getUserEmail = state => state.userAuth.userEmail;

export const getBillingInfo = state => state.userAuth.billingInfo;


// export const getUserBalanceSelector = state => state.userAuth.balance;

export const getTotalPages = state => state.adsSlice.pages;

export const getTotalFollowedPages = state => state.adsSlice.followedPages;

export const getTotalCount = state => state.adsSlice.totalCount;

export const getMainTotalPages = state => state.adsSlice.mainPageTotal;

export const getUnreadMessagesCount = state => state.chatSlice.messagesCount;

export const getUnreadNotificationsData = state => state.chatSlice.notificationData;

export const newNotificationsData = state => state.chatSlice.newNotification;

