import styled from "styled-components";
import {Form} from "formik";

export const StyledInputErr = styled.p`
  color: #ff7272;
  font-weight: 600;
  font-size: 0.8rem;
`;





export const StyledRegistrationIconWrapper = styled.div`
  position: relative;
`;

export const StyledRegistrationIcon = styled.div`
  position: absolute;
  right: .75rem;
  top: 50%;
  transform: translateY(-50%);
  display: grid;
  align-items: center;
  @media (max-width: 580px) {
    left: unset;
    right: 5px;
  }
`;



export const StyledForm = styled(Form)`
  display: grid;
  grid-gap: 20px;
  //border: 1px solid #eeeeee;
  flex-direction: column;

`;
