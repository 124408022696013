import React from 'react';
import './change_confirmed_popup.css';

const ChangedConfirmedPopup = ({changingConfirmed, successText, warning}) => {
    const tickSvg = () => {
        return (
            warning
                ? <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 33.25C26.8701 33.25 33.25 26.8701 33.25 19C33.25 11.1299 26.8701 4.75 19 4.75C11.1299 4.75 4.75 11.1299 4.75 19C4.75 26.8701 11.1299 33.25 19 33.25Z"
                        fill="#FFB648"/>
                    <path d="M19 12.6667V19.0001" stroke="white" strokeWidth="3" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M19 25.3333H19.0158" stroke="white" strokeWidth="3" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
                : <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 33.25C26.8701 33.25 33.25 26.8701 33.25 19C33.25 11.1299 26.8701 4.75 19 4.75C11.1299 4.75 4.75 11.1299 4.75 19C4.75 26.8701 11.1299 33.25 19 33.25Z"
                        fill="white"/>
                    <path d="M14.25 19.0002L17.4167 22.1668L23.75 15.8335" stroke="#2F49D1" strokeWidth="3"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
        )
    }

    return changingConfirmed ? (
        <div className={'changed_confirmed_popup'}>
            <div className={'changing_confirmed'}>
                {tickSvg()}
                <span>{successText}</span>
            </div>
        </div>
    ) : <div className={'changed_confirmed_popup_un_active'}>
        <div className={'changing_confirmed'}>
            {tickSvg()}
            <span>{successText}</span>
        </div>
    </div>
}
export default ChangedConfirmedPopup