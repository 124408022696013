import io from "socket.io-client";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAccessTokenSelector} from "../redux/selectors/selectors";
import {APIUserRequest} from "../API/api";
import Keys from "./helper";

const useSocket = () => {

    const dispatch = useDispatch();
    const access_token = useSelector(getAccessTokenSelector);

    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (access_token) {
            setSocket(io(`${process.env.REACT_APP_SOCKET_URL}/chat`, {
                query: {token: access_token},
                withCredentials: true,
                transports: ["websocket"] // or [ "websocket", "polling" ] (the order matters)
            }))
        }
    }, [access_token])

    useEffect(() => {
        if (!!socket) {
            socket.connect();
            // socket.on("connect", () => {
            //     console.log(socket.id); // x8WIv7-mJelg7on_ALbx
            //     console.log(socket.connected); // true
            //     //console.log(socket.rooms);
            // });

            socket.on("connect_error", (err) => {
                if (err.message === 'unauthorized') {
                    console.log(`connect_error due to ${err.message}`);
                    APIUserRequest.userRefreshToken(localStorage.getItem(Keys.REFRESH_TOKEN), dispatch).then(r => r);
                }
            });

            socket.on("unauthorized", (error) => {
                console.log(error)
                APIUserRequest.userRefreshToken(localStorage.getItem(Keys.REFRESH_TOKEN), dispatch).then(r => r);
            });
        }
        return () => socket ? socket.disconnect() : null;
    }, [socket, dispatch])

    return {socket, setSocket};
};

export default useSocket;